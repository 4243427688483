import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { Space, Spin } from 'antd';
import { changePlanOrder } from 'modules/benefitsWellness/util';
import MDVPlan from 'model/MDVPlan';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import iconEdit from 'images/icon-edit.svg';
import leftRoundIcon from 'images/icons/icon-left-round-arrow.svg';
import rightRoundIcon from 'images/icons/icon-right-round-arrow.svg';
import MDVCardTitles from 'modules/benefitsWellness/components/MDVCardTitles/MDVCardTitles';
import MDVCardValues from 'modules/benefitsWellness/components/MDVCardValues/MDVCardValues';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import NoPlansText from 'modules/benefitsWellness/components/NoPlansText/NoPlansText';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';
import { listHRAPlans } from 'modules/taxAdvantaged/slices/hraSlice';

import PlanDocuments from 'modules/resources/components/PlanDocuments/PlanDocuments';
import { MEDICAL_PLAN_DOCUMENT_TYPE } from 'modules/resources/constants';
import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './medicalCardWrapper.module.less';

type MedicalCardWrapperProps = {
  onUpdatePlanList: Function;
  allPlans: MDVPlan[];
  reloadPlans: Function;
  showAlert: Function;
  isLoading: boolean;
  isContributionsViewOpen?: boolean;
  onCloseContributions?: Function;
  isUpdated?: Function;
};

const MedicalCardWrapper: FC<MedicalCardWrapperProps> = (
  props: MedicalCardWrapperProps
) => {
  const {
    onUpdatePlanList,
    reloadPlans,
    allPlans,
    showAlert,
    isLoading,
    isContributionsViewOpen = false,
    onCloseContributions = () => {},
    isUpdated = () => {},
  } = props;
  const dispatch = useAppDispatch();
  const { medical, benefitWellness } = useSelector((state: any) => state);
  const { activePlanCompare, selectedPlanForCompare } = benefitWellness;
  const { inProgress, filteredPlanList } = medical;
  const { isTablet, isMobile } = useAppSelector((state) => state.layout);
  const [contributionModalClose, setContributionModalClose] =
    useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const benguide = useBenguide();
  const isEditMode = benguide.isEditMode;
  const plans = useMemo(() => {
    if (activePlanCompare) {
      return filteredPlanList.filter((plan: MDVPlan) =>
        selectedPlanForCompare.includes(plan.id)
      ) as MDVPlan[];
    } else {
      return filteredPlanList;
    }
  }, [activePlanCompare, filteredPlanList, selectedPlanForCompare]);

  const [planViewCursor, setPlanViewCursor] = useState(0);
  const [planDetailsHeight, setPlanDetailsHeight] = useState(120);

  const reArrangeRxValues = (plan: any) => {
    const rxDeductiblesandOOPMax = plan?.rxDeductiblesAndOop;

    const rxDeductibles = {
      deductibleInNetworkCost: rxDeductiblesandOOPMax?.deductibleInNetworkCost,
      deductibleOutOfNetworkCost:
        rxDeductiblesandOOPMax?.deductibleOutOfNetworkCost,
      deductibleInNetworkApplicable:
        rxDeductiblesandOOPMax?.deductibleInNetworkApplicable,
      deductibleOutOfNetworkApplicable:
        rxDeductiblesandOOPMax?.deductibleOutOfNetworkApplicable,
    };

    const rxOOP = {
      oopMaxInNetworkCost: rxDeductiblesandOOPMax?.oopMaxInNetworkCost,
      oopMaxOutOfNetworkCost: rxDeductiblesandOOPMax?.oopMaxOutOfNetworkCost,
      oopMaxInNetworkApplicable:
        rxDeductiblesandOOPMax?.oopMaxInNetworkApplicable,
      oopMaxOutOfNetworkApplicable:
        rxDeductiblesandOOPMax?.oopMaxOutOfNetworkApplicable,
    };

    const updatedPlanObjectWithNewValues = {
      ...plan,
      rxOopMax: rxOOP,
      rxDeductibles: rxDeductibles,
    };
    return updatedPlanObjectWithNewValues;
  };

  useEffect(() => {
    setPlanViewCursor(0);
  }, [plans.length]);

  useEffect(() => {
    isUpdated((i: any) => !i);
  }, [isChanged, isUpdated, plans]);

  useEffect(() => {
    const { planYearId, employerId, benefitClasses } = benguide;
    dispatch(listHRAPlans(employerId, planYearId, benefitClasses));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onCloseContributions(contributionModalClose);
  }, [contributionModalClose, onCloseContributions]);

  useEffect(() => {
    setPlanDetailsHeight(120);
  }, [plans]);

  const maxPlanCards = useMemo(() => {
    if (isTablet && !isMobile) {
      return 2;
    }
    if (isMobile) {
      return plans.length;
    }
    return 4;
  }, [isTablet, isMobile, plans?.length]);

  if (inProgress) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <div
      className={`${styles.medicalCardWrapper} ${
        !isEditMode ? styles.customMargin : ''
      }`}
    >
      {planViewCursor !== 0 && plans.length > 0 && (
        <img
          className={styles.leftRoundIcon}
          src={leftRoundIcon}
          alt="left"
          onClick={() => {
            setPlanViewCursor(planViewCursor - 1);
          }}
        />
      )}
      {maxPlanCards + planViewCursor < plans.length && (
        <img
          className={styles.rightRoundIcon}
          src={rightRoundIcon}
          alt="right"
          onClick={() => {
            setPlanViewCursor(planViewCursor + 1);
          }}
        />
      )}
      {plans.length > 0 ? (
        <MDVCardTitles
          planType={MDVTypes.MEDICAL}
          onUpdatePlanList={onUpdatePlanList}
          addedPlanList={plans}
          allPlans={allPlans}
          reloadPlans={reloadPlans}
          allowHeaderTitle={true}
          showAlert={showAlert}
          isLoading={isLoading}
          detailsHeight={planDetailsHeight}
          isContributionsViewOpen={isContributionsViewOpen}
          onCloseContributions={setContributionModalClose}
        />
      ) : (
        <NoPlansText text={'There are no plans that fit your criteria.'} />
      )}
      {plans
        .slice(planViewCursor, maxPlanCards + planViewCursor)
        .map((plan: any, index: number) => {
          return (
            <MDVCardValues
              key={index}
              plan={reArrangeRxValues(plan)}
              planType={MDVTypes.MEDICAL}
              attachedPlans={plans}
              isChanged={setIsChanged}
              moveLeft={() => {
                changePlanOrder(
                  index + planViewCursor,
                  index + planViewCursor - 1,
                  plans,
                  onUpdatePlanList
                );
              }}
              moveRight={() => {
                changePlanOrder(
                  index + planViewCursor,
                  index + planViewCursor + 1,
                  plans,
                  onUpdatePlanList
                );
              }}
              hideLeftMoveButton={
                index % maxPlanCards === 0 && planViewCursor === 0
              }
              hideRightMoveButton={index + planViewCursor === plans.length - 1}
              summarySection={
                <MedicalSummarySection plan={plan} attachedPlans={plans} />
              }
              onHigherDetailsHeight={(number) => {
                setPlanDetailsHeight(number);
              }}
              detailsHeight={planDetailsHeight}
            />
          );
        })}
    </div>
  );
};

type MedicalSummarySectionProps = {
  plan: MDVPlan;
  attachedPlans: MDVPlan[];
};

const MedicalSummarySection: FC<MedicalSummarySectionProps> = ({
  plan,
}: MedicalSummarySectionProps) => {
  const benguide = useBenguide();
  const isEditMode = benguide.isEditMode;
  const [isVisible, setIsVisible] = useState(false);

  if (
    isEditMode ||
    !isEmpty(plan.documents) ||
    !isEmpty(plan.additionalDocuments) ||
    !isEmpty(plan.additionalWeblinks)
  ) {
    return (
      <>
        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={iconEdit} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <PlanDocuments
              visible={visible}
              onClose={onClose}
              benefitKind={BENEFIT_CATEGORY.MEDICAL.value}
              planDocumentTypes={MEDICAL_PLAN_DOCUMENT_TYPE}
              plan={plan}
            />
          )}
        >
          {!isEmpty(plan.documents) ||
          !isEmpty(plan.additionalDocuments) ||
          !isEmpty(plan.additionalWeblinks) ? (
            <div className={styles.benefitDetail}>
              <div className={styles.viewSbc}>
                <span
                  className={styles.sbcLink}
                  onClick={() => setIsVisible(true)}
                >
                  Plan Resources
                </span>
              </div>
            </div>
          ) : isEditMode ? (
            <div className={styles.missingInfo}>
              <MissingInfo missingInfoText="Missing Summary" />
            </div>
          ) : (
            <div className={styles.emptySummary}>-</div>
          )}
        </EditableHotspot>
        <ViewResourcesModal
          plan={plan}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          benefitKind={BENEFIT_CATEGORY.MEDICAL.value}
        />
      </>
    );
  }
  return <></>;
};

export default MedicalCardWrapper;

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import AdditionalPerksChannel from 'modules/clients/UPClient/channels/AdditionalPerksChannel';
import PlanReference from 'model/PlanReference';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import editIcon from 'images/icon-edit.svg';
import perksDefaultBannerImage from 'images/samples/additionalperks.png';
import { ReactComponent as AdditionalPerksIcon } from 'images/pageHeaderIcons/additional-perks.svg';
import {
  fetchAdditionalPerksPlanList,
  addPlans,
  handleSectionImageUpload,
} from 'modules/additionalPerks/slices/additionalPerksSlice';
import { SectionName } from 'modules/home/constants';
import { getSectionImageUrl } from 'services/BenGuideService';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import { ADDITIONAL_PERKS_CHANNEL } from 'modules/clients/UPClient/UPClientConst';
import AdditionalPerksCard from 'modules/additionalPerks/components/AdditionalPerksCard/AdditionalPerksCard';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { getBase64Image } from 'utils/screenshotUtil';
import { benguideApiV2 } from 'utils/apiUtil';
import PageSection from 'model/PageSection';

import styles from './additionalPerks.module.less';

const AdditionalPerks: FC = () => {
  const dispatch = useAppDispatch();
  const { planList, additionalPerksSection, sections } = useAppSelector(
    (state) => state.additionalPerks
  );

  const { plans: addedPlans = [] } = additionalPerksSection;
  const benguide = useBenguide();
  const {
    masterId,
    planYearId,
    employerId,
    isEditMode,
    benefitClasses,
    revision,
    latestRevision,
  } = benguide;

  const [bannerImage, setBannerImage] = useState<string>('');
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [originalImg, setOriginalImg] = useState<string>('');
  const imageUploaderRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  useEffect(() => {
    if (masterId) {
      setBannerImage(
        getSectionImageUrl(
          masterId,
          SectionName.ADDITIONAL_PERKS_IMAGE,
          latestRevision || revision
        )
      );
    }
  }, [dispatch, masterId, revision, latestRevision]);

  const updatePlans = (plans: [], orderByPlanNames: boolean) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        planName: plan.name,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });
    const addPlanRequest = {
      plans: planReferences,
      sectionName: BENEFIT_CATEGORY.ADDITIONAL_PERK.value,
      orderByPlanNames: orderByPlanNames,
    } as AddPlanApiRequestPayload;
    dispatch(addPlans(masterId, addPlanRequest));
  };

  const reloadAllAdditionalPerksPlans = useCallback(() => {
    dispatch(
      fetchAdditionalPerksPlanList(employerId, planYearId, benefitClasses)
    );
  }, [employerId, planYearId, dispatch, benefitClasses]);

  const handleImageUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setLoadingImage(true);
    setBannerImage(image);
    dispatch(
      handleSectionImageUpload(
        masterId,
        image,
        SectionName.ADDITIONAL_PERKS_IMAGE,
        originalImage,
        cropArea
      )
    );
  };

  useEffect(() => {
    if (sections) {
      const imgSection = sections.find(
        (section: PageSection) =>
          section.name === SectionName.ADDITIONAL_PERKS_IMAGE
      );
      if (imgSection?.data?.originalImageReference) {
        getBase64Image(
          `${benguideApiV2}/${masterId}/images?section-name=${
            SectionName.ADDITIONAL_PERKS_IMAGE
          }&original=true&revision=${latestRevision || revision}`
        ).then((data: any) => {
          setOriginalImg(data);
          if (loadingImage) {
            (imageUploaderRef?.current as any)?.close();
            setLoadingImage(false);
          }
        });
      }
    }
    // No need call when change loadingImage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId, sections, latestRevision]);

  const sectionImageCrop = useMemo(() => {
    if (sections) {
      const imgSection = sections.find(
        (section: PageSection) =>
          section.name === SectionName.ADDITIONAL_PERKS_IMAGE
      );

      return imgSection ? imgSection.data.originalImageCropParams : null;
    }
    return null;
  }, [sections]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.perksWrapper}>
        <AdditionalPerksChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.ADDITIONAL_PERK.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle="Additional Perks"
          pageHeaderIcon={<AdditionalPerksIcon />}
          customIconStyle={styles.perksIcon}
        />
        {isEditMode && !isEmpty(addedPlans) && (
          <div className={styles.addOrRemovePlansWrapper}>
            <EditableHotspot
              alwaysVisible={true}
              customModal={(visible: boolean, onClose: Function) => (
                <AddOrRemovePlans
                  visible={visible}
                  onClose={onClose}
                  title="Additional Perks"
                  onSave={updatePlans}
                  addedPlanList={addedPlans}
                  allPlans={planList.data}
                  reloadPlans={reloadAllAdditionalPerksPlans}
                  channel={ADDITIONAL_PERKS_CHANNEL}
                  showAlert={showAlert}
                  isLoading={planList.inProgress}
                />
              )}
            >
              <Button>+ Add or Remove Plans</Button>
            </EditableHotspot>
          </div>
        )}

        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={editIcon} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <ImageUploader
              title="Additional Perks Photo"
              cropShape="rect"
              uploadedImage={bannerImage}
              aspect={1}
              loading={false}
              onConfirm={handleImageUpload}
              showOperations
              uploadNewImgText="+ Upload New Photo"
              isOpenImageTray={visible}
              onCloseImgTray={onClose}
              uploadImgAfterResetText="Upload Photo"
              hideUploader
              customCropper={{ width: 780, height: 248 }}
              defaultLogo={perksDefaultBannerImage}
              imagePreviewStyle={styles.mainImage}
              uploadWrapperClass={styles.imageUploadWrapper}
              maxSizeMB={4}
              defaultCropArea={sectionImageCrop}
              unCroppedImage={originalImg}
              ref={imageUploaderRef}
              preventCloseOnConfirm={true}
              saving={loadingImage}
            />
          )}
        >
          <></>
        </EditableHotspot>

        {addedPlans.length > 0 ? (
          <div className={styles.planCardsContainer}>
            {addedPlans.map((plan: any, index: any) => (
              <AdditionalPerksCard key={index} plan={plan} />
            ))}
          </div>
        ) : (
          isEditMode && (
            <AddOrRemovePlans
              visible
              withoutModal
              title="Additional Perks"
              onSave={updatePlans}
              addedPlanList={addedPlans}
              allPlans={planList.data}
              reloadPlans={reloadAllAdditionalPerksPlans}
              channel={ADDITIONAL_PERKS_CHANNEL}
              showAlert={showAlert}
              isLoading={planList.inProgress}
            />
          )
        )}
        {isEmpty(addedPlans) && !isEditMode && (
          <NoPlansView className={styles.noPlanView} />
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default AdditionalPerks;

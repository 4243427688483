import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import * as BenefitGuideService from 'modules/auth/services/BenguideService';
import { getRichMedia } from 'modules/auth/services/BenguideService';

import {
  updateBenguideStyles,
  updateBenguideHomePage,
  updateBenguideLatestRevision,
} from 'modules/auth/slices/benguideSlice';

import StyleConfiguration from 'model/StyleConfiguration';
import ContentPage from 'model/CotentPage';
import RichMedia from 'model/RichMedia';

import {
  ImageType,
  SectionName,
  HOME_CHECK_LIST,
  Section,
} from 'modules/home/constants';
import { convertEncodedStringToBlob } from 'utils/fileUtil';
import OriginalImageCropParams from 'model/OriginalImageCropParams';

type ImageUploadResponse = {
  sectionName: string;
};
interface HomeState {
  inProgress: boolean;
  defaultTextOverview: string;
  defaultTextBenefit: string;
  error: any;
  contentOverview: string;
  titleOverview: string;
  contentOverviewSuccess: boolean;
  contentBenefit: string;
  contentBenefitSuccess: boolean;
  richTextImage: string | undefined;
  richTextImageReference: string | null;
  uploadInProgress: boolean;
  commonImageUploadInProgress: boolean;
  commonImage: string | undefined;
  sectionImageUploadInProgress: boolean;
  sectionImageUploadSuccess: boolean;
  sectionImage: string | null;
  sectionImageOriginal: string | null;
  deleteInProgress: boolean;
  benefitEnrollLink: string;
  benefitEnrollLinkSuccess: boolean;
  backgroundImageReference: string;
  homeContentPage: ContentPage | { enabled: false; sections: [] };
  updateBenefitRichTextCompleted: boolean;
  checkList: string[];
  checkListOriginal: string[];
  benefitButton: boolean;
  titleBenefit: string;
  latestRevision: number;
}

const initialState = {
  inProgress: false,
  defaultTextOverview: '',
  defaultTextBenefit: '',
  error: null,
  contentOverview: '',
  contentOverviewSuccess: false,
  contentBenefitSuccess: false,
  contentBenefit: '',
  richTextImage: undefined,
  richTextImageReference: null,
  uploadInProgress: false,
  commonImageUploadInProgress: false,
  commonImage: undefined,
  sectionImageUploadInProgress: false,
  sectionImageUploadSuccess: false,
  sectionImage: null,
  sectionImageOriginal: null,
  deleteInProgress: false,
  benefitEnrollLink: '',
  benefitEnrollLinkSuccess: false,
  backgroundImageReference: '',
  homeContentPage: { enabled: false, sections: [] },
  updateBenefitRichTextCompleted: false,
  checkList: [],
  checkListOriginal: [],
  benefitButton: false,
  titleOverview: '',
  titleBenefit: '',
  latestRevision: 0,
} as HomeState;

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    getDefaultRichMediaOverviewStarted(state) {
      state.inProgress = true;
      state.defaultTextOverview = '';
    },
    getDefaultRichMediaOverviewSuccess(
      state,
      action: PayloadAction<RichMedia>
    ) {
      state.inProgress = false;
      state.defaultTextOverview = action.payload.content;
      state.titleOverview = action.payload.title;
    },
    getDefaultRichMediaOverviewFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultTextOverview = '';
      state.error = action.payload;
    },
    createRichMediaOverviewStarted(state) {
      state.inProgress = true;
      state.contentOverview = '';
      state.contentOverviewSuccess = false;
    },
    createRichMediaOverviewSuccess(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentOverview = action.payload.content;
      state.titleOverview = action.payload.title;
      state.contentOverviewSuccess = true;
    },
    createRichMediaOverviewFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentOverview = '';
      state.error = action.payload;
      state.contentOverviewSuccess = false;
    },
    getDefaultRichMediaBenefitStarted(state) {
      state.inProgress = true;
      state.defaultTextBenefit = '';
    },
    getDefaultRichMediaBenefitSuccess(state, action: PayloadAction<RichMedia>) {
      state.inProgress = false;
      state.defaultTextBenefit = action.payload.content;
      state.titleBenefit = action.payload.title;
    },
    getDefaultRichMediaBenefitFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultTextBenefit = '';
      state.error = action.payload;
    },
    createRichMediaBenefitStarted(state) {
      state.inProgress = true;
      state.contentBenefit = '';
      state.updateBenefitRichTextCompleted = false;
      state.contentBenefitSuccess = false;
    },
    createRichMediaBenefitSuccess(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentBenefit = action.payload.content;
      state.titleBenefit = action.payload.title;
      state.updateBenefitRichTextCompleted = true;
    },
    updateRichMediaBenefitSuccess(state, action: PayloadAction<RichMedia>) {
      state.inProgress = false;
      state.contentBenefit = action.payload.content;
      state.titleBenefit = action.payload.title;
    },
    createRichMediaBenefitFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentBenefit = '';
      state.error = action.payload;
      state.updateBenefitRichTextCompleted = false;
      state.contentBenefitSuccess = false;
    },
    resetUpdateBenefitRichTextCompleted(state) {
      state.updateBenefitRichTextCompleted = false;
    },
    resetDefaultText(state) {
      state.inProgress = false;
      state.defaultTextOverview = '';
      state.defaultTextBenefit = '';
      state.error = null;
    },
    uploadRichMediaStart(state, action: PayloadAction<string>) {
      state.uploadInProgress = true;
      state.richTextImage = action.payload;
    },
    uploadRichMediaSuccess(state, action: PayloadAction<any>) {
      state.uploadInProgress = false;
      state.richTextImageReference = action.payload;
      state.error = null;
    },
    uploadRichMediaFailed(state, action: PayloadAction<any>) {
      state.uploadInProgress = false;
      state.error = action.payload;
      state.richTextImageReference = '';
      state.richTextImage = '';
    },
    resetUpload(state) {
      state.uploadInProgress = false;
      state.richTextImageReference = '';
      state.richTextImage = '';
      state.error = null;
    },
    uploadCommonImageStart(state, action: PayloadAction<string>) {
      state.commonImageUploadInProgress = true;
      state.commonImage = action.payload;
    },
    uploadCommonImageSuccess(state, action: PayloadAction<any>) {
      state.commonImageUploadInProgress = false;
      state.error = null;
      state.latestRevision = action.payload.latestRevision;
    },
    uploadBackgroundImageSuccess(state, action: PayloadAction<any>) {
      state.commonImageUploadInProgress = false;
      state.error = null;
      state.backgroundImageReference = action.payload.imageReference;
    },
    uploadCommonImagaFailed(state, action: PayloadAction<any>) {
      state.commonImageUploadInProgress = false;
      state.error = action.payload;
      state.commonImage = '';
    },
    uploadSectionImageStart(
      state,
      action: PayloadAction<{ image: string; original?: string }>
    ) {
      state.sectionImageUploadInProgress = true;
      state.sectionImage = action.payload.image;
      state.sectionImageUploadSuccess = false;
      if (action.payload.original) {
        state.sectionImageOriginal = action.payload.original;
      }
    },
    uploadSectionImageSuccess(
      state,
      action: PayloadAction<ImageUploadResponse>
    ) {
      state.sectionImageUploadInProgress = false;
      state.error = null;
      state.sectionImageUploadSuccess =
        action.payload.sectionName === SectionName.HOME_MAIN_IMAGE;
    },
    uploadSectionImagaFailed(state, action: PayloadAction<any>) {
      state.sectionImageUploadInProgress = false;
      state.error = action.payload;
      state.sectionImage = '';
      state.sectionImageUploadSuccess = false;
    },
    deleteSectionImageStart(state) {
      state.deleteInProgress = true;
      state.homeContentPage.sections = state.homeContentPage.sections.filter(
        (section) => section.name !== SectionName.HOME_MAIN_IMAGE
      );
    },
    deleteSectionImageSuccess(state, action: PayloadAction<number>) {
      state.deleteInProgress = false;
      state.error = null;
      state.latestRevision = action.payload;
    },
    deleteSectionImagaFailed(state, action: PayloadAction<any>) {
      state.deleteInProgress = false;
      state.error = action.payload;
    },
    createBenefitEnrollLinkStarted(state) {
      state.inProgress = true;
      state.benefitEnrollLink = '';
      state.benefitEnrollLinkSuccess = false;
    },
    createBenefitEnrollLinkSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.benefitEnrollLink = action.payload;
      state.benefitEnrollLinkSuccess = true;
    },
    getBenefitEnrollLinkSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.benefitEnrollLink = action.payload;
      state.benefitEnrollLinkSuccess = false;
    },
    createBenefitEnrollLinkFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.benefitEnrollLink = '';
      state.error = action.payload;
      state.benefitEnrollLinkSuccess = false;
    },
    getHomeContentPageStared(state) {
      state.inProgress = true;
      state.homeContentPage = { enabled: false, sections: [] };
    },
    getHomeContentPageSuccess(state, { payload }) {
      state.inProgress = false;
      state.homeContentPage = payload.homePage;
    },
    updateHomeContentPageSuccess(state, { payload }) {
      state.inProgress = false;
      state.homeContentPage = payload;
    },
    getHomeContentPageFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.homeContentPage = { enabled: false, sections: [] };
      state.error = action.payload;
    },
    updateChecklistLocally(state, { payload }) {
      state.checkList = payload;
      state.checkListOriginal = payload;
    },
    getChecklistStarted(state) {
      state.inProgress = true;
      state.checkList = [];
    },
    getChecklistSuccess(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.checkList = action.payload;
      state.checkListOriginal = action.payload;
    },
    getChecklistFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.checkList = [];
      state.error = action.payload;
    },
    updateChecklistSuccess(state, { payload }) {
      state.inProgress = false;
      state.checkList = payload;
    },
    getBenefitButtonStared(state) {
      state.inProgress = true;
      state.benefitButton = false;
    },
    getBenefitButtonSuccess(state, { payload }) {
      state.inProgress = false;
      state.benefitButton = payload.enabled;
    },
    getBenefitButtonFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.benefitButton = false;
      state.error = action.payload;
    },
    updateChecklistSuccessAPI(state, { payload }) {
      state.inProgress = false;
      state.checkList = payload.checkList;
      state.contentBenefitSuccess = true;
    },
    clearBasicInfoSuccess(state) {
      state.contentBenefitSuccess = false;
      state.contentOverviewSuccess = false;
      state.sectionImageUploadSuccess = false;
      state.benefitEnrollLinkSuccess = false;
    },
  },
});

export const {
  getDefaultRichMediaOverviewStarted,
  getDefaultRichMediaOverviewSuccess,
  getDefaultRichMediaOverviewFailed,
  createRichMediaOverviewStarted,
  createRichMediaOverviewSuccess,
  createRichMediaOverviewFailed,
  getDefaultRichMediaBenefitStarted,
  getDefaultRichMediaBenefitSuccess,
  getDefaultRichMediaBenefitFailed,
  createRichMediaBenefitStarted,
  createRichMediaBenefitSuccess,
  createRichMediaBenefitFailed,
  resetDefaultText,
  uploadRichMediaStart,
  uploadRichMediaSuccess,
  uploadRichMediaFailed,
  resetUpload,
  uploadCommonImageStart,
  uploadCommonImageSuccess,
  uploadCommonImagaFailed,
  uploadSectionImageStart,
  uploadSectionImageSuccess,
  uploadSectionImagaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  deleteSectionImagaFailed,
  createBenefitEnrollLinkStarted,
  createBenefitEnrollLinkSuccess,
  getBenefitEnrollLinkSuccess,
  createBenefitEnrollLinkFailed,
  uploadBackgroundImageSuccess,
  getHomeContentPageStared,
  getHomeContentPageSuccess,
  getHomeContentPageFailed,
  updateHomeContentPageSuccess,
  resetUpdateBenefitRichTextCompleted,
  updateChecklistLocally,
  getChecklistStarted,
  getChecklistSuccess,
  getChecklistFailed,
  updateChecklistSuccess,
  updateRichMediaBenefitSuccess,
  getBenefitButtonStared,
  getBenefitButtonSuccess,
  getBenefitButtonFailed,
  updateChecklistSuccessAPI,
  clearBasicInfoSuccess,
} = homeSlice.actions;

export default homeSlice.reducer;

export const getDefaultRichMediaOverview =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaOverviewStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaOverviewSuccess(response.data));
    } catch (error) {
      dispatch(
        getDefaultRichMediaOverviewFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getRichMediaOverview =
  (benguideId: string) => async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaOverviewStarted());
    try {
      const response = await BenefitGuideService.getRichMedia(
        benguideId,
        SectionName.HOME_OVERVIEW_TEXT
      );
      dispatch(getDefaultRichMediaOverviewSuccess(response.data));
    } catch (error) {
      dispatch(
        getDefaultRichMediaOverviewFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getRichMediaBenefit =
  (benguideId: string) => async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaBenefitStarted());
    try {
      const response = await BenefitGuideService.getRichMedia(
        benguideId,
        SectionName.HOME_BENEFIT_GUIDE_DESCRIPTION
      );
      dispatch(getDefaultRichMediaBenefitSuccess(response.data));
    } catch (error) {
      dispatch(
        getDefaultRichMediaBenefitFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getDefaultRichMediaBenefit =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaBenefitStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaBenefitSuccess(response.data));
    } catch (error) {
      dispatch(
        getDefaultRichMediaBenefitFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const createRichMediaOverview =
  (
    benguideId: string,
    sectionName: SectionName,
    contentOverview: string,
    title: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaOverviewStarted());
    try {
      const data = {
        benGuideId: benguideId,
        sectionName: sectionName,
        content: contentOverview,
        title: title,
      };
      const response = await BenGuideService.createRichMedia(benguideId, data);
      const responseData = {
        content: data.content,
        latestRevision: response.data.latestRevision,
        title: data.title,
      };
      dispatch(createRichMediaOverviewSuccess(responseData));
    } catch (error) {
      dispatch(
        createRichMediaOverviewFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const createRichMediaBenefit =
  (
    benguideId: string,
    sectionName: SectionName,
    contentBenefit: string,
    title: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaBenefitStarted());
    try {
      const data = {
        benGuideId: benguideId,
        sectionName: sectionName,
        content: isEmpty(contentBenefit) ? `<p></p>` : contentBenefit,
        title: title,
      };
      await BenGuideService.createRichMedia(benguideId, data);
      const responseData = {
        content: data.content,
        title: data.title,
      };
      dispatch(createRichMediaBenefitSuccess(responseData));
    } catch (error) {
      dispatch(createRichMediaBenefitFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const handleRichMediaUpload =
  (benguideId: string, data: any, onComplete: Function) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadRichMediaStart(data));
    try {
      const response = await BenGuideService.uploadRichMedia(benguideId, data);
      onComplete(response.data);
      dispatch(uploadRichMediaSuccess(response.data));
    } catch (error) {
      dispatch(uploadRichMediaFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const handleImageUpload =
  (
    benguideId: string,
    image: string,
    imageType: ImageType,
    originalImage: string,
    cropArea: OriginalImageCropParams,
    onComplete?: Function
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadCommonImageStart(image));
    try {
      let originalImgRef = '';

      const originalImgBlob = await convertEncodedStringToBlob(originalImage);
      const originalImgRes = await BenGuideService.uploadImage(
        benguideId,
        originalImgBlob,
        imageType,
        true
      );
      originalImgRef = originalImgRes.data.imageReference;

      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenGuideService.uploadImage(
        benguideId,
        imageBlob,
        imageType,
        false,
        cropArea,
        originalImgRef
      );
      const responseData = {
        imageReference: response.data.imageReference,
      };
      if (ImageType.BACKGROUND_IMAGE === imageType) {
        dispatch(uploadBackgroundImageSuccess(responseData));
      }
      dispatch(uploadCommonImageSuccess(response.data));
    } catch (error) {
      dispatch(uploadCommonImagaFailed(JSON.parse(JSON.stringify(error))));
    }

    onComplete && onComplete();
  };

export const handleImageColorCustomizerUpdate =
  (
    benguideId: string,
    image: string,
    originalImage: string,
    cropArea: OriginalImageCropParams,
    imageType: ImageType,
    homeTextColor: string,
    colorTheme: string,
    currentImageReference: string,
    onComplete?: Function
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadCommonImageStart(image));
    try {
      const originalImageBlob = await convertEncodedStringToBlob(originalImage);
      const originalImgRes = await BenGuideService.uploadImage(
        benguideId,
        originalImageBlob,
        imageType,
        true
      );

      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenGuideService.uploadImage(
        benguideId,
        imageBlob,
        imageType,
        false,
        cropArea,
        originalImgRes.data.imageReference
      );
      if (ImageType.BACKGROUND_IMAGE === imageType) {
        dispatch(uploadBackgroundImageSuccess(response.data.imageReference));
        const content = {
          backgroundImageUrl: response.data.imageReference
            ? response.data.imageReference
            : currentImageReference,
          colorTheme: colorTheme,
          homeTextColor: homeTextColor,
          originalBackgroundImageUrl: originalImgRes.data.imageReference,
          originalImageCropParams: cropArea,
        } as StyleConfiguration;
        const { data } = await BenGuideService.updateStyles(
          benguideId,
          content
        );
        const sectionResponse = data as StyleConfiguration;
        dispatch(updateBenguideStyles(sectionResponse));
      }
    } catch (error) {
      dispatch(uploadCommonImagaFailed(JSON.parse(JSON.stringify(error))));
    }

    onComplete && onComplete();
  };

export const handleImageColorCustomizerUpdateWithoutImage =
  (
    benguideId: string,
    homeTextColor: string,
    colorTheme: string,
    currentImageReference: string,
    originalImageRef: string | null,
    cropArea: OriginalImageCropParams | null
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const content = {
        backgroundImageUrl: currentImageReference,
        colorTheme: colorTheme,
        homeTextColor: homeTextColor,
        originalBackgroundImageUrl: originalImageRef,
        originalImageCropParams: cropArea,
      } as StyleConfiguration;
      const { data } = await BenGuideService.updateStyles(benguideId, content);
      const sectionResponse = data as StyleConfiguration;
      dispatch(updateBenguideStyles(sectionResponse));
    } catch (error) {
      dispatch(uploadCommonImagaFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const handleSectionImageUpload =
  (
    benguideId: string,
    image: string,
    sectionName: SectionName,
    originalImage?: string,
    cropArea?: OriginalImageCropParams,
    originalRef?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStart({ image, original: originalImage }));
    let updateOriginalRef = originalRef;
    try {
      if (originalImage) {
        const originalImgBlob = await convertEncodedStringToBlob(originalImage);
        const originalImgRes = await BenGuideService.uploadSectionImage(
          benguideId,
          originalImgBlob,
          sectionName,
          true
        );
        updateOriginalRef = originalImgRes.data.imageReference;
      }
      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenGuideService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName,
        false,
        updateOriginalRef,
        cropArea
      );

      const uploadResponse: ImageUploadResponse = {
        sectionName: sectionName,
      };
      const homeSectionResponse = await BenGuideService.getContentPage(
        benguideId,
        Section.HOME
      );

      dispatch(
        updateHomeContentPageSuccess(homeSectionResponse.data.contentPageVO)
      );
      dispatch(updateBenguideHomePage(homeSectionResponse.data.contentPageVO));
      dispatch(uploadSectionImageSuccess(uploadResponse));
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
    } catch (error) {
      dispatch(uploadSectionImagaFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const handleSectionImageDelete =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      const response = await BenGuideService.deleteSectionImage(
        benguideId,
        sectionName
      );
      dispatch(getHomeContentPage(benguideId, Section.HOME));
      dispatch(deleteSectionImageSuccess(response.data.latestRevision));
    } catch (error) {
      dispatch(deleteSectionImagaFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const createBenefitEnrollLink =
  (
    benguideId: string,
    sectionName: SectionName,
    benefitEnrollLink: string,
    isChecked: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createBenefitEnrollLinkStarted());
    try {
      const data = {
        sectionName: sectionName,
        content: benefitEnrollLink,
      };
      await BenGuideService.createRichMedia(benguideId, data);
      dispatch(createBenefitEnrollLinkSuccess(benefitEnrollLink));
      const sectionConfig = {
        enabled: isChecked,
      };
      const response = await BenGuideService.updateSection(
        benguideId,
        Section.BENEFIT_ENROLL_BUTTON,
        sectionConfig
      );
      const responseData = {
        enabled: isChecked,
        latestRevision: response.data.latestRevision,
      };
      dispatch(getBenefitButtonSuccess(responseData));
    } catch (error) {
      dispatch(
        createBenefitEnrollLinkFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getBenefitEnrollButton: any =
  (benguideId: string) => async (dispatch: Dispatch) => {
    dispatch(getBenefitButtonStared());
    try {
      const response = await BenGuideService.getContentPage(
        benguideId,
        Section.BENEFIT_ENROLL_BUTTON
      );
      dispatch(getBenefitButtonSuccess(response.data.contentPageVO));
      const enrollmentLinkResponse = await getRichMedia(
        benguideId,
        SectionName.BENEFITS_ENROLL_BUTTON
      );
      const { data: benefitEnrollmentLink } = enrollmentLinkResponse;
      dispatch(
        getBenefitEnrollLinkSuccess(
          benefitEnrollmentLink && benefitEnrollmentLink.content
        )
      );
    } catch (error) {
      dispatch(getBenefitButtonFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const handleContentImageDelete =
  (benguideId: string, imageType: ImageType) => async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      const response = await BenGuideService.deleteCommonImageContent(
        benguideId,
        imageType
      );
      dispatch(deleteSectionImageSuccess(response.data.latestRevision));
    } catch (error) {
      dispatch(deleteSectionImagaFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const getHomeContentPage: any =
  (benguideId: string, section: string) => async (dispatch: Dispatch) => {
    dispatch(getHomeContentPageStared());
    try {
      const response = await BenGuideService.getContentPage(
        benguideId,
        section
      );
      dispatch(updateHomeContentPageSuccess(response.data.contentPageVO));
      dispatch(updateBenguideHomePage(response.data.contentPageVO));
    } catch (error) {
      dispatch(getHomeContentPageFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const updateChecklistSection: any =
  (checkList: string[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(updateChecklistSuccess(checkList));
    } catch (error) {
      dispatch(getChecklistFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const updateOverivewText: any = (overviewText: RichMedia) => {
  return async (dispatch: Dispatch) => {
    dispatch(createRichMediaOverviewSuccess(overviewText));
  };
};

export const updateChecklistText: any = (checkListText: RichMedia) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateRichMediaBenefitSuccess(checkListText));
  };
};

export const getCheckList: any =
  (benguideId: string, section: string) => async (dispatch: Dispatch) => {
    dispatch(getChecklistStarted());
    try {
      const response = await BenGuideService.getCheckList(benguideId, section);
      dispatch(getChecklistSuccess(response.data.options));
    } catch (error) {
      dispatch(getChecklistFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const updateCheckList = (benguideId: string, checkList: string[]) => {
  return async (dispatch: Dispatch) => {
    const data = {
      sectionName: HOME_CHECK_LIST,
      options: checkList,
    };
    const response = await BenGuideService.updateCheckList(benguideId, data);
    const responseData = {
      checkList: checkList,
      latestRevision: response.data.latestRevision,
    };
    dispatch(updateChecklistSuccessAPI(responseData));
  };
};

import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { Select, Row, Col, Switch } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import { INPUT_DATE_FORMAT_DEFAULT } from 'utils/dateUtil';
import SectionName from 'model/SectionName';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import CashInput from 'components/CashInput/CashInput';

import {
  DATES_SECTIONS,
  WAIVER_CREDIT_FREQUENCY,
} from 'modules/datesEligability/constants';

import { ReactComponent as CalendarIcon } from 'images/icon-calendar.svg';
import styles from './sectionEditor.module.less';

type SectionEditorProps = {
  sectionNames: SectionName[];
  onChange: Function;
  defaultContent?: string;
  handleEditorChange?: Function;
  editorInitialContent?: string;
  benguideId?: string;
  limit?: number;
  textEditorRef: Ref<any>;
  defaultValue?: string;
  height?: number | string;
  maxHeight?: number;
  activateDefaultValue?: boolean;
  changedEditorContent?: string;
  detectUserInput?: Function;
  resetValue?: string;
  isToggleNeeded?: boolean;
  toggleChecked?: boolean;
  selectedSection?: string;
  defaultSection?: string;
  onSwitch?: (value: boolean, selectedOption: string) => void;
  disabled?: boolean;
  startDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  onchangeDate?: (value: dayjs.Dayjs | null, isStartDate: boolean) => void;
  waiverAmount?: string;
  waiverFrequency?: string;
  onChangeWaiverAmount?: Function;
  onChangeWaiverFrequency?: Function;
};

const SectionEditor = forwardRef((props: SectionEditorProps, ref) => {
  useImperativeHandle(ref, () => ({
    resetSelectedOption: () => {
      setSelectedOption(defaultValue);
      onChange(defaultValue);
    },
    changeSelection: (option: string) => {
      setSelectedOption(option);
      onChange(option);
    },
  }));

  const {
    sectionNames,
    onChange,
    defaultContent,
    handleEditorChange,
    editorInitialContent,
    benguideId,
    limit,
    textEditorRef,
    defaultValue,
    height,
    maxHeight,
    activateDefaultValue = false,
    detectUserInput,
    resetValue = '',
    isToggleNeeded = false,
    toggleChecked,
    selectedSection,
    defaultSection,
    onSwitch = () => {},
    disabled = false,
    startDate,
    endDate,
    onchangeDate = () => {},
    waiverAmount,
    waiverFrequency,
    onChangeWaiverAmount,
    onChangeWaiverFrequency,
  } = props;

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    defaultValue
  );

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  const handleSelectSection = (value: string | undefined) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <div className={styles.sectionEditorWrapper}>
      <Row gutter={8} wrap={false} align={'middle'}>
        <Col>
          <div className={styles.selectLabel}>Select Section to Edit: </div>
        </Col>
        <Col>
          <Select
            size="large"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={handleSelectSection}
            defaultValue={defaultValue}
            className={styles.selectDropdown}
            value={selectedOption}
          >
            {sectionNames.map((section: SectionName, index: number) => (
              <Select.Option
                value={section.code}
                key={index}
                label={section.title}
              >
                {section.title}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col flex={'auto'} className={styles.sectionSwitch}>
          {isToggleNeeded && (
            <>
              <span className={styles.overlay}>
                Display{' '}
                {isEmpty(selectedSection) ? defaultSection : selectedSection}?
              </span>
              <span className={styles.toggle}>
                <Switch
                  onChange={(value) => onSwitch(value, selectedOption || '')}
                  defaultChecked={toggleChecked}
                  checked={toggleChecked}
                />
              </span>
            </>
          )}
        </Col>
      </Row>
      {selectedOption === DATES_SECTIONS[0].code && (
        <Row>
          <Col className={styles.datePickerCol}>
            <div className={styles.selectLabel}>Open Enrollment Date: </div>
          </Col>
          <Col className={styles.datePickerCol}>
            <DatePickerInput
              allowClear
              suffixIcon={<CalendarIcon className={styles.calenderIcon} />}
              value={startDate}
              placeholder={INPUT_DATE_FORMAT_DEFAULT}
              disabled={!toggleChecked}
              className={styles.datePicker}
              onChange={(date) => {
                onchangeDate(date, true);
              }}
            />
          </Col>
          <span className={styles.datePickerMargin}>-</span>
          <Col className={`${styles.datePickerCol} ${styles.datePickerDate}`}>
            <DatePickerInput
              allowClear
              suffixIcon={<CalendarIcon className={styles.calenderIcon} />}
              value={endDate}
              placeholder={INPUT_DATE_FORMAT_DEFAULT}
              disabled={!toggleChecked}
              className={styles.datePicker}
              onChange={(date) => {
                onchangeDate(date, false);
              }}
            />
          </Col>
        </Row>
      )}
      {selectedOption == DATES_SECTIONS[3].code && (
        <Row gutter={8} align="middle" className={styles.extraSectionWrapper}>
          <Col className={styles.waiverCreditLabel}>Waiver Credit Amount: </Col>
          <Col>
            <CashInput
              size="large"
              disabled={!toggleChecked}
              value={waiverAmount}
              onChange={onChangeWaiverAmount}
              className={styles.waiverCreditInput}
            />
          </Col>
          <Col>
            <Select
              size="large"
              disabled={!toggleChecked}
              className={styles.waiverCreditSelect}
              defaultActiveFirstOption
              onChange={onChangeWaiverFrequency as any}
              value={waiverFrequency}
              options={Object.entries(WAIVER_CREDIT_FREQUENCY).map(
                ([value, label]) => ({
                  value,
                  ...label,
                })
              )}
            />
          </Col>
        </Row>
      )}
      <Row className={disabled ? styles.richText : ''}>
        <RichTextEditor
          limit={limit}
          initialValue={editorInitialContent}
          onChange={handleEditorChange}
          defaultText={defaultContent}
          ref={textEditorRef}
          masterId={benguideId}
          height={height}
          activateDefaultValue={activateDefaultValue}
          maxHeight={maxHeight}
          detectUserInput={detectUserInput}
          resetValue={resetValue}
          disabled={disabled}
        />
      </Row>
    </div>
  );
});

SectionEditor.displayName = 'SectionEditor';
export default SectionEditor;

import { useState, useEffect, useRef, useCallback } from 'react';
import reactHtmlParser from 'react-html-parser';
import isEmpty from 'lodash/isEmpty';
import { Collapse } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import SelectSectionName from 'model/SectionName';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import SectionEditor from 'components/SectionEditor/SectionEditor';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import { ReactComponent as HIWIcon } from 'images/pageHeaderIcons/how-insurance-works.svg';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SectionName } from 'modules/home/constants';
import { HIW_SECTIONS } from 'modules/howInsuranceWorks/constants';

import editIcon from 'images/icon-edit.svg';

import {
  getDefaultRichMedia,
  getCurrentRichMedia,
  createRichMedia,
  createRichMediaSuccess,
} from 'modules/howInsuranceWorks/slices/hiwSlice';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import styles from './howInsuranceWorks.module.less';

const { Panel } = Collapse;

type HIWSection = {
  title: string;
  content: string;
  isDirty: boolean;
};

const defaultSectionData = HIW_SECTIONS.map((section) => ({
  title: section.title,
  content: '',
  isDirty: false,
}));

const HowInsuranceWorks = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const textEditorRef = useRef<any>(null);
  const sectionEditorRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const defaultContent = useAppSelector(
    (state) => state.howInsuranceWorks.defaultContent
  );
  const { currentContent, requestType } = useAppSelector(
    (state) => state.howInsuranceWorks
  );

  const [contentData, setContentData] =
    useState<HIWSection[]>(defaultSectionData);
  const [displayData, setDisplayData] =
    useState<HIWSection[]>(defaultSectionData);
  const [selectedEditorSection, setSelectedEditorSection] =
    useState<SectionName>(HIW_SECTIONS[0].code);
  const [selectedPageSection, setSelectedPageSection] = useState<SectionName>(
    HIW_SECTIONS[0].code
  );
  const [defaultSectionContent, setDefaultSectionContent] =
    useState<HIWSection[]>(defaultSectionData);
  const [isResetDefaultPressed, setIsResetDefaultPressed] =
    useState<boolean>(false);
  const [editorChangedContent, setEditorChangedContent] = useState<
    HIWSection[]
  >([]);
  const [resetValue, setResetValue] = useState<string | undefined>('');
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [contentEdited, setContentEdited] = useState<boolean>(false);

  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const { masterId } = benguideData || {};
  const defSectionContentRef = useRef<HIWSection[]>(defaultSectionContent);
  const displayDataRef = useRef<HIWSection[]>(displayData);
  const contentDataRef = useRef<HIWSection[]>(contentData);

  const getCurrentRichMediaHIW = useCallback(
    (code: SectionName) => {
      dispatch(getCurrentRichMedia(masterId, code));
    },
    [dispatch, masterId]
  );

  useEffect(() => {
    defSectionContentRef.current = defaultSectionContent;
  }, [defaultSectionContent]);

  useEffect(() => {
    displayDataRef.current = displayData;
  }, [displayData]);

  useEffect(() => {
    contentDataRef.current = contentData;
  }, [contentData]);

  useEffect(() => {
    masterId && getCurrentRichMediaHIW(HIW_SECTIONS[0].code);
  }, [getCurrentRichMediaHIW, masterId]);

  useEffect(() => {
    HIW_SECTIONS.forEach((section) => {
      dispatch(getDefaultRichMedia(masterId, section.code));
    });
  }, [dispatch, masterId]);

  useEffect(() => {
    if (!isEmpty(defaultContent && defSectionContentRef.current)) {
      const newDefaultContent = defSectionContentRef.current.map((data) =>
        data.title === defaultContent.title
          ? { ...data, content: defaultContent.content, isDirty: false }
          : data
      );
      setDefaultSectionContent(newDefaultContent);
    }
  }, [defaultContent]);

  useEffect(() => {
    const currentTitle =
      HIW_SECTIONS.find((item) => item.code === selectedEditorSection)?.title ||
      '';

    const currentContent = contentDataRef.current.find(
      ({ title }) => title === currentTitle
    );

    const displayContent = displayData.find(
      ({ title }) => title === currentTitle
    );

    const initialContent = isEmpty(currentContent?.content)
      ? displayContent
      : currentContent;
    setResetValue(initialContent?.content);
    textEditorRef?.current?.changeEditorContent(currentContent?.content);
  }, [displayData, selectedEditorSection]);

  useEffect(() => {
    if (displayDataRef.current) {
      const newCurrentContent = displayDataRef.current.map((data) =>
        data.title === currentContent.title
          ? {
              ...data,
              content: currentContent.content,
              isDirty: data.isDirty !== undefined ? data.isDirty : false,
            }
          : data
      );
      setDisplayData(newCurrentContent);
    }
  }, [currentContent]);

  useEffect(() => {
    if (createRichMediaSuccess.type === requestType) {
      const newCurrentContent = contentDataRef.current.map((data) =>
        data.title === currentContent.title
          ? {
              ...data,
              content: currentContent.content,
              isDirty: false,
            }
          : data
      );
      setContentData(newCurrentContent);
    }
    // eslint-disable-next-line
  }, [requestType]);

  const updateEditorContent = (content: string) => {
    if (contentEdited) {
      const newCurrentContent = contentData.map((data) =>
        data.title === selectedSectionTitle
          ? { ...data, content, isDirty: true }
          : data
      );

      setContentData(newCurrentContent);
      setIsResetDefaultPressed(false);
    }
  };

  const detectInputChanged = () => {
    setContentEdited(true);
  };

  const resetToDefault = () => {
    const sectionDefaultContentData = defaultSectionContent.find(
      ({ title }) => title === selectedSectionTitle
    );

    if (sectionDefaultContentData) {
      const currentContentData = contentData.map((data) =>
        data.title === selectedSectionTitle
          ? {
              ...data,
              content: sectionDefaultContentData.content,
              isDirty: true,
            }
          : data
      );
      setContentData(currentContentData);
      textEditorRef.current.getEditorRef().current.editor.setDirty(true);
    }

    setIsResetDefaultPressed(true);
  };

  const handleSubmit = async (closeModal: Function) => {
    setContentEdited(false);
    const dirtyContent = contentData.filter((content) => content.isDirty);
    for (const section of dirtyContent) {
      const matchingSectionName = HIW_SECTIONS.find(
        ({ title }) => title === section.title
      );
      await dispatch(
        createRichMedia(
          masterId,
          matchingSectionName?.code as SectionName,
          section.content,
          section.title
        )
      );
    }

    setSelectedPageSection(selectedEditorSection);
    resetAll(selectedEditorSection);
    closeModal();
  };

  const collapseOnChange = (key: string | string[]) => {
    const currentKey = key as SectionName;
    setSelectedPageSection(currentKey);
    setSelectedEditorSection(currentKey);
    key && getCurrentRichMediaHIW(currentKey);
  };

  const onCloseModal = (onClose: Function) => {
    setContentEdited(false);
    if (!isEmpty(displayData)) {
      setContentData(displayData);
    }
    const selectedPageSectionTitle =
      HIW_SECTIONS.find((item) => item.code === selectedPageSection)?.title ||
      '';
    if (
      selectedPageSectionTitle === selectedSectionTitle &&
      textEditorRef.current
    ) {
      textEditorRef.current.clearTextEditorContent(
        displayDataSectionEditor?.content
      );
    }
    resetAll(selectedPageSection);
    onClose();
  };

  const onChangeSection = (value: SectionName) => {
    setIsResetDefaultPressed(false);
    setSelectedEditorSection(value);
    value && getCurrentRichMediaHIW(value);
  };

  const resetAll = (requiredSection: SectionName) => {
    if (sectionEditorRef.current) {
      sectionEditorRef.current.resetSelectedOption();
    }
    setEditorChangedContent([]);
    setSelectedEditorSection(requiredSection);
    setIsResetDefaultPressed(false);
  };

  const selectedSectionTitle =
    HIW_SECTIONS.find((item) => item.code === selectedEditorSection)?.title ||
    '';

  const displayDataSectionEditor = displayData.find(
    ({ title }) => title === selectedSectionTitle
  );

  const defaultCurrentSectionContent = defaultSectionContent.find(
    ({ title }) => title === selectedSectionTitle
  );

  const editorCurrentSectionChangedContent = editorChangedContent.find(
    ({ title }) => title === selectedSectionTitle
  );

  const editorInitialContent =
    displayDataSectionEditor !== editorCurrentSectionChangedContent &&
    !isEmpty(editorCurrentSectionChangedContent)
      ? editorCurrentSectionChangedContent
      : displayDataSectionEditor;

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <div className={styles.hiwWrapper} ref={textRef}>
        <PageHeader
          pageHeaderTitle="How Insurance Works"
          pageHeaderIcon={<HIWIcon className={styles.hiwIcon} />}
        />
        <BenefitGuideRefreshChannel />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <div className={styles.textArea}>
          <Collapse
            activeKey={selectedPageSection}
            defaultActiveKey={HIW_SECTIONS[0].code}
            onChange={collapseOnChange}
            bordered={false}
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
            }
            destroyInactivePanel
          >
            {HIW_SECTIONS.map((section: SelectSectionName) => {
              const displayDataSection = displayData.find(
                ({ title }) => title === section.title
              );

              return (
                <Panel key={section.code} header={section.title}>
                  <EditableHotspot
                    alwaysVisible={false}
                    editText="Edit Text"
                    editIcon={<img src={editIcon} alt="edit" />}
                    customModal={(visible: boolean, onClose: Function) => (
                      <HalfScreenModal
                        width="68%"
                        title="How Insurance Works"
                        visible={visible}
                        onReset={resetToDefault}
                        onCancel={() => onCloseModal(onClose)}
                        onSubmit={() => handleSubmit(onClose)}
                        confirmModalShow
                        increaseResetSpacing
                        afterClose={() => textEditorRef?.current?.resetUndo()}
                      >
                        <SectionEditor
                          sectionNames={HIW_SECTIONS}
                          defaultContent={defaultCurrentSectionContent?.content}
                          editorInitialContent={editorInitialContent?.content}
                          handleEditorChange={(data: string) =>
                            updateEditorContent(data)
                          }
                          benguideId={masterId}
                          textEditorRef={textEditorRef}
                          onChange={onChangeSection}
                          defaultValue={selectedPageSection}
                          limit={5000}
                          ref={sectionEditorRef}
                          activateDefaultValue={isResetDefaultPressed}
                          changedEditorContent={
                            editorCurrentSectionChangedContent?.content
                          }
                          resetValue={resetValue}
                          maxHeight={400}
                          detectUserInput={detectInputChanged}
                        />
                      </HalfScreenModal>
                    )}
                  >
                    <div className={styles.hiwContent}>
                      {displayDataSection?.content ? (
                        reactHtmlParser(displayDataSection?.content)
                      ) : (
                        <p>&nbsp;</p>
                      )}
                    </div>
                  </EditableHotspot>
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default HowInsuranceWorks;

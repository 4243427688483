import { FC } from 'react';

import { fieldFormatters } from 'modules/benefitsWellness/util';
import { SUPER_SCRIPTS } from 'modules/benefitsWellness/constants/BenefitConstants';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';

import styles from './mdvFormattedValue.module.less';

type MDVFormattedValueProps = {
  data: any;
  formatter: string;
  isEdit?: boolean;
  removeSup?: boolean;
};

const defaultValue = '-';

const showSuperScript = (
  data: any,
  value: string | undefined,
  removeSup: boolean
) => {
  if (
    data &&
    data.copayPriorToDeductible !== 'YES' &&
    data.benefitCovered !== 'NO' &&
    value &&
    value !== defaultValue
  ) {
    return (
      <>
        {value.replace(SUPER_SCRIPTS[0].strValue, '')}
        {!removeSup ? <sup>{SUPER_SCRIPTS[0].superScriptValue}</sup> : ''}
      </>
    );
  }
  return value;
};

const MDVFormattedValue: FC<MDVFormattedValueProps> = (
  props: MDVFormattedValueProps
) => {
  const { data, formatter, isEdit = false, removeSup = false } = props;
  const formattedValue = fieldFormatters(data, formatter);
  const value = showSuperScript(data, formattedValue, removeSup);
  if (value === defaultValue && isEdit) {
    return <MissingInfo missingInfoText="Missing Info" />;
  }
  return (
    <div
      className={styles.benefitDetail}
      title={
        formattedValue?.includes(SUPER_SCRIPTS[0].strValue)
          ? formattedValue.replace(SUPER_SCRIPTS[0].strValue, '(1)')
          : formattedValue
      }
    >
      <span className={styles.networkValue}>{value}</span>
    </div>
  );
};

export default MDVFormattedValue;

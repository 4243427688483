import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import SectionName from 'model/SectionName';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import SectionEditor from 'components/SectionEditor/SectionEditor';

import styles from './planCardEditor.module.less';

type PlanCardEditorProps = {
  isOpenModal: boolean;
  closeModal: Function;
  editorContent: string;
  updateEditorContent: (data: string) => void;
  resetToDefault: () => void;
  isResetEnabled: boolean;
  benguideId?: string;
  defaultContent?: string;
  resetToCurrentValue: Function;
  onSubmit?: (content: string) => void;
  sectionEditEnabled?: boolean;
  defaultSelectedOption?: string;
  onSelectOptionChange?: Function;
  sectionNames?: SectionName[];
  setCurrentConetnt?: Function;
};

const PlanCardEditor = forwardRef((props: PlanCardEditorProps, ref) => {
  useImperativeHandle(ref, () => ({
    changeContent(value: string) {
      if (textEditorRef.current) {
        textEditorRef.current.changeEditorContent(value);
      }
    },
  }));

  const {
    isOpenModal,
    closeModal,
    editorContent,
    updateEditorContent,
    resetToDefault,
    isResetEnabled,
    benguideId,
    defaultContent,
    resetToCurrentValue,
    onSubmit,
    sectionEditEnabled = false,
    defaultSelectedOption,
    onSelectOptionChange = () => {},
    sectionNames,
    setCurrentConetnt,
  } = props;

  const textEditorRef = useRef<any>(null);

  const [content, setContent] = useState<string>(editorContent);

  const resetContent = () => {
    const { current } = textEditorRef;
    const { clearTextEditorContent } = current;
    clearTextEditorContent(defaultContent);
    resetToDefault();
  };

  useEffect(() => {
    if (isOpenModal) {
      setContent(editorContent || '');
      textEditorRef.current.changeEditorContent(editorContent);
    }
  }, [isOpenModal, editorContent]);

  const closeOverviewModal = () => {
    resetToCurrentValue();
    if (textEditorRef.current && !sectionEditEnabled) {
      textEditorRef.current.clearTextEditorContent();
    }
    if (setCurrentConetnt) {
      setCurrentConetnt();
    }
    closeModal();
  };

  const handleEditorChange = (data: string) => {
    setContent(data);
    sectionEditEnabled && updateEditorContent(data);
  };

  const handleSubmit = () => {
    updateEditorContent(content);
    onSubmit && onSubmit(content);
    closeModal();
  };

  return (
    <HalfScreenModal
      width={900}
      title="Edit Text"
      visible={isOpenModal}
      onReset={resetContent}
      onCancel={closeOverviewModal}
      onSubmit={handleSubmit}
      confirmModalShow={true}
      isResetEnabled={isResetEnabled}
      footerStyles={styles.footerColorCustomizer}
      afterClose={() => textEditorRef?.current?.resetUndo()}
    >
      {sectionEditEnabled && (
        <SectionEditor
          sectionNames={sectionNames || []}
          onChange={onSelectOptionChange}
          textEditorRef={textEditorRef}
          handleEditorChange={handleEditorChange}
          editorInitialContent={editorContent}
          defaultContent={editorContent}
          limit={1500}
          benguideId={benguideId}
          defaultValue={defaultSelectedOption}
          activateDefaultValue
        />
      )}
      {!sectionEditEnabled && (
        <RichTextEditor
          limit={1500}
          initialValue={editorContent}
          onChange={handleEditorChange}
          defaultText={editorContent}
          ref={textEditorRef}
          masterId={benguideId}
          activateDefaultValue={false}
        />
      )}
    </HalfScreenModal>
  );
});

PlanCardEditor.displayName = 'PlanCardEditor';

export default PlanCardEditor;

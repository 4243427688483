import { FC, useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { NotificationContextProvider } from 'context/NotificationContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  findFrequencyByBenefitKind,
  getAgeBandedPlans,
  hasSuperScripts,
} from 'modules/benefitsWellness/util';
import MedicalCardWrapper from 'modules/benefitsWellness/components/MedicalCardWrapper/MedicalCardWrapper';
import MDVPlansWrapper from 'modules/benefitsWellness/components/MDVPlansWrapper/MDVPlansWrapper';
import MedicalPlansChannel from 'modules/clients/UPClient/channels/MedicalPlansChannel';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import NoMDVPlansView from 'components/NoPlansView/NoMDVPlansView';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import {
  getCustomFieldsToState,
  getMedicalPlanList,
  saveAndReloadMedicalSection,
} from 'modules/benefitsWellness/slices/medicalSlice';
import useBenguide from 'hooks/benguide';
import MDVPlan from 'model/MDVPlan';
import {
  AGE_BAND,
  FOUR_TIER,
  N_TIER,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';
import Rate from 'model/Rate';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { PLAN_RECOMMENDER } from 'modules/home/constants';
import { planRecommenderToggle } from 'services/BenGuideService';
import styles from './medical.module.less';

type MedicalProps = {
  onCloseContributions?: Function;
};

const Medical: FC<MedicalProps> = (props: MedicalProps) => {
  const { onCloseContributions = () => {} } = props;
  const { medical } = useAppSelector((state: any) => state);
  const { selectedNetwork, rateValidations } = useAppSelector(
    (state) => state.benefitWellness
  );
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isOpenContributionModal, setIsOpenContributionModal] =
    useState<boolean>(false);
  const [contributionModalClose, setContributionModalClose] =
    useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [planListUpdateFlag, setPlanListUpdateFlag] = useState<boolean>(false);

  const { planList, medicalSection = [] } = medical;
  const { plans } = medicalSection;
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const { masterId, planYearId, employerId, benefitClasses } = benguide;
  const isEditMode = benguide.isEditMode;
  const updatePlanList = async (
    planList: MDVPlan[],
    orderByPlanNames: boolean,
    updatedPlans: boolean
  ) => {
    setPlanListUpdateFlag(!planListUpdateFlag);
    const enabled = {
      enabled: false,
    };
    if (updatedPlans) {
      await planRecommenderToggle(masterId, PLAN_RECOMMENDER, enabled);
    }
    dispatch(saveAndReloadMedicalSection(masterId, planList, orderByPlanNames));
  };
  const contribFreq = findFrequencyByBenefitKind(
    benguide.contributionFrequencies,
    BENEFIT_CATEGORY.MEDICAL.value
  );
  const { enabled } = contribFreq;

  const enablePlanRecommender = plans.length > 1;

  useEffect(() => {
    dispatch(getCustomFieldsToState());
  }, [dispatch]);

  useEffect(() => {
    setContributionModalClose(contributionModalClose);
  }, [contributionModalClose]);

  const reloadAllMedicalPlans = useCallback(() => {
    dispatch(getMedicalPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, benefitClasses, dispatch]);

  useEffect(() => {
    onCloseContributions(contributionModalClose);
  }, [contributionModalClose, onCloseContributions]);

  const foundRates =
    (!isEmpty(plans) &&
      plans
        .map((plan: MDVPlan) => plan.rates)
        .filter((plan: MDVPlan) => plan)
        .map((rate: Rate) => {
          return Object.values(rate);
        })
        .reduce((arr1: [], arr2: []) => {
          return arr1.concat(arr2);
        }, [])
        .map((rate: Rate) => rate.type)) ||
    [];

  const rateType = foundRates.includes(N_TIER)
    ? N_TIER
    : foundRates.includes(FOUR_TIER)
    ? FOUR_TIER
    : AGE_BAND;

  const taxADVTaggedPlans =
    (!isEmpty(plans) &&
      plans.filter((plan: MDVPlan) =>
        plan?.hsaMedicalContributionVO?.every(
          (contribution) =>
            contribution?.benefitKind === 'HSA' ||
            contribution?.benefitKind === 'HRA'
        )
      )) ||
    [];

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.medicalWrapper}>
        <MedicalPlansChannel />
        <BenefitGuideRefreshChannel benefitKind={MDVTypes.MEDICAL} />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <MDVPlansWrapper
          planType={MDVTypes.MEDICAL}
          addedPlansCount={plans.length}
          enablePlanRecommender={enablePlanRecommender}
          showPlanRecommender={plans.length > 0}
          disablePlanRecommenderOnEdit={!(plans.length > 0)}
          rateType={rateType}
          onView={setIsOpenContributionModal}
          hasMismatchContributions={
            isEmpty(taxADVTaggedPlans)
              ? rateValidations.hasMismatchContributions ||
                !enabled ||
                getAgeBandedPlans(plans).length === plans.length
              : false
          }
          closeContributions={contributionModalClose}
          isUpdated={isChanged}
          planListUpdateFlag={planListUpdateFlag}
        />
        {plans.length > 0 ? (
          <MedicalCardWrapper
            onUpdatePlanList={updatePlanList}
            allPlans={planList.data}
            reloadPlans={reloadAllMedicalPlans}
            showAlert={showAlert}
            isContributionsViewOpen={isOpenContributionModal}
            isLoading={planList.inProgress}
            onCloseContributions={setContributionModalClose}
            isUpdated={setIsChanged}
          />
        ) : isEditMode ? (
          <AddOrRemovePlans
            visible={true}
            withoutModal={true}
            title="Medical"
            onSave={updatePlanList}
            addedPlanList={plans}
            allPlans={planList.data}
            reloadPlans={reloadAllMedicalPlans}
            isLoading={planList.inProgress}
            showAlert={showAlert}
          />
        ) : (
          <NoMDVPlansView />
        )}
        {plans.length > 0 && (
          <div className={styles.bottomText}>
            {hasSuperScripts(plans, selectedNetwork).size > 0 && (
              <>
                <sup>1</sup>After Deductible is Met
                <br />
              </>
            )}
            {LEGAL_DISCLAIMER}
          </div>
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default Medical;

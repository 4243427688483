import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import Section from 'model/Section';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import {
  setCloseModalsInUP,
  updateBenguideLatestRevision,
} from 'modules/auth/slices/benguideSlice';
import * as worklifeService from 'services/WorkLifeService';
import * as planService from 'modules/auth/services/PlanService';
import { SectionName } from 'modules/home/constants';
import * as BenGuideService from 'services/BenGuideService';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { convertEncodedStringToBlob } from 'utils/fileUtil';
import PageSection from 'model/PageSection';

const initialState = {
  planList: {
    inProgress: false,
    data: null,
    error: null,
  },
  familyAndLeaveSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  richMedia: {},
  sectionImage: {
    inProgress: false,
    image: '',
    error: null,
  },
  latestRevision: 0,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.BACKUP_CHILDCARE]: '',
      [SectionName.BEREAVEMENT]: '',
      [SectionName.FERTILITY]: '',
      [SectionName.FAMILY_AND_LEAVE_OTHER]: '',
      [SectionName.PARENTAL_LEAVE]: '',
    },
  },
  sectionImageOriginal: '',
  sections: [] as PageSection[],
};

const familyAndLeaveSlice = createSlice({
  name: 'familyAndLeave',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.data = payload;
      state.planList.error = null;
      state.planList.inProgress = false;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
    },
    familyAndLeaveSectionUpdateStart: (state) => {
      state.familyAndLeaveSection.inProgress = true;
    },
    familyAndLeaveSectionUpdateCompleted: (state, { payload }) => {
      state.familyAndLeaveSection.inProgress = false;
      state.familyAndLeaveSection.enable = payload.enable;
      state.familyAndLeaveSection.plans = payload.plans;
      state.familyAndLeaveSection.error = null;
    },
    familyAndLeaveSectionUpdateFailed: (state, { payload }) => {
      state.familyAndLeaveSection.inProgress = false;
      state.familyAndLeaveSection.error = payload;
    },
    richMediaFetchingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingCompleted: (state, { payload }) => {
      const { planId, content } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, content);
      set(state, `richMedia.${planId}.error`, null);
    },
    richMediaUpdatingFailed: (state, { payload }) => {
      const { planId, error } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, '');
      set(state, `richMedia.${planId}.error`, error);
    },
    uploadSectionImageStart(
      state,
      action: PayloadAction<{ image: string; original?: string }>
    ) {
      state.sectionImage.image = action.payload.image;
      state.sectionImage.error = null;
      state.sectionImage.inProgress = true;
      if (action.payload.original) {
        state.sectionImageOriginal = action.payload.original;
      }
    },
    uploadSectionImageSuccess(state, { payload }) {
      state.sectionImage.error = null;
      state.sectionImage.image = payload;
      state.sectionImage.inProgress = false;
      state.latestRevision = payload.latestRevision;
    },
    uploadSectionImageFailed(state, { payload }) {
      state.sectionImage.error = payload;
      state.sectionImage.image = '';
      state.sectionImage.inProgress = false;
    },
    deleteSectionImageStart(state) {
      state.sectionImage.inProgress = true;
      state.sectionImage.error = null;
    },
    deleteSectionImageSuccess(state) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = null;
    },
    deleteSectionImagaFailed(state, { payload }) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = payload;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      (state.defaultRichMedia.data as any)[payload.section] =
        payload.media.content;
    },
    getDefaultMediaFailed(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    setSections(state, { payload }) {
      state.sections = payload;
    },
  },
});

export const {
  planListFetchingCompleted,
  planListFetchingFailed,
  planListFetchingStarted,
  familyAndLeaveSectionUpdateStart,
  familyAndLeaveSectionUpdateCompleted,
  familyAndLeaveSectionUpdateFailed,
  richMediaFetchingStarted,
  richMediaUpdatingCompleted,
  richMediaUpdatingFailed,
  richMediaUpdatingStarted,
  deleteSectionImagaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  uploadSectionImageFailed,
  uploadSectionImageStart,
  uploadSectionImageSuccess,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  setSections,
} = familyAndLeaveSlice.actions;

export const fetchFamilyAndLeavePlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    worklifeService
      .getFamilyAndLeavePlanList(employerId, planYearId, benefitClasses)
      .then((response) => {
        const { data } = response || {};
        dispatch(planListFetchingCompleted(data));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateFamilyAndLeaveSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    dispatch(setSections(section.sections));
    const planPromisses = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return planService.getPlans('work-life-plans', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as Section
    );
    dispatch(familyAndLeaveSectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const updatedPlanList = (
  benguideId: string,
  request: SectionApiRequestPayload
) => {
  return async (dispatch: Dispatch) => {
    BenGuideService.updateBenefitSection(benguideId, request)
      .then(({ data }) => {
        const { benefitPage } = data || {};
        dispatch(updateFamilyAndLeaveSection(benefitPage));
      })
      .catch((error) => {
        dispatch(familyAndLeaveSectionUpdateFailed(error));
      });
  };
};

export const addMultiplePlans = (
  benguideId: string,
  request: Array<AddPlanApiRequestPayload>
) => {
  return async (dispatch: Dispatch) => {
    BenGuideService.addMultiplePlans(benguideId, { newPlans: request })
      .then(({ data }) => {
        dispatch(
          reloadBenefitAndFamilyAndLeaveSection(benguideId, data.latestRevision)
        );
      })
      .catch((error) => {
        dispatch(familyAndLeaveSectionUpdateFailed(error));
      });
  };
};

export const reloadBenefitAndFamilyAndLeaveSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenGuideService.getFamilyAndLeaveSection(
      benguideId,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateFamilyAndLeaveSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaContent = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaFetchingStarted(planId));
    BenGuideService.getRichMediaContent(benguideId, benefitKind, planId)
      .then(({ data }) => {
        const { content } = data;
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const updateRichMediaContent = (
  benguideId: string,
  planId: string,
  content: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaUpdatingStarted(planId));
    BenGuideService.updateRichMediaContent(
      benguideId,
      benefitKind,
      planId,
      content
    )
      .then(() => {
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const handleSectionImageUpload =
  (
    benguideId: string,
    image: string,
    sectionName: SectionName,
    originalImage?: string,
    cropArea?: OriginalImageCropParams,
    originalRef?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStart({ image, original: originalImage }));
    try {
      let updateOriginalRef = originalRef;

      if (originalImage) {
        const originalImgBlob = await convertEncodedStringToBlob(originalImage);
        const originalImgRes = await BenGuideService.uploadSectionImage(
          benguideId,
          originalImgBlob,
          sectionName,
          true
        );
        updateOriginalRef = originalImgRes.data.imageReference;
      }
      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenGuideService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName,
        false,
        updateOriginalRef,
        cropArea
      );
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
      dispatch(
        reloadBenefitAndFamilyAndLeaveSection(
          benguideId,
          response.data.latestRevision
        )
      );
      dispatch(uploadSectionImageSuccess(response.data));
    } catch (error) {
      dispatch(uploadSectionImageFailed(error));
    }
  };

export const handleSectionImageDelete =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      await BenGuideService.deleteSectionImage(benguideId, sectionName);
      dispatch(deleteSectionImageSuccess());
    } catch (error) {
      dispatch(deleteSectionImagaFailed(error));
    }
  };

export const getDefaultText = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    let sectionName = SectionName.BACKUP_CHILDCARE;
    if (benefitKind === benefitCategory.BEREAVEMENT.value) {
      sectionName = SectionName.BEREAVEMENT;
    } else if (benefitKind === benefitCategory.FERTILITY.value) {
      sectionName = SectionName.FERTILITY;
    } else if (benefitKind === benefitCategory.FAMILY_AND_LEAVE_OTHER.value) {
      sectionName = SectionName.FAMILY_AND_LEAVE_OTHER;
    } else if (benefitKind === benefitCategory.PARENTAL_LEAVE.value) {
      sectionName = SectionName.PARENTAL_LEAVE;
    }
    BenGuideService.getRichMediaContentForPlan(
      benguideId,
      sectionName,
      planId,
      true
    )
      .then(({ data }) => {
        dispatch(
          getDefaultMediaSuccess({
            section: sectionName,
            media: data,
          })
        );
      })
      .catch((error) => {
        dispatch(getDefaultMediaFailed(error));
      });
  };
};

export default familyAndLeaveSlice.reducer;

import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import {
  MONTHLY,
  PLAN_RECOMMENDER_QUESTIONS,
} from 'modules/planRecommender/pages/constants';
import { getCurrentAnswerByQuestionType } from 'modules/planRecommender/pages/PlanRecommender/PlanRecommender';
import iconClose from 'images/icon-close.svg';
import rankIconStarGreen from 'images/plan-recommender/icon-rank-star-green.svg';
import carrierLogo from 'images/plan-recommender/Icon-Medical.svg';
import PlanSummaryBox from 'modules/planRecommender/components/OtherPlansSummary/PlanSummaryBox/PlanSummaryBox';

import styles from './otherPlansSummary.module.less';

type OtherPlansSummaryProps = {
  questions: any;
  isEditDrawerOpen: boolean;
  onClose: Function;
  downloadResultPdf: MouseEventHandler<HTMLButtonElement>;
  isdrawerOpen: boolean;
  questionsInProgress: boolean;
};

const PlanTypeInfo = ({ sectorSubTitle, sectorPara }: any) => {
  return (
    <div>
      <div className={styles.sectorSubtitle}>{sectorSubTitle}</div>
      {sectorPara}
    </div>
  );
};

const OtherPlansSummary: FC<OtherPlansSummaryProps> = (
  props: OtherPlansSummaryProps
) => {
  const { isEditDrawerOpen, onClose, downloadResultPdf, isdrawerOpen } = props;
  const [questions, setQuestions] = useState<any>(props.questions);

  const { benefitClasses } = useBenguide();
  const { plans } = useAppSelector((state) => state.planRecommender);

  // Transforms rates object to extract rates for first benefit class of the benguide
  const transformedPlans = useMemo(() => {
    const firstBenefitClass: undefined | string =
      benefitClasses && benefitClasses[0];

    if (plans.length > 0 && firstBenefitClass) {
      return plans.map((plan) => {
        const clonedPlan = cloneDeep(plan);
        let selectedRate = null;
        for (const group in plan?.rates) {
          if (group === firstBenefitClass) {
            selectedRate = plan.rates[group];
            break;
          }
        }

        if (selectedRate) {
          clonedPlan.rates = selectedRate;
        }
        return clonedPlan;
      });
    }
    return [];
  }, [plans, benefitClasses]);

  const premiumFrequency = useMemo(() => {
    if (transformedPlans && transformedPlans.length > 0) {
      return transformedPlans[0]?.annualPremiumConfig?.premiumFrequency;
    }
    return MONTHLY;
  }, [transformedPlans]);

  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions, isEditDrawerOpen]);

  const PLAN_RECOMMENDER_HEADER_TEXTS = {
    COVERAGE: {
      EE: ' just you, ',
      ES: ' you and your partner, ',
      EC: ' you and child(ren), ',
      EF: ' you and your family, ',
    },
    DOCTOR_VISITS: {
      0: (
        <>
          {' '}
          <u>not</u> visiting the doctor,{' '}
        </>
      ),
      2: (
        <>
          visiting the doctor <u>rarely</u>,{' '}
        </>
      ),
      5: (
        <>
          visiting the doctor <u>occasionally</u>,{' '}
        </>
      ),
      10: (
        <>
          visiting the doctor <u>often</u>,{' '}
        </>
      ),
      15: (
        <>
          visiting the doctor <u>frequently</u>,
        </>
      ),
    },
    PROCEDURES: {
      true: (
        <>
          <u>not</u> planning a pregnancy or any procedures,
        </>
      ),
      false: (
        <>
          planning a <u>pregnancy or procedures</u>,
        </>
      ),
    },
    SPECIALIST_VISITS: {
      0: (
        <>
          {' '}
          <u>not</u> visiting a specialist,{' '}
        </>
      ),
      2: (
        <>
          visiting a specialist <u>rarely</u>,
        </>
      ),
      5: (
        <>
          visiting a specialist <u>occasionally</u>,
        </>
      ),
      10: (
        <>
          visiting a specialist <u>often</u>,
        </>
      ),
      15: (
        <>
          visiting a specialist <u>frequently</u>,
        </>
      ),
    },
    DOCTOR_PREFERENCE: {
      IN_NETWORK: (
        <>
          using a <u>narrow network</u>,
        </>
      ),
      BROAD_RANGE: (
        <>
          using a <u>wide network,</u>
        </>
      ),
      KAISER_ONLY: (
        <>
          using a <u>Kaiser</u> ,
        </>
      ),
    },
  } as any;

  const getHeaderText = () => {
    let coveringType = '';
    let isProceduresNone = '';
    let doctorVisits = '';
    let specialistVisits = '';
    let doctorPreference = '';
    let prescriptions = [] as number[];
    let drugsType = [] as string[];

    if (questions[1].currentAnswer !== undefined) {
      coveringType = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.COVERAGE
      )[0];
    }
    if (questions[2].currentAnswer !== undefined) {
      isProceduresNone = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.PROCEDURES
      ).includes('NONE');
    }

    if (questions[3].currentAnswer !== undefined) {
      doctorVisits = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.DOCTOR_VISITS
      );
    }

    if (questions[3].currentAnswer !== undefined) {
      specialistVisits = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.SPECIALIST_VISITS
      );
    }

    if (questions[4].currentAnswer !== undefined) {
      doctorPreference = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.DOCTOR_PREFERENCE
      )[0];
    }
    if (questions[5].currentAnswer !== undefined) {
      prescriptions = getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.PRESCRIPTIONS
      ) || [0, 0, 0, 0];

      drugsType = ['low', 'medium', 'high', 'specialty'].filter(
        (drug, index) => {
          return prescriptions[index] > 0;
        }
      );
    }

    const textSegments = [
      <React.Fragment key={1}>
        This is based on covering benefits for{' '}
      </React.Fragment>,
      <React.Fragment key={2}>
        <u>{PLAN_RECOMMENDER_HEADER_TEXTS.COVERAGE[coveringType]}</u>{' '}
      </React.Fragment>,
      <React.Fragment key={3}>
        {PLAN_RECOMMENDER_HEADER_TEXTS.PROCEDURES[isProceduresNone]}{' '}
      </React.Fragment>,
      <React.Fragment key={4}>
        {PLAN_RECOMMENDER_HEADER_TEXTS.DOCTOR_VISITS[doctorVisits]}{' '}
      </React.Fragment>,
      <React.Fragment key={5}>
        {PLAN_RECOMMENDER_HEADER_TEXTS.SPECIALIST_VISITS[specialistVisits]}{' '}
      </React.Fragment>,
      <React.Fragment key={6}>
        {PLAN_RECOMMENDER_HEADER_TEXTS.DOCTOR_PREFERENCE[doctorPreference]}{' '}
      </React.Fragment>,
      <React.Fragment key={7}>
        {drugsType !== undefined && drugsType.length === 0 ? (
          <>
            and <u>not taking any prescription drugs.</u>{' '}
          </>
        ) : (
          <>
            and using{' '}
            <u>
              {drugsType !== undefined &&
                drugsType.join(', ').replace(/, ([^,]*)$/, ' & $1')}{' '}
              cost drugs
            </u>
            .
          </>
        )}
      </React.Fragment>,
    ];

    return textSegments;
  };

  const close = () => {
    onClose();
  };

  const getRecommendedPlanName = (plans: any) => {
    const recommendedPlan =
      plans.length > 0
        ? plans.find((plan: any) => (plan ? plan.rank === 1 : false))
        : {};
    const { name = '' } = recommendedPlan || {};

    return name;
  };

  const classes = [styles.overlay];
  if (!isdrawerOpen) {
    classes.push(styles.closed);
  }
  return (
    <div className={styles.otherPlansSummaryWrapper}>
      <div className={styles.planRecommenderHeader}>
        <div className={styles.planRecommenderPlansWrapper}>
          <div className={classes.join(' ')}>
            <div className={styles.closeIcon} onClick={close}>
              <img src={iconClose} alt="close" />
              <div className={styles.closeText}>close</div>
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.summaryTitle}>
                We recommend the {getRecommendedPlanName(transformedPlans)} plan
                for you!
              </div>
              <div className={styles.summaryCaption}>
                <p>{getHeaderText()}</p>
              </div>
              <div className={styles.summaryBody}>
                <div className={styles.subTitle}>Top Plans</div>
                <hr />
                <h2 className={styles.recommendation}>RECOMMENDATION</h2>
                {transformedPlans.map((plan, index) => {
                  if (plan.rank <= 3) {
                    return (
                      <PlanSummaryBox
                        rankIcon={rankIconStarGreen}
                        carrierLogo={carrierLogo}
                        key={index}
                        plan={plan}
                        dbgFrequency={premiumFrequency}
                      />
                    );
                  }
                  return null;
                })}

                <div className={styles.subTitle}>Additional Plans</div>
                <hr className={styles.separator} />
                {transformedPlans.map((plan, index) => {
                  if (plan.rank > 3) {
                    return (
                      <PlanSummaryBox
                        rankIcon={rankIconStarGreen}
                        carrierLogo={carrierLogo}
                        key={index}
                        plan={plan}
                        dbgFrequency={premiumFrequency}
                      />
                    );
                  }
                  return null;
                })}
                <div className={styles.subTitle}>
                  Learn More About All Plan Types
                </div>
                <hr className={styles.separator} />
                <PlanTypeInfo
                  sectorSubTitle="What is a PPO?"
                  sectorPara={
                    <>
                      <p>
                        A PPO, or Preferred Provider Organization provides a
                        wider degree of choice. Insurance carriers make
                        agreements with medical providers every year on
                        participating in these PPO networks to provide consumers
                        a wider range of choice on healthcare.
                      </p>
                      <div className="para-spacing">
                        With a PPO, the network is wider and no physician
                        referral is required to go see a specialist. If you need
                        to go outside of a PPO network, the insurer will still
                        provide some coverage, but your out-of-network coverage
                        will result in higher out-of-pocket costs.
                      </div>
                    </>
                  }
                />
                <PlanTypeInfo
                  sectorSubTitle="What is an EPO?"
                  sectorPara={
                    <>
                      <p>
                        An EPO or Exclusive Provider Organization provides a
                        hybrid of an HMO and a PPO. On the one hand, like a PPO,
                        you do not need a physician’s referral to go see a
                        specialist. On the other hand, like an HMO, you must
                        stay within the network.
                      </p>
                    </>
                  }
                />
                <PlanTypeInfo
                  sectorSubTitle="What is an HMO?"
                  sectorPara={
                    <>
                      <p>
                        A Health Maintenance Organization, or HMO plan, is a
                        fully integrated health plan that includes doctors,
                        pharmacies and administrative functions all within a
                        single network. You pick a primary care doctor who
                        serves as a primary point of contact. When necessary,
                        you are required to get a referral from your primary
                        care physician to see a specialist within the HMO
                        network, such as a dermatologist.
                      </p>
                      <div className="para-spacing">
                        One of the primary reasons employees like HMOs is
                        because they serve as a single provider for everything.
                        Because of this, HMOs typically have lower
                        administrative costs associated with running their
                        networks.
                      </div>
                    </>
                  }
                />
                <PlanTypeInfo
                  sectorSubTitle="What is an HSA-Eligible Plan?"
                  sectorPara={
                    <>
                      <p>
                        An HSA-eligible plan is a High-Deductible Health Plan
                        (HDHP) that can be paired with a Health Savings Account
                        (HSA). An HDHP can be an HMO, PPO, or EPO. The key
                        difference is how you share in health care costs. An
                        HDHP has lower premiums, but you pay all costs
                        out-of-pocket (including prescriptions) until you meet
                        the plan’s deductible. Once you meet the deductible, the
                        plan shares costs (copays or coinsurance kicks in).
                      </p>
                      <div className="para-spacing">
                        The HSA serves as a “health 401(k)” you can use to
                        offset your out-of-pocket costs now—or let it build to
                        pay for future health care costs after your retire. An
                        HSA is a personal bank account, and the money is yours
                        to keep even if you change employers. HSA funds are
                        always yours to spend on qualified medical expenses (you
                        just can’t *contribute* to an HSA unless you have an
                        HDHP). HSAs funds rollover year-to-year, and they have a
                        triple tax advantage (contributions are not federally
                        taxed, funds grow tax-free, and funds used to pay for
                        qualified medical expenses are not taxed).
                      </div>
                    </>
                  }
                />
                <PlanTypeInfo
                  sectorSubTitle="What is a POS?"
                  sectorPara={
                    <>
                      <p>
                        POS – Point-of-Service (in-network and out-of-network
                        coverage): This plan model is a hybrid of the other
                        three. You must select a primary care physician who
                        handles your referrals, but they may refer you to a
                        specialist that is out-of-network unlike in an HMO or
                        EPO. This difference makes it important to understand
                        the out-of-network cost rates (coinsurance, copays,
                        etc.) associated with your plan choice.
                      </p>
                    </>
                  }
                />

                <div className={styles.subTitle}>How Other Plans Compare</div>
                <hr className={styles.separator} />
                <div>
                  <p>
                    Knowing the difference between plan types and their networks
                    can help you choose the best plan for your needs and
                    preferences.
                  </p>
                </div>
                <div className={styles.tableContainer}>
                  <div className={styles.tableContainerOuter}>
                    <div className={styles.tableContainerInner}>
                      <table className={styles.tableStyle}>
                        <tbody>
                          <tr>
                            <th
                              className={`${styles.compareTableHeader} ${styles.compareTableHeaderRight}`}
                            ></th>
                            <th
                              className={`${styles.compareTableHeader} ${styles.compareTableHeaderRight}`}
                            >
                              <span>HMO</span>
                              <br />
                              <span className={styles.compareTableSubTitle}>
                                Health Maintenance
                                <div>Organization</div>
                              </span>
                            </th>
                            <th
                              className={`${styles.compareTableHeader} ${styles.compareTableHeaderRight}`}
                            >
                              <span>PPO</span>
                              <br />
                              <span className={styles.compareTableSubTitle}>
                                Preferred Provider
                                <div>Organization</div>
                              </span>
                            </th>
                            <th
                              className={`${styles.compareTableHeader} ${styles.compareTableHeaderRight}`}
                            >
                              <span>EPO</span>
                              <br />
                              <span className={styles.compareTableSubTitle}>
                                Exclusive Provider
                                <div>Organization</div>
                              </span>
                            </th>

                            <th className={`${styles.compareTableHeader} `}>
                              <span>POS</span>
                              <br />
                              <span className={styles.compareTableSubTitle}>
                                Point of Service
                                <div
                                  className={styles.tableHeaderSpacing}
                                ></div>
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <td className={styles.compareTableDataItem}>
                              Self Referral
                            </td>
                            <td className={styles.compareTableDataRow}>-</td>
                            <td className={styles.compareTableDataRow}>
                              <span className="dot"></span>
                            </td>
                            <td className={styles.compareTableDataRow}>
                              <span className="dot"></span>
                            </td>

                            <td>-</td>
                          </tr>
                          <tr>
                            <td className={styles.compareTableDataItem}>
                              Primary Doc Referral
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              <span className="dot"></span>
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              -
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              -
                            </td>

                            <td className={` ${styles.filled}`}>
                              <span className="dot"></span>
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.compareTableDataItem}>
                              No Coinsurance
                            </td>
                            <td className={styles.compareTableDataRow}>
                              <span className="dot"></span>
                            </td>
                            <td className={styles.compareTableDataRow}>-</td>
                            <td className={styles.compareTableDataRow}>
                              <span className="dot"></span>
                            </td>

                            <td>-</td>
                          </tr>
                          <tr>
                            <td className={styles.compareTableDataItem}>
                              No Deductible
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              <span className="dot"></span>
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              -
                            </td>
                            <td
                              className={`${styles.compareTableDataRow} ${styles.filled}`}
                            >
                              <span className="dot"></span>
                            </td>

                            <td className={` ${styles.filled}`}>
                              <span className="dot"></span>
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.compareTableDataItem}>
                              Out-of-Network Coverage
                            </td>
                            <td className={styles.compareTableDataRow}>-</td>
                            <td className={styles.compareTableDataRow}>
                              <span className="dot"></span>
                            </td>
                            <td className={styles.compareTableDataRow}>-</td>

                            <td>
                              <span className="dot"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={styles.tableFooterOap}>
                  * OAP is similar to an EPO, with one major difference:
                  out-of-network coverage is included at a higher out-of-pocket
                  cost.
                </div>
                <div className={styles.footer}>
                  <div className={styles.footerInner}>
                    <div>
                      <button className={styles.resultsButton} hidden>
                        Email Results
                      </button>
                    </div>
                    <div>
                      <button
                        className={styles.resultsButton}
                        onClick={downloadResultPdf}
                      >
                        Download Results
                      </button>
                    </div>
                    <div className={styles.footerClose} onClick={close}>
                      <div className={styles.footerCloseCenter}>
                        <u>Close</u>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherPlansSummary;

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Col, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { convertEnumToOptions } from 'utils/commonUtil';
import MDVPlan from 'model/MDVPlan';
import LifePlan from 'model/LifePlan';
import FileType from 'model/FileType';
import FourOOneKPlan from 'model/FourOOneKPlan';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import useBenguide from 'hooks/benguide';
import {
  BASIC_LIFE_TYPE,
  MEDICAL_PLAN_DOCUMENT_TYPE,
  PLAN_ADDITIONAL_DOCUMENT,
  TAX_ADVANTAGED_TYPE,
  VOLUNTARY_LIFE_PLAN_TYPE,
} from 'modules/resources/constants';
import { openDocument } from 'utils/fileUtil';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import {
  allPlanDocsUpdated,
  getLatestBenguideByHash,
  removePlanDocuments,
  uploadAndRemovePlanDocuments,
  uploadPlanDocuments,
  uploadWeblinks,
} from 'modules/resources/slices/resourcesSlice';
import PlanDocumentUploader from 'modules/resources/components/PlanDocuments/PlanDocumentUploader/PlanDocumentUploader';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import {
  BENEFIT_CATEGORY,
  BenefitKind,
  ERROR_MESSAGE_PLAN_EDITING,
  FILE_TYPE_ERROR_PDF,
} from 'constants/commonConstants';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import { reloadMedicalSection } from 'modules/benefitsWellness/slices/medicalSlice';
import { reloadDentalSection } from 'modules/benefitsWellness/slices/dentalSlice';
import { reloadVisionSection } from 'modules/benefitsWellness/slices/visionSlice';
import { reloadFourOOneKSection } from 'modules/fourOOneK/slices/fourOOneKSlice';
import {
  TaxAdvantagedSection,
  TAX_ADVANTAGED_BENEFIT_TYPE_INFO,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';

import AdditionalPlanResources from 'modules/resources/components/AdditionalPlanResources/AdditionalPlanResources';
import WebLinkType from 'model/WebLinkType';
import styles from './planDocuments.module.less';

type PlanDocumentsProps = {
  plan: MDVPlan | LifePlan | FourOOneKPlan | TaxAdvantageAccountsPlan | any;
  planDocumentTypes: any;
  benefitKind: string;
  isDisabled?: boolean;
  visible: boolean;
  onClose: Function;
};

const PlanDocuments = forwardRef((props: PlanDocumentsProps, ref) => {
  const {
    plan,
    planDocumentTypes,
    benefitKind,
    isDisabled = false,
    visible,
    onClose,
  } = props;
  const { documentReferences, documents } = plan;

  const dispatch = useAppDispatch();
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.resources
  );
  const benguide = useBenguide();
  const [resetInput, setResetInput] = useState<{ [key: string]: boolean }>({});
  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);
  const [isRemoved, setIsRemoved] = useState<{ [key: string]: boolean }>({});
  const [docs, setDocs] = useState<{ [key: string]: string }>(documents);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isCloseConfirmed, setClosedConfirmed] = useState<boolean>(false);

  const documentRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    resetAll() {
      resetAllData();
    },
  }));

  const resetAllData = () => {
    setIsRemoved({});
    setSelectedFileList([]);
    setAlertMessage('');
    setIsAlertVisible(false);
    setDocs(documents);
  };

  const onRemove = (docType: string) => {
    setIsRemoved((prevState) => ({
      ...prevState,
      [docType]: true,
    }));
    const copy = cloneDeep(docs);
    delete copy[docType];
    setDocs(copy);
  };

  useEffect(() => {
    if (
      plan?.documentReferences &&
      !plan.documentReferences[MEDICAL_PLAN_DOCUMENT_TYPE.SBC.value]
    ) {
      setResetInput((prevState) => ({
        ...prevState,
        [MEDICAL_PLAN_DOCUMENT_TYPE.SBC.value]: true,
      }));
    }
  }, [setResetInput, plan.documentReferences]);

  useEffect(() => {
    if (documents) {
      setDocs(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (isEmpty(resetInput)) {
      return;
    }
    const keys = Object.keys(resetInput);
    keys.forEach((key) => {
      if (resetInput[key]) {
        resetInput[key] = false;
        setResetInput((prevState) => ({ ...prevState, [key]: false }));
      }
    });
  }, [resetInput]);

  const documentTypes = convertEnumToOptions(planDocumentTypes);

  const downloadFileObj = (data: File, docType: string) => {
    const lastFile: FileType | undefined = selectedFileList
      .filter(
        (item) => item.docType === docType && item.file.name === data.name
      )
      .pop();
    if (lastFile !== undefined) {
      const url = window.URL.createObjectURL(lastFile.file);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
      return;
    }

    if (documents && documents[docType] && plan.id && benefitKind) {
      openDocument(plan.id, docType, benefitKind);
      return;
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = data.name;
    a.click();
    window.URL.revokeObjectURL(url);
    return;
  };

  const handleClose = (onClose: Function) => {
    setClosedConfirmed(true);
    dispatch(allPlanDocsUpdated());
    resetAllData();
    onClose();
  };

  const getUpdatedPlanSection = async (benefitKind: string) => {
    const { masterId, urlHash } = benguide;
    if (BENEFIT_CATEGORY.MEDICAL.value === benefitKind)
      return dispatch(reloadMedicalSection(masterId));
    else if (BENEFIT_CATEGORY.DENTAL.value === benefitKind)
      return dispatch(reloadDentalSection(masterId));
    else if (BENEFIT_CATEGORY.VISION.value === benefitKind)
      return dispatch(reloadVisionSection(masterId));
    else if (
      BASIC_LIFE_TYPE.includes(benefitKind) ||
      VOLUNTARY_LIFE_PLAN_TYPE.includes(benefitKind) ||
      TaxAdvantagedSection.HSA === benefitKind ||
      TaxAdvantagedSection.FSA === benefitKind ||
      TAX_ADVANTAGED_TYPE.LIMITED_FSA === benefitKind ||
      TAX_ADVANTAGED_TYPE.DCAP_FSA === benefitKind ||
      TaxAdvantagedSection.HRA === benefitKind ||
      TAX_ADVANTAGED_BENEFIT_TYPE_INFO.COMMUTER.value === benefitKind
    ) {
      dispatch(getLatestBenguideByHash(urlHash));
    } else if (BENEFIT_CATEGORY.RETIREMENT.value === benefitKind)
      return dispatch(reloadFourOOneKSection(masterId));
  };

  const uploadDocument = async () => {
    const onSaveSuccess = () => {
      onClose();
      getUpdatedPlanSection(benefitKind);
      dispatch(allPlanDocsUpdated());
    };

    const removeDocs = Object.keys(isRemoved)
      .filter((documentType) => isRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocuments(selectedFileList, plan.id as string, benefitKind)
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(
        removePlanDocuments(plan.id as string, benefitKind, removeDocs)
      );
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadAndRemovePlanDocuments(
          selectedFileList,
          plan.id as string,
          benefitKind,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(
        uploadWeblinks(selectedWeblink, plan.id as string, benefitKind)
      );
    }
    onSaveSuccess();
  };

  function getBenefitKindLabel(value: string): string {
    const label = BenefitKind[value as keyof typeof BenefitKind]?.label;
    return label ? label : value;
  }

  return (
    <HalfScreenModal
      visible={visible}
      onCancel={() => handleClose(onClose)}
      closeIcon={false}
      title={`Edit ${getBenefitKindLabel(benefitKind)} Plan Resources`}
      width={555}
      onSubmit={() => {
        uploadDocument();
      }}
      footerStyles={styles.resCardFooter}
      isLoading={documentUploadInProgress}
    >
      {isDisabled && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <div className={styles.alertHolder}>
        {isAlertVisible && (
          <AlertMessage
            type="error"
            message={alertMessage}
            closeAlert={() => setIsAlertVisible(false)}
            wrapperClassName={styles.alertWrapper}
          />
        )}
      </div>
      <div className={styles.planDocumentsWrapper}>
        {documentTypes.map((docType, index) => {
          const blobUrl = get(docs, [docType.value, 'blobUrl'], undefined);
          const fileName = get(docs, [docType.value, 'fileName'], undefined);
          let file;
          const savedFileName = get(docs, docType.value);
          if (savedFileName) {
            file = new File([''], savedFileName);
          }

          if (blobUrl) {
            file = new File([blobUrl], fileName);
          }
          return (
            <Row key={index}>
              <Col span={6}>
                <div className={styles.docType}> {docType.label}</div>
              </Col>
              <Col span={18}>
                <PlanDocumentUploader
                  onChange={(e: File) => {
                    setIsRemoved((prevState) => ({
                      ...prevState,
                      [docType.value]: false,
                    }));
                    setSelectedFileList([
                      ...selectedFileList,
                      { file: e, docType: docType.value, planDocumentName: '' },
                    ]);
                    setDocs({ ...docs, [docType.value]: e.name });
                  }}
                  onRemove={() => {
                    onRemove(docType.value);
                  }}
                  onFileNameClick={(file: File) =>
                    downloadFileObj(file, docType.value)
                  }
                  isLoading={get(
                    documentReferences,
                    [docType.value, 'uploading'],
                    false
                  )}
                  allowedFileTypes="application/pdf"
                  maxFileSizeMB={100}
                  isRemoved={isRemoved[docType.value]}
                  file={file}
                  docType={docType.value}
                  onValidateFails={() => {
                    setIsAlertVisible(true);
                    setAlertMessage(FILE_TYPE_ERROR_PDF);
                  }}
                  isDisabled={isDisabled}
                  ref={documentRef}
                />
              </Col>
            </Row>
          );
        })}
      </div>
      <AdditionalPlanResources
        ref={ref}
        plan={plan}
        benefitKind={benefitKind}
        isCloseConfirmed={isCloseConfirmed}
        selectedFileList={selectedFileList}
        setSelectedFileList={setSelectedFileList}
        selectedWebLinkList={selectedWeblink}
        setSelectedWebLinkList={setSelectedWeblink}
        setIsDocRemoved={setIsRemoved}
      />
    </HalfScreenModal>
  );
});

PlanDocuments.displayName = 'PlanDocuments';

export default PlanDocuments;

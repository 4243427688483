import React, { forwardRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import Option from 'model/Option';
import Plan from 'model/Plan';
import FileType from 'model/FileType';
import WebLinkType from 'model/WebLinkType';
import AlertMessage, { AlertInfo } from 'components/AlertMessage/AlertMessage';

import {
  convertToOptions,
  convertToWebLinkType,
  getValidationMessage,
  removeFileExtension,
} from 'utils/commonUtil';
import { ReactComponent as IconAddDocument } from 'images/icons/icon-add.svg';
import { ReactComponent as IconRemoveDocument } from 'images/icons/icon-remove-red.svg';
import {
  getDocumentNameWithHighestNumber,
  incrementNumberInFileName,
  isValidWeblink,
} from 'utils/fileUtil';
import {
  FILE,
  INVALID_WEBLINK,
  PLAN_ADDITIONAL_DOCUMENT,
  WEBLINK,
} from 'modules/resources/constants';
import PlanResources from 'modules/benefitsWellness/components/PlanResources/PlanResources';
import { NO_NAME } from 'constants/commonConstants';
import styles from './AdditionalPlanResource.module.less';

type AdditionalPlanResourcesProps = {
  plan?: Plan;
  benefitKind?: string;
  isCloseConfirmed: boolean;
  selectedFileList: FileType[];
  setSelectedFileList: Function;
  selectedWebLinkList: WebLinkType[];
  setSelectedWebLinkList: Function;
  setIsDocRemoved: Function;
};

const formInitialValues = {
  planDocumentName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  weblink: '',
  fileName: '',
  groupId: '',
};

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';

const AdditionalPlanResources = forwardRef(
  (props: AdditionalPlanResourcesProps, ref) => {
    const {
      plan,
      benefitKind,
      isCloseConfirmed,
      selectedFileList,
      setSelectedFileList,
      selectedWebLinkList,
      setSelectedWebLinkList,
      setIsDocRemoved,
    } = props;

    const [isAddNewDocumentFormOpened, setIsAddNewDocumentOpened] =
      useState<boolean>(false);
    const [
      temporaryAdditionalPlanDocuments,
      setTemporaryAdditionalPlanDocuments,
    ] = useState<Option[]>([]);
    const [additionalPlanDocumentTypes, setAdditionalPlanDocumentTypes] =
      useState<Option[]>([]);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [file, setFile] = useState<any | null>(null);
    const [docs, setDocs] = useState<{
      [key: string]: string;
    }>({});
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(formInitialValues);
    const [additionalResourceType, setAdditionalResourceType] =
      useState<string>('');
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [invalidField, setInvalidField] = useState<InvalidField>('');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [onEdit, setOnEdit] = useState<string>();
    const [editType, setEditType] = useState<string>();
    const [planDocumentNameList, setPlanDocumentNameList] = useState<string[]>(
      []
    );
    const [weblinkNameList, setWeblinkNameList] = useState<string[]>([]);

    useEffect(() => {
      if (plan) {
        setTemporaryAdditionalPlanDocuments([]);
        setDocs({
          ...plan.documents,
          ...plan.additionalDocuments,
        });
        if (!isEmpty(plan.additionalDocuments)) {
          const convertedList: Option[] = convertToOptions(
            plan.additionalDocuments
          );
          setAdditionalPlanDocumentTypes(convertedList);
          setPlanDocumentNameList(
            convertedList.map((document) => document.value)
          );
        }
        if (!isEmpty(plan.additionalWeblinks)) {
          const convertedList = convertToWebLinkType(plan.additionalWeblinks);
          setSelectedWebLinkList(convertedList);
          setWeblinkNameList(
            convertedList.map((weblink) => weblink.planDocumentName)
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan]);

    const onFileRemove = () => {
      setFile(null);
      setIsRemoved(true);
      setFormData({ ...formData, fileName: '' });
    };

    const onFileChange = (value: File) => {
      setIsRemoved(false);
      if (value) {
        const planDocumentName = form.getFieldValue('planDocumentName');
        setFile(value);
        setDocs({ ...docs, [planDocumentName]: value.name });
        setSelectedFileList([
          ...selectedFileList,
          {
            file: value,
            docType: PLAN_ADDITIONAL_DOCUMENT,
            planDocumentName: planDocumentName
              ? planDocumentName
              : removeFileExtension(value.name),
          },
        ]);
      }
    };

    const onValidateFails = (validateSetting: string) => {
      setAlertMessage({
        type: 'error',
        message: getValidationMessage(validateSetting),
      });
      setShowAlert(true);
    };

    const onCancel = () => {
      setIsAddNewDocumentOpened(false);
      form.resetFields();
      onFileRemove();
      setIsEdit(false);
      setOnEdit(undefined);
    };

    const updateResourceName = (
      nameList: string[],
      planDocumentName: string
    ): string => {
      const selectedListExist = nameList.filter((file) =>
        file.startsWith(planDocumentName)
      );
      if (!selectedListExist.includes(planDocumentName)) {
        return planDocumentName;
      } else if (selectedListExist.length > 0) {
        const highest = getDocumentNameWithHighestNumber(
          selectedListExist,
          planDocumentName
        );
        return incrementNumberInFileName(highest);
      }
      return planDocumentName;
    };

    const removeDocFromList = (editedResource: string) => {
      const existingFile = selectedFileList.find(
        (file) => file.planDocumentName === editedResource
      );
      if (existingFile) {
        setSelectedFileList((prevSelectedFileList: any) => {
          const updatedFileList = prevSelectedFileList.filter(
            (file: any) => file.planDocumentName !== editedResource
          );

          return updatedFileList;
        });

        setTemporaryAdditionalPlanDocuments((prevTempDocs) => {
          return prevTempDocs.filter(
            (additionalDoc) => additionalDoc.value !== editedResource
          );
        });
      } else {
        setAdditionalPlanDocumentTypes(
          additionalPlanDocumentTypes.filter(
            (additionalDoc) => additionalDoc.value !== editedResource
          )
        );
        onRemove(editedResource);
      }
    };
    const onSave = () => {
      let planDocumentName = form.getFieldValue('planDocumentName')
        ? form.getFieldValue('planDocumentName')
        : NO_NAME;
      const weblink = form.getFieldValue('weblink');
      let weblinkList = cloneDeep(selectedWebLinkList);
      let updatedFileList: any[] = selectedFileList;
      if (isEdit && onEdit) {
        if (editType === FILE && weblink) {
          removeDocFromList(onEdit);
        } else if (editType === WEBLINK && file) {
          setSelectedWebLinkList(
            selectedWebLinkList.filter(
              (weblink) => weblink.planDocumentName !== onEdit
            )
          );
        }

        if (file) {
          const existingFile = selectedFileList.find(
            (file) => file.planDocumentName === onEdit
          );
          if (existingFile) {
            updatedFileList = selectedFileList.filter(
              (file: any) => file.planDocumentName !== onEdit
            );

            setTemporaryAdditionalPlanDocuments((prevTempDocs) => {
              return prevTempDocs.filter(
                (additionalDoc) => additionalDoc.value !== onEdit
              );
            });
          } else {
            const filteredList = additionalPlanDocumentTypes.filter(
              (additionalDoc) => additionalDoc.value !== onEdit
            );
            setAdditionalPlanDocumentTypes(filteredList);
            onRemove(onEdit);
          }
        } else if (weblink) {
          setSelectedWebLinkList(
            selectedWebLinkList.filter(
              (weblink) => weblink.planDocumentName !== onEdit
            )
          );
          weblinkList = selectedWebLinkList.filter(
            (weblink) => weblink.planDocumentName !== onEdit
          );
        }
        const filteredList = planDocumentNameList.filter(
          (name) => name !== onEdit
        );
        setPlanDocumentNameList(filteredList);
        planDocumentName = updateResourceName(filteredList, planDocumentName);
        setIsEdit(false);
        setOnEdit(undefined);
        setEditType(undefined);
      } else {
        planDocumentName = updateResourceName(
          planDocumentNameList,
          planDocumentName
        );
      }

      if (file) {
        updatedFileList.push({
          file: file,
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: planDocumentName,
        });
        setSelectedFileList(updatedFileList);

        setPlanDocumentNameList([...planDocumentNameList, planDocumentName]);
        setDocs({ ...docs, [planDocumentName]: file.name });

        const tempDocListIndex = temporaryAdditionalPlanDocuments.findIndex(
          (item) => item.value?.toString() === planDocumentName?.toString()
        );
        const planDocListIndex = additionalPlanDocumentTypes.findIndex(
          (item) => item.value?.toString() === planDocumentName?.toString()
        );
        if (tempDocListIndex !== -1) {
          const docList = cloneDeep(temporaryAdditionalPlanDocuments);
          docList[tempDocListIndex].value = planDocumentName;
          setTemporaryAdditionalPlanDocuments(docList);
        } else if (planDocListIndex !== -1) {
          const docList = cloneDeep(additionalPlanDocumentTypes);
          docList[planDocListIndex].value = planDocumentName;
          setAdditionalPlanDocumentTypes(docList);
        } else {
          setTemporaryAdditionalPlanDocuments((additionalTypes: any) => [
            ...additionalTypes,
            {
              value: !planDocumentName.startsWith(NO_NAME)
                ? planDocumentName
                : file.name,
              label: file.name,
            },
          ]);
        }
        setIsAddNewDocumentOpened(false);
      } else if (weblink) {
        if (isValidWeblink(weblink)) {
          const webLinks = cloneDeep(weblinkList);
          const existingWebLinkIndex = webLinks.findIndex(
            (link) => link.planDocumentName === planDocumentName
          );
          if (existingWebLinkIndex !== -1) {
            webLinks[existingWebLinkIndex].weblink = weblink;
            setSelectedWebLinkList(webLinks);
          } else {
            weblinkList.push({
              weblink: weblink,
              planDocumentName: planDocumentName,
            });

            setSelectedWebLinkList(weblinkList);
          }
          setWeblinkNameList([...weblinkNameList, planDocumentName]);
          setIsAddNewDocumentOpened(false);
        } else
          setAlertMessage({
            type: 'error',
            message: getValidationMessage(INVALID_WEBLINK),
          });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Please select a file to upload',
        });
        setShowAlert(true);
      }
      form.resetFields();
      onFileRemove();
      setAdditionalResourceType('');
    };

    const onRemove = (docType: string) => {
      setIsDocRemoved((prevState: any) => ({
        ...prevState,
        [docType]: true,
      }));

      if (!isEmpty(docs) && !isEmpty(docs[docType])) {
        const copy = cloneDeep(docs);
        delete copy[docType];
        setDocs(copy);
      }
    };
    const onRemoveWebLinkFromList = (webLink: WebLinkType) => {
      const webLinkIndex = selectedWebLinkList.indexOf(webLink);
      setSelectedWebLinkList((prevItems: any) =>
        prevItems.filter((_: any, i: any) => i !== webLinkIndex)
      );
    };

    const renderList = (docType: Option, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => {
                  const docIndex = additionalPlanDocumentTypes.indexOf(docType);
                  setAdditionalPlanDocumentTypes((prevItems: Option[]) =>
                    prevItems.filter((_: Option, i: number) => i !== docIndex)
                  );
                  const tempDocIndex =
                    temporaryAdditionalPlanDocuments.indexOf(docType);
                  setTemporaryAdditionalPlanDocuments((prevItems: Option[]) =>
                    prevItems.filter(
                      (_: Option, i: number) => i !== tempDocIndex
                    )
                  );
                  onRemove(docType.value);
                }}
                icon={<IconRemoveDocument width={24} />}
              ></LinkButton>
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {docType.value.startsWith(NO_NAME)
                  ? docType.label
                  : docType.value}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  // Set initial form values first
                  form.setFieldsValue({ weblink: undefined });

                  // Use a promise to enforce order for setting form fields and file
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: !docType.value.startsWith(NO_NAME)
                        ? docType.value
                        : undefined,
                    });

                    const blobUrl = get(
                      docs,
                      [docType.value, 'blobUrl'],
                      undefined
                    );
                    const fileName = get(
                      docs,
                      [docType.value, 'fileName'],
                      undefined
                    );
                    let file;
                    const savedFileName = get(docs, docType.value);

                    if (savedFileName) {
                      file = new File([''], savedFileName);
                    }
                    if (blobUrl) {
                      file = new File([blobUrl], fileName);
                    }

                    setFile(file);
                    setOnEdit(docType.value);
                    setEditType(FILE);
                  });

                  // Perform the rest of the state updates
                  setIsEdit(true);
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('file');
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };
    const renderWebList = (webLink: WebLinkType, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => onRemoveWebLinkFromList(webLink)}
                icon={<IconRemoveDocument width={24} />}
              />
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {webLink.planDocumentName == null ||
                webLink.planDocumentName === 'undefined' ||
                webLink.planDocumentName.startsWith(NO_NAME)
                  ? webLink.weblink
                  : webLink.planDocumentName}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  setFile(null);
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: webLink.planDocumentName.startsWith(
                        NO_NAME
                      )
                        ? webLink.weblink
                        : webLink.planDocumentName,
                    });
                  });

                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      weblink: webLink.weblink,
                    });
                  });
                  form.setFieldsValue({ webLink: webLink.weblink });
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('webLink');
                  setIsEdit(true);
                  setEditType(WEBLINK);
                  setOnEdit(webLink.planDocumentName);
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };
    return (
      <>
        {showAlert && (
          <div className={styles.alertWrapper}>
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={() => setShowAlert(false)}
            />
          </div>
        )}
        <LinkButton
          className={styles.addAdditionalResource}
          icon={<IconAddDocument width={24} />}
          disabled={isAddNewDocumentFormOpened}
          onClick={() => {
            setIsAddNewDocumentOpened(true);
          }}
        >
          Add Additional Resources
        </LinkButton>
        {temporaryAdditionalPlanDocuments.map(renderList)}
        {additionalPlanDocumentTypes.map(renderList)}
        {selectedWebLinkList.map(renderWebList)}
        {isAddNewDocumentFormOpened && (
          <PlanResources
            ref={ref}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            setFormData={setFormData}
            formData={formData}
            form={form}
            isRemoved={isRemoved}
            isCloseConfirmed={isCloseConfirmed}
            additionalResourceType={additionalResourceType}
            setAdditionalResourceType={setAdditionalResourceType}
            onValidateFails={onValidateFails}
            invalidField={invalidField}
            setIsAdditionalInfoValid={setInvalidField}
            planId={plan?.id}
            benefitKind={benefitKind}
            onSave={onSave}
            onCancel={onCancel}
            file={file}
          />
        )}
      </>
    );
  }
);
AdditionalPlanResources.displayName = 'AdditionalPlanResources';
export default AdditionalPlanResources;

import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'antd';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import { isUndefined } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  coverageFormatter,
  currencyFormatter,
  findFrequencyByBenefitKind,
  getAdditionalServices,
  getConfiguredBenefitCodeList,
  getEEContributionByFrequency,
  getEEContributionField,
  showRxRow,
} from 'modules/benefitsWellness/util';
import useBenguide from 'hooks/benguide';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import meta from 'modules/benefitsWellness/data/metadata';
import MDVPlan from 'model/MDVPlan';
import CustomField from 'model/CustomField';
import HSAMedicalContribution from 'model/HSAMedicalContribution';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import MDVMetaGroup from 'model/MDVMetaGroup';
import iconEdit from 'images/icon-edit.svg';
import iconPlayPlanInfo from 'images/icon-play-plan-info.svg';
import iconDocSearch from 'images/icon-doc-search-plans.svg';
import iconLeftArrow from 'images/icons/Icon-left-arrow.svg';
import iconRightArrow from 'images/icons/Icon-right-arrow.svg';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  DENTAL_PLAN_CHANNEL,
  MEDICAL_PLAN_CHANNEL,
  VISION_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import {
  AGE_BAND,
  AGE_BAND_LABEL,
  FOUR_TIER,
  IN_NETWORK,
  MAIL_ORDER_RX_TIER_LABELS,
  MOBILE_DEDUCTIBLE_LABELS,
  MOBILE_IND_DEDUCTIBLE_LABELS,
  MOBILE_RX_DEDUCTIBLE_OOP_LABELS,
  NDASH,
  NETWORK_PREFIX,
  RX_TIER_LABELS,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import MDVMetaService from 'model/MDVMetaService';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';
import {
  setSelectedPlanForCompare,
  checkBenefitGuidesRates,
} from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import {
  editCustomFieldValue,
  getCustomFieldsToState,
} from 'modules/benefitsWellness/slices/medicalSlice';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';
import MDVFormattedValue from 'modules/benefitsWellness/components/MDVFormattedValue/MDVFormattedValue';
import ProviderSearchModal from 'modules/benefitsWellness/components/ProviderSearchModal/ProviderSearchModal';
import PlanTypeInfoDrawer from 'modules/benefitsWellness/components/PlanTypeInfoDrawer/PlanTypeInfoDrawer';
import { TaxAdvantageBenefitKind } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { NOT_APPLICABLE_FORMATTED } from 'utils/planRecommenderUtils/planRecommenderUtils';
import {
  BENEFIT_TYPES,
  DENTAL_ADVANCED_PROFILE_PARAMETERS,
  VISION_ADVANCED_PROFILE_PARAMETERS,
} from 'constants/commonConstants';
import { FORMATTED_FREQUENCY } from 'modules/benefitsWellness/constants/TooltipConstants';
import EditCustomRowModal from 'components/EditCustomRowModal/EditCustomRowModal';
import MDVCardCustomValue from 'modules/benefitsWellness/components/MDVCardCustomValue/MDVCardCustomValue';

import styles from './mdvCardValues.module.less';

const IN = 'In';
const OUT_OF = 'OutOf';
const COST = 'Cost';
const APPLICABLE = 'Applicable';
const DEDUCTIBLE = 'deductibles';
const IND_WITHIN_FAMILY_DEDUCTIBLE = 'indWithinFamilyDeductible';
const OUT_OF_POCKET = 'outOfPocket';
const IND_WITHIN_FAMILY_OOP_MAX = 'indWithinFamilyOOPMax';
const MONTHLY_COST = 'monthlyCost';
const CUSTOM_FIELD = 'customFields';
const DEDUCTIBLE_COST = 'deductibleCost';
const OUT_OF_POCKET_MAX = 'outOfPocketMax';
const PLAN_YEAR_MAX = 'calendarYearMax';
const ORTHODONTIA_MAX = 'orthodontiaLifetimeMax';
const RX_DEDUCTIBLES = 'rxDeductibles';
const RX_DEDUCTIBLE_AND_OOP_ATTRIBUTE_NAME = 'rxDeductiblesAndOop';
const RX_OOP_MAX = 'rxOOP';
const defaultValue = '-';

type MDVCardValueProps = {
  key: number;
  plan: any;
  planType: MDVTypes;
  moveLeft: () => void;
  moveRight: () => void;
  hideLeftMoveButton: boolean;
  hideRightMoveButton: boolean;
  summarySection?: ReactNode;
  attachedPlans: MDVPlan[];
  detailsHeight?: number;
  onHigherDetailsHeight?: (height: number) => void;
  isChanged?: Function;
};

const MDVCardValues: FC<MDVCardValueProps> = (props: MDVCardValueProps) => {
  const {
    plan,
    planType,
    hideLeftMoveButton,
    hideRightMoveButton,
    moveLeft,
    moveRight,
    summarySection,
    attachedPlans,
    detailsHeight,
    onHigherDetailsHeight,
    isChanged,
  } = props;
  const currentBenguide = useBenguide();
  const dispatch = useAppDispatch();
  const { rateValidations } = useAppSelector((state) => state.benefitWellness);
  const { isMobile } = useAppSelector((state) => state.layout);
  const { data: hraPlanList } = useAppSelector((state) => state.hra.planList);
  const isEditMode = currentBenguide.isEditMode;

  const [dataGroups, setDataGroups] = useState<MDVMetaGroup[]>([]);
  const [isOpenProviderSearch, setIsOpenProviderSearch] =
    useState<boolean>(false);
  const [additionalServices, setAdditionalServices] = useState<
    MDVMetaService[]
  >([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const detailsSectionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      detailsSectionRef.current?.scrollHeight &&
      detailsHeight !== undefined &&
      onHigherDetailsHeight
    ) {
      if (detailsHeight < detailsSectionRef.current.scrollHeight) {
        onHigherDetailsHeight(detailsSectionRef.current?.scrollHeight);
      }
    }
  }, [onHigherDetailsHeight, detailsHeight]);

  useEffect(() => {
    isChanged && isChanged((i: any) => !i);
    if (planType) {
      const fieldGroups = meta.benguideMetaData.planFieldGroupings.find(
        (planFieldGroup) => planFieldGroup.planType === planType.toLowerCase()
      );
      const definedBenefitCodes = getConfiguredBenefitCodeList(
        fieldGroups?.hotspots || []
      );
      setAdditionalServices(
        getAdditionalServices(attachedPlans, definedBenefitCodes)
      );
      setDataGroups(fieldGroups?.hotspots || []);
      dispatch(
        checkBenefitGuidesRates(
          currentBenguide.masterId,
          planType.toUpperCase()
        )
      );
    }
  }, [planType, attachedPlans, dispatch, currentBenguide.masterId, isChanged]);

  const { selectedNetwork, selectedPlanForCompare, selectedCoverageType } =
    useAppSelector((state) => state.benefitWellness);

  const benguideData = useAppSelector((state) => state.benguide);
  const { urlHash } = benguideData.data;

  const benguide = useBenguide();
  const navigate = useNavigate();

  const {
    name,
    customServices = [],
    rxCosts = {},
    benefitCarrier,
    mailOrderRxCosts = {},
    hsaMedicalContributionVO: _hsaMedicalContributionVO,
  } = plan || {};

  const hsaPlanIds =
    useAppSelector((state) => state.hsa.hsaSection)?.plans?.map(
      (plan) => plan.planId
    ) || [];

  const hraPlanIds =
    useAppSelector((state) => state.hra.hraSection)?.plans?.map(
      (plan) => plan.planId
    ) || [];

  const hsaMedicalContributionVO: HSAMedicalContribution[] =
    _hsaMedicalContributionVO
      ? _hsaMedicalContributionVO?.filter(
          (entry: HSAMedicalContribution) =>
            hsaPlanIds.includes(entry.planId) && plan.hsaCompatible
        )
      : [];

  const hraMedicalContributionVO: HSAMedicalContribution[] =
    _hsaMedicalContributionVO
      ? _hsaMedicalContributionVO?.filter((entry: HSAMedicalContribution) =>
          hraPlanIds.includes(entry.planId)
        )
      : [];

  const networkTextPrefix = selectedNetwork === IN_NETWORK.value ? IN : OUT_OF;
  const networkType =
    selectedNetwork === IN_NETWORK.value
      ? NETWORK_PREFIX.IN
      : NETWORK_PREFIX.OUT_OF;

  const showIfExistServiceList = dataGroups
    .filter((titleGroup) => !!titleGroup.isCustomizedServices)
    .map((titleGroup) =>
      titleGroup.elements.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  const showIfExistRxCostsList = dataGroups
    .filter((titleGroup) => !!titleGroup.isCustomizedRX)
    .map((titleGroup) =>
      titleGroup.elements.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  const showIfExistDeductibleIndividualList = dataGroups
    ?.filter((item) => item?.isCustomizedDeductible)
    ?.map((item) =>
      item?.elements?.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  const nonExistCustomServices = {} as any;
  const nonExistRxCosts = {} as any;
  const nonExistDeductibleIndividual = {} as any;
  attachedPlans.forEach((plan) => {
    showIfExistServiceList &&
      showIfExistServiceList.forEach((service) => {
        if (
          plan.customServices &&
          !plan.customServices.find(
            (customService) =>
              customService.benefitCode &&
              customService.benefitCode.code === service.serviceBenefitCode
          )
        ) {
          nonExistCustomServices[service.serviceBenefitCode] =
            nonExistCustomServices[service.serviceBenefitCode]
              ? nonExistCustomServices[service.serviceBenefitCode] + 1
              : 1;
        }
      });

    showIfExistRxCostsList &&
      showIfExistRxCostsList.forEach((rxCost) => {
        if (
          (rxCost.fieldName === 'rxCost' &&
            rxCost.rxCode &&
            plan.rxCosts &&
            !(
              plan.rxCosts['inNetwork'].hasOwnProperty(rxCost.rxCode) ||
              plan.rxCosts['outOfNetwork'].hasOwnProperty(rxCost.rxCode)
            )) ||
          (rxCost.fieldName === 'mailRxCost' &&
            rxCost.rxCode &&
            plan.mailOrderRxCosts &&
            !(
              plan.mailOrderRxCosts['inNetwork'].hasOwnProperty(
                rxCost.rxCode
              ) ||
              plan.mailOrderRxCosts['outOfNetwork'].hasOwnProperty(
                rxCost.rxCode
              )
            ) &&
            nonExistRxCosts)
        ) {
          nonExistRxCosts[rxCost.fieldName + rxCost.rxCode] = nonExistRxCosts[
            rxCost.fieldName + rxCost.rxCode
          ]
            ? nonExistRxCosts[rxCost.fieldName + rxCost.rxCode] + 1
            : 1;
        }
      });
    showIfExistDeductibleIndividualList?.forEach((deductibleIndividual) => {
      if (
        deductibleIndividual.fieldName === IND_WITHIN_FAMILY_DEDUCTIBLE &&
        (plan?.deductibles?.hasOwnProperty(
          'individualWithinFamilyInNetworkCost'
        ) ||
          plan?.deductibles?.hasOwnProperty(
            'individualWithinFamilyOutOfNetworkCost'
          ))
      ) {
        nonExistDeductibleIndividual[deductibleIndividual.fieldName] =
          nonExistDeductibleIndividual[deductibleIndividual.fieldName]
            ? nonExistDeductibleIndividual[deductibleIndividual.fieldName] + 1
            : 1;
      }
      if (
        deductibleIndividual.fieldName === IND_WITHIN_FAMILY_OOP_MAX &&
        (plan?.outOfPocket?.hasOwnProperty(
          'individualWithinFamilyInNetworkCost'
        ) ||
          plan?.outOfPocket?.hasOwnProperty(
            'individualWithinFamilyOutOfNetworkCost'
          ))
      ) {
        nonExistDeductibleIndividual[deductibleIndividual.fieldName] =
          nonExistDeductibleIndividual[deductibleIndividual?.fieldName]
            ? nonExistDeductibleIndividual[deductibleIndividual?.fieldName] + 1
            : 1;
      }
    });
  });

  const showCoverageDetails = (type: string) => {
    let individualValue = coverageFormatter(
      getCoverageValue(type, true, COST),
      getCoverageValue(type, true, APPLICABLE)
    );
    let familyValue = coverageFormatter(
      getCoverageValue(type, false, COST),
      getCoverageValue(type, false, APPLICABLE)
    );
    if (
      type === DEDUCTIBLE ||
      type === OUT_OF_POCKET ||
      type === PLAN_YEAR_MAX ||
      type === ORTHODONTIA_MAX
    ) {
      if (individualValue === NOT_APPLICABLE_FORMATTED) {
        individualValue = defaultValue;
      }
      if (familyValue === NOT_APPLICABLE_FORMATTED) {
        familyValue = defaultValue;
      }
    }

    const coverageValue = `${
      type === DEDUCTIBLE || type === OUT_OF_POCKET
        ? `${individualValue} / ${familyValue}`
        : individualValue
    }`;
    return (
      <>
        {!isEditMode && isMobile && (
          <div className={styles.serviceHeaderMobile}>
            {
              MOBILE_DEDUCTIBLE_LABELS.find(
                (deductible) => deductible.value === type
              )?.label
            }
          </div>
        )}

        <div className={styles.benefitDetail} title={coverageValue}>
          {!isEditMode
            ? coverageValue
            : getCoverageDetails(type, individualValue, familyValue)}
        </div>
      </>
    );
  };

  const getCoverageDetails = (
    type: string,
    individualValue: string,
    familyValue?: string
  ) => {
    if (type === DEDUCTIBLE || type === OUT_OF_POCKET) {
      if (
        (individualValue === defaultValue && familyValue === defaultValue) ||
        (individualValue === NOT_APPLICABLE_FORMATTED &&
          familyValue === NOT_APPLICABLE_FORMATTED)
      )
        return (
          <div className={styles.commonMissingTag}>
            <MissingInfo missingInfoText="Missing Info" />
          </div>
        );
      else if (
        individualValue === defaultValue ||
        individualValue === NOT_APPLICABLE_FORMATTED
      )
        return (
          <div className={styles.missingContribution}>
            <MissingInfo missingInfoText="Missing Info" />
            <div className={styles.valWrapper}>
              <div className={styles.valueSeparator}>/</div>
              <div className={styles.contributionText}>{familyValue}</div>
            </div>
          </div>
        );
      else if (
        familyValue === defaultValue ||
        familyValue === NOT_APPLICABLE_FORMATTED
      )
        return (
          <div className={styles.missingContribution}>
            <div className={styles.valWrapper}>
              <div className={styles.contributionText}>{individualValue}</div>
              <div className={styles.valueSeparator}>/</div>
            </div>
            <MissingInfo missingInfoText="Missing Info" />
          </div>
        );
      else return `${individualValue} / ${familyValue}`;
    } else {
      if (individualValue === defaultValue)
        return (
          <div className={styles.commonMissingTag}>
            <MissingInfo missingInfoText="Missing Info" />
          </div>
        );
      else return individualValue;
    }
  };

  const getCoverageValue = (
    type: string,
    isIndividual: boolean,
    applicableText: 'Applicable' | 'Cost'
  ) => {
    if (type === DEDUCTIBLE || type === OUT_OF_POCKET) {
      if (isIndividual) {
        return get(
          plan,
          `${type}.individual${networkTextPrefix}Network${applicableText}`
        );
      } else {
        return get(
          plan,
          `${type}.family${networkTextPrefix}Network${applicableText}`
        );
      }
    } else {
      return get(plan, `${type}.${networkType}Network${applicableText}`);
    }
  };

  const getMDVChannel = () => {
    if (planType === MDVTypes.MEDICAL) {
      return MEDICAL_PLAN_CHANNEL;
    } else if (planType === MDVTypes.DENTAL) {
      return DENTAL_PLAN_CHANNEL;
    } else {
      return VISION_PLAN_CHANNEL;
    }
  };

  const excludeIfCustomServicesUnavailable = (title: MDVMetaService) => {
    const { excludeIfUnavailable } = title;
    let isExcluded = false;
    excludeIfUnavailable &&
      excludeIfUnavailable.forEach((serviceCode: number) => {
        if (serviceCode === 0 && isEditMode) {
          return (isExcluded = additionalServices.length > 0);
        }
        if (
          nonExistCustomServices &&
          nonExistCustomServices.hasOwnProperty(
            nonExistCustomServices[serviceCode]
          ) &&
          attachedPlans.length !== nonExistCustomServices[serviceCode]
        ) {
          return (isExcluded = true);
        }
      });
    return isExcluded;
  };

  const { coverageLabel } = selectedCoverageType || {};
  const dbgGroups = currentBenguide?.benefitClasses;
  const dbgRatesGroups = plan?.groups?.filter((plan: any) =>
    dbgGroups.includes(plan)
  );
  const rateGroup = plan.rates && plan.rates[dbgRatesGroups[0]];
  const { type, fourTierContributions, ntierContributions } = rateGroup || {};
  const contribution =
    (type === FOUR_TIER ? fourTierContributions : ntierContributions) || {};
  const { contributions = [] } = contribution;
  const contributionFiled =
    type === FOUR_TIER
      ? selectedCoverageType.fourTierValue
      : selectedCoverageType.nTierValue;
  const value =
    contributions.find((tier: any) => tier.tierName === contributionFiled) ||
    {};
  const { totalCost } = value;

  const getHSAContribution = (
    hsaMedicalContribution: HSAMedicalContribution,
    index: number
  ) => {
    const otherContribution =
      hsaMedicalContribution && hsaMedicalContribution.otherContribution;
    const employerEEContribution =
      hsaMedicalContribution && hsaMedicalContribution.employerEEContribution;
    const employerESContribution =
      hsaMedicalContribution && hsaMedicalContribution.employerESContribution;
    const employerECContribution =
      hsaMedicalContribution && hsaMedicalContribution.employerECContribution;
    const employerEFContribution =
      hsaMedicalContribution && hsaMedicalContribution.employerEFContribution;
    const frequency =
      hsaMedicalContribution && hsaMedicalContribution.frequency;
    const noPlansTagged =
      hsaMedicalContribution && hsaMedicalContribution.noPlansTagged;
    const multiplePlansTagged =
      hsaMedicalContribution && hsaMedicalContribution.multiplePlansTagged;
    const benefitKind =
      hsaMedicalContribution && hsaMedicalContribution.benefitKind;

    const noValuesPresent =
      !noPlansTagged &&
      !multiplePlansTagged &&
      isEmpty(otherContribution) &&
      !employerEEContribution &&
      !employerECContribution &&
      !employerESContribution &&
      !employerEFContribution;

    const getFrequencyValue = () => {
      if (frequency === 'ANNUALLY') {
        return 'YR';
      } else if (frequency === 'MONTHLY') {
        return 'MTH';
      } else if (frequency === 'SEMI_MONTHLY') {
        return 'SM';
      } else if (frequency === 'BI_WEEKLY') {
        return 'BW';
      } else if (frequency === 'QUARTERLY') {
        return 'QTR';
      }
    };

    const tootTipText = `Your employer will contribute this amount on a ${
      FORMATTED_FREQUENCY[frequency] || ''
    } basis`;

    const contributionValue = () => {
      if (selectedCoverageType.fourTierValue === 'EE') {
        return employerEEContribution;
      } else if (selectedCoverageType.fourTierValue === 'ES') {
        return employerESContribution;
      } else if (selectedCoverageType.fourTierValue === 'EC') {
        return employerECContribution;
      } else if (selectedCoverageType.fourTierValue === 'EF') {
        return employerEFContribution;
      }
    };

    const navigateToHSA = () => {
      if (benefitKind === 'HSA') {
        navigate(
          `/${urlHash}${
            currentBenguide.employerName
              ? '/' + currentBenguide.employerName
              : ''
          }/tax-advantaged/hsa`
        );
      }
    };

    const getContributionValue = () => {
      if (benefitKind === 'HSA') {
        if (contributionValue()) {
          return `$${contributionValue()} HSA CONTRIBUTION/ ${getFrequencyValue()}`;
        } else {
          return 'HSA CONTRIBUTION';
        }
      }
    };

    const planCompatible = benefitKind === 'HSA' ? plan.hsaCompatible : true;

    const isSelectedCoveredValueEmpty =
      (selectedCoverageType.fourTierValue === 'EE' &&
        employerEEContribution === undefined) ||
      (selectedCoverageType.fourTierValue === 'ES' &&
        employerESContribution === undefined) ||
      (selectedCoverageType.fourTierValue === 'EC' &&
        employerECContribution === undefined) ||
      (selectedCoverageType.fourTierValue === 'EF' &&
        employerEFContribution === undefined);

    return noValuesPresent || !planCompatible || isSelectedCoveredValueEmpty ? (
      <></>
    ) : (
      <>
        {!noPlansTagged &&
          benefitKind === 'HSA' &&
          !multiplePlansTagged &&
          planType === MDVTypes.MEDICAL && (
            <div
              className={`${styles.hsaContribution} ${
                index === 1 ? styles.hra : styles.hsa
              }`}
              onClick={() => {
                navigateToHSA();
              }}
            >
              {!isEditMode && contributionValue() !== undefined ? (
                <Popover
                  content={<div>{tootTipText}</div>}
                  placement="left"
                  overlayClassName={`popoverInner ${styles.planWrapper}`}
                >
                  <div className={styles.hsaValue}>
                    {getContributionValue()}
                  </div>
                </Popover>
              ) : (
                <div className={styles.hsaValue}>{getContributionValue()}</div>
              )}
            </div>
          )}
      </>
    );
  };

  const displayTaxAdvantageTag = (
    taxAdvantageContribution: HSAMedicalContribution[],
    taxAdvantageType: TaxAdvantageBenefitKind
  ) => {
    if (
      !isEmpty(taxAdvantageContribution) &&
      taxAdvantageContribution.length <= 1
    ) {
      return taxAdvantageContribution.map(
        (hsaMedicalContribution: HSAMedicalContribution, index: number) => {
          return (
            <div key={index}>
              {getHSAContribution(hsaMedicalContribution, index)}
            </div>
          );
        }
      );
    } else if (isEditMode && !isEmpty(taxAdvantageContribution)) {
      return (
        <div className={styles.hraConflict}>
          <MissingInfo
            missingInfoText={`${taxAdvantageType} Contribution Conflict`}
          />
        </div>
      );
    }
  };

  const getRXCoverageValue = (
    type: string,
    isIndividual: boolean,
    isOop: boolean,
    applicableText: 'Applicable' | 'Cost'
  ) => {
    if (!isOop) {
      if (isIndividual) {
        return get(
          plan,
          `${type}.individualDeductible${networkTextPrefix}Network${applicableText}`
        );
      } else {
        return get(
          plan,
          `${type}.familyDeductible${networkTextPrefix}Network${applicableText}`
        );
      }
    } else {
      if (isIndividual) {
        return get(
          plan,
          `${type}.individualOopMax${networkTextPrefix}Network${applicableText}`
        );
      } else {
        return get(
          plan,
          `${type}.familyOopMax${networkTextPrefix}Network${applicableText}`
        );
      }
    }
  };

  const showRxOopDeductiblesValue = (
    type: string,
    isOop: boolean,
    fieldName: string
  ) => {
    let individualValue = coverageFormatter(
      getRXCoverageValue(type, true, isOop, COST),
      getRXCoverageValue(type, true, isOop, APPLICABLE)
    );
    let familyValue = coverageFormatter(
      getRXCoverageValue(type, false, isOop, COST),
      getRXCoverageValue(type, false, isOop, APPLICABLE)
    );
    if (individualValue === NOT_APPLICABLE_FORMATTED) {
      individualValue = defaultValue;
    }
    if (familyValue === NOT_APPLICABLE_FORMATTED) {
      familyValue = defaultValue;
    }

    const coverageValue = `${individualValue} / ${familyValue}`;

    return (
      <>
        {!isEditMode && isMobile && (
          <div className={styles.serviceHeaderMobile}>
            {
              MOBILE_RX_DEDUCTIBLE_OOP_LABELS.find(
                (deductible) => deductible.value === fieldName
              )?.label
            }
          </div>
        )}

        <div className={styles.benefitDetail} title={coverageValue}>
          {!isEditMode
            ? coverageValue
            : getRxCoverageDetails(type, coverageValue)}
        </div>
      </>
    );
  };

  const getRxCoverageDetails = (type: string, individualValue: string) => {
    if (
      individualValue === defaultValue ||
      individualValue === NOT_APPLICABLE_FORMATTED
    ) {
      return (
        <div className={styles.commonMissingTag}>
          <MissingInfo missingInfoText="Missing Info" />
        </div>
      );
    } else {
      return `${individualValue}`;
    }
  };

  const getIndividualWithinCoverageDetails = (
    type: string,
    individualValue: string
  ) => {
    if (type === DEDUCTIBLE || type === OUT_OF_POCKET) {
      if (
        individualValue === defaultValue ||
        individualValue === NOT_APPLICABLE_FORMATTED
      )
        return (
          <div className={styles.commonMissingTag}>
            <MissingInfo missingInfoText="Missing Info" />
          </div>
        );
      else return `${individualValue}`;
    }
  };

  const getIndividualWithinCoverageValue = (
    type: string,
    applicableText: 'Applicable' | 'Cost'
  ) => {
    return (
      get(
        plan,
        `${type}.individualWithinFamily${networkTextPrefix}Network${applicableText}`
      ) || defaultValue
    );
  };

  const showIndividualWithinCoverageValue = (
    type: string,
    fieldName: string
  ) => {
    const individualValue = coverageFormatter(
      getIndividualWithinCoverageValue(type, COST),
      getIndividualWithinCoverageValue(type, APPLICABLE)
    );
    const coverageValue =
      individualValue === NOT_APPLICABLE_FORMATTED
        ? defaultValue
        : individualValue;
    return (
      <>
        {!isEditMode && isMobile && (
          <div className={styles.serviceHeaderMobile}>
            {
              MOBILE_IND_DEDUCTIBLE_LABELS.find(
                (deductible) => deductible.value === fieldName
              )?.label
            }
          </div>
        )}

        <div className={styles.benefitDetail} title={coverageValue}>
          {!isEditMode
            ? coverageValue
            : getIndividualWithinCoverageDetails(type, coverageValue)}
        </div>
      </>
    );
  };

  const getPlanValues = (title: any) => {
    const {
      fieldName,
      fieldValue,
      serviceBenefitCode,
      rxCode,
      fieldFormatter,
      additionalServices: isAdditionalServicesSupport,
      includeIfServiceAvailable,
      isRx,
    } = title;
    const contribFreq = findFrequencyByBenefitKind(
      currentBenguide.contributionFrequencies,
      planType.toUpperCase()
    );
    const { enabled } = contribFreq;
    const attachedServices = attachedPlans
      .reduce((previousValue: any, customService: any): any => {
        return [...previousValue, ...customService.customServices];
      }, [])
      .map((service: any) => service?.benefitCode?.code);

    if (fieldName === 'spaceField' && includeIfServiceAvailable) {
      if (
        attachedServices.some((r: any) =>
          includeIfServiceAvailable?.includes(r)
        ) &&
        !isMobile
      ) {
        return <div className={styles.titleSeperator}></div>;
      } else {
        return null;
      }
    }
    if (fieldName === 'title') {
      return getMDVBasicInfo(enabled);
    } else if (serviceBenefitCode) {
      return getServiceText(serviceBenefitCode, customServices, fieldFormatter);
    } else if (isEditMode && isRx) {
      return <></>;
    } else if (rxCode) {
      return getRxText(rxCode, rxCosts, fieldFormatter, fieldName);
    } else if (fieldName === DEDUCTIBLE_COST) {
      return showCoverageDetails(DEDUCTIBLE);
    } else if (fieldName === IND_WITHIN_FAMILY_DEDUCTIBLE) {
      return showIndividualWithinCoverageValue(DEDUCTIBLE, fieldName);
    } else if (fieldName === RX_OOP_MAX) {
      return showRxOopDeductiblesValue(
        RX_DEDUCTIBLE_AND_OOP_ATTRIBUTE_NAME,
        true,
        fieldName
      );
    } else if (fieldName === RX_DEDUCTIBLES) {
      return showRxOopDeductiblesValue(
        RX_DEDUCTIBLE_AND_OOP_ATTRIBUTE_NAME,
        false,
        fieldName
      );
    } else if (fieldName === OUT_OF_POCKET_MAX) {
      return showCoverageDetails(OUT_OF_POCKET);
    } else if (fieldName === IND_WITHIN_FAMILY_OOP_MAX) {
      return showIndividualWithinCoverageValue(OUT_OF_POCKET, fieldName);
    } else if (
      !fieldValue &&
      !isAdditionalServicesSupport &&
      (!title.excludeIfUnavailable ||
        (title.excludeIfUnavailable &&
          excludeIfCustomServicesUnavailable(title))) &&
      !isMobile
    ) {
      return <div className={styles.titleSeperator}></div>;
    } else if (fieldName === MONTHLY_COST) {
      if (
        !isEditMode &&
        (!enabled || rateValidations.hasMismatchContributions)
      ) {
        return <></>;
      }
      if (!enabled || rateValidations.hasMismatchContributions) {
        return (
          <div
            className={`${styles.monthlyCost} ${styles.monthlyCostDisabled}`}
          >
            <div className={styles.monthlyCostValue}>
              {NDASH} {NDASH}
            </div>

            <div className={styles.monthlyCoversYou}>{coverageLabel}</div>
          </div>
        );
      }
      if (type === AGE_BAND) {
        return (
          <div className={styles.monthlyCost} title={AGE_BAND_LABEL}>
            <div className={styles.monthlyCostValue}>{AGE_BAND_LABEL}</div>

            <div className={styles.monthlyCoversYou}>{coverageLabel}</div>
          </div>
        );
      }
      const isContributionsExist = contributions.some(
        (item: { totalCost: number }) => item.totalCost > 0
      );

      if (!totalCost && isEditMode && !isContributionsExist) {
        return (
          <div
            className={`${styles.monthlyCostMissingInfoWrapper} ${styles.monthlyCost}`}
          >
            <MissingInfo missingInfoText="Missing Info" />
          </div>
        );
      }
      const contribFreq = findFrequencyByBenefitKind(
        currentBenguide.contributionFrequencies,
        planType.toUpperCase()
      );
      const { frequency } = contribFreq;
      const employeeContribution = getEEContributionByFrequency(
        frequency,
        value
      );
      const getMaxValue = () => {
        if (isContributionsExist) {
          const b = maxBy(contributions, getEEContributionField(frequency));
          return getEEContributionByFrequency(frequency, b);
        }
        return 0;
      };
      return (
        <div
          className={styles.monthlyCost}
          title={totalCost && currencyFormatter(employeeContribution)}
        >
          <div className={styles.monthlyCostValue}>
            {totalCost
              ? currencyFormatter(employeeContribution)
              : isContributionsExist
              ? currencyFormatter(getMaxValue())
              : `${NDASH} ${NDASH}`}
          </div>

          <div className={styles.monthlyCoversYou}>
            {totalCost || isContributionsExist ? coverageLabel : ''}
          </div>
        </div>
      );
    } else if (fieldName === PLAN_YEAR_MAX) {
      return showCoverageDetails(PLAN_YEAR_MAX);
    } else if (fieldName === ORTHODONTIA_MAX) {
      return showCoverageDetails(ORTHODONTIA_MAX);
    } else if (isAdditionalServicesSupport) {
      return showAdditionalServices();
    }
  };

  const openProviderSearch = () => {
    if (!isEditMode) setIsOpenProviderSearch(true);
  };

  const openPlanTypeDrawer = () => {
    if (!isEditMode) setIsDrawerOpen(true);
  };

  const taggedHRAplan =
    hraPlanList?.find((hraPlans) => hraPlans?.id === plan?.hraPlanId) || null;

  const constructMDVtooltip = () => {
    if (taggedHRAplan) {
      const {
        selectedMedicalPlans = [],
        selectedDentalPlans = [],
        selectedVisionPlans = [],
      } = taggedHRAplan as any;

      const eligiblePlans = [
        !isEmpty(selectedMedicalPlans) && 'Medical',
        !isEmpty(selectedDentalPlans) && 'Dental',
        !isEmpty(selectedVisionPlans) && 'Vision',
      ]
        .filter(Boolean)
        .join(', ');

      return `This HRA allowance is available for eligible ${eligiblePlans} plans.`;
    }
  };

  const getMDVBasicInfo = (ratesEnabled: boolean) => {
    const getHRAContribution = (selectedCoveredType: string) => {
      const employerContribution = (taggedHRAplan as any)?.employerContribution;
      if (taggedHRAplan && employerContribution) {
        switch (selectedCoveredType) {
          case 'EE':
            return employerContribution?.employerEEContribution;
          case 'ES':
            return employerContribution?.employerESContribution;
          case 'EC':
            return employerContribution?.employerECContribution;
          case 'EF':
            return employerContribution?.employerEFContribution;
          case 'EE1':
            return employerContribution?.employerEE1Contribution;
          case 'EE2':
            return employerContribution?.employerEE2Contribution;
          case 'EE3':
            return employerContribution?.employerEE3Contribution;
          case 'EE4':
            return employerContribution?.employerEE4Contribution;
          case 'EE5':
            return employerContribution?.employerEE5Contribution;
          case 'EE6':
            return employerContribution?.employerEE6Contribution;
          default:
            return undefined;
        }
      }
      return undefined;
    };

    const navigateToHRA = () => {
      navigate(
        `/${urlHash}${
          currentBenguide.employerName ? '/' + currentBenguide.employerName : ''
        }/tax-advantaged/hra`
      );
    };

    const displayHRAContributionValue = () => {
      return (
        <div
          onClick={() => {
            navigateToHRA();
          }}
          className={`${styles.hsaContribution} ${styles.hra}`}
        >
          {!isEditMode ? (
            <Popover
              content={<div>{constructMDVtooltip()}</div>}
              placement="left"
              overlayClassName={`popoverInner ${styles.planWrapper}`}
            >
              <div className={styles.hsaValue}>{`$${getHRAContribution(
                contributionFiled
              )} HRA ALLOWANCE/YR`}</div>
            </Popover>
          ) : (
            <div className={styles.hsaValue}>{`$${getHRAContribution(
              contributionFiled
            )} HRA ALLOWANCE/YR`}</div>
          )}
        </div>
      );
    };

    return (
      <div
        className={`${styles.detailSection} ${styles.basicInfo} ${
          planType === MDVTypes.MEDICAL && styles.medicalBasicInfo
        } ${
          !isEditMode &&
          (!ratesEnabled || rateValidations.hasMismatchContributions) &&
          styles.medicalBasicInfoHiddenRates
        }`}
        ref={detailsSectionRef}
        style={!isMobile ? { height: detailsHeight } : {}}
      >
        <div className={styles.details}>
          <div className={styles.logoWrapper}>
            {plan.benefitCarrier && (
              <img
                src={plan.benefitCarrier.logoUrl}
                alt={`${plan.benefitCarrier.name} logo`}
                className={styles.logo}
              />
            )}
          </div>
          {planType === MDVTypes.MEDICAL && !isEditMode && (
            <div className={styles.compare}>
              Compare
              <StyledCheckbox
                checked={selectedPlanForCompare.includes(plan.id)}
                onChange={(event) => {
                  const { target } = event;
                  const { checked } = target;
                  let selectedPlanForCompareCloned = cloneDeep(
                    selectedPlanForCompare
                  );
                  if (checked) {
                    selectedPlanForCompareCloned.push(plan.id);
                  } else {
                    selectedPlanForCompareCloned =
                      selectedPlanForCompareCloned.filter(
                        (id) => id !== plan.id
                      );
                  }
                  dispatch(
                    setSelectedPlanForCompare(selectedPlanForCompareCloned)
                  );
                }}
              />
            </div>
          )}
          <div className={styles.planName} title={name}>
            {name}
          </div>
          {planType === MDVTypes.MEDICAL && (
            <div className={styles.taxAdvantageTags}>
              {displayTaxAdvantageTag(
                hsaMedicalContributionVO,
                TaxAdvantageBenefitKind.HSA
              )}
              {displayTaxAdvantageTag(
                hraMedicalContributionVO,
                TaxAdvantageBenefitKind.HRA
              )}
            </div>
          )}
          <div className={styles.taxAdvantageTags}>
            {(planType === MDVTypes.MEDICAL ||
              planType === MDVTypes.DENTAL ||
              planType === MDVTypes.VISION) &&
              !isUndefined(getHRAContribution(contributionFiled)) &&
              taggedHRAplan?.id !== undefined &&
              hraPlanIds.includes(taggedHRAplan?.id) &&
              displayHRAContributionValue()}
          </div>
          <span className={styles.taxAdvantageBr}></span>
        </div>

        {((planType === MDVTypes.MEDICAL && plan.type) ||
          (benefitCarrier && benefitCarrier.providerSearchUrl)) && (
          <div
            className={`${styles.links} ${
              planType === MDVTypes.MEDICAL && styles.medicalPlanInfo
            }`}
          >
            {planType === MDVTypes.MEDICAL && plan.type && (
              <div className={styles.link}>
                <div className={styles.icon}>
                  <img src={iconPlayPlanInfo} alt="Plan Info" />
                </div>
                <div
                  className={styles.planTypeButton}
                  onClick={openPlanTypeDrawer}
                >
                  {plan.hsaCompatible ? 'HSA' : plan.type} Info
                </div>
              </div>
            )}
            {benefitCarrier && benefitCarrier.providerSearchUrl && (
              <div className={styles.link}>
                <div className={styles.icon}>
                  <img src={iconDocSearch} alt="doctor search" />
                </div>
                <div
                  className={styles.providerSearch}
                  onClick={openProviderSearch}
                >
                  Provider Search
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getServiceText = useCallback(
    (
      benefitCode: number,
      customServices: any[],
      fieldFormatter: string
    ): any => {
      const filteredCustomService = customServices.find(
        (service: any) => service.benefitCode.code === benefitCode
      );

      if (filteredCustomService) {
        const network = get(
          filteredCustomService,
          `serviceValue.${networkType}Network`
        );
        return (
          <div>
            {isMobile && (
              <div className={styles.serviceHeaderMobile}>
                {filteredCustomService?.benefitCode?.shortName}
              </div>
            )}
            <MDVFormattedValue
              removeSup={planType === BENEFIT_TYPES.VISION}
              data={network}
              formatter={fieldFormatter}
              isEdit={isEditMode}
            />
          </div>
        );
      }

      return isEditMode ? (
        <MissingInfo missingInfoText="Missing Info" />
      ) : isMobile ? null : (
        <div className={styles.emptyValue}>-</div>
      );
    },
    [networkType, isEditMode, isMobile, planType]
  );

  const getRxText = useCallback(
    (rxCode: string, rxCosts: any, fieldFormatter: string, rxType: string) => {
      if (rxType === 'rxCost') {
        const { inNetwork = {}, outOfNetwork = {} } = rxCosts;
        const network =
          selectedNetwork === IN_NETWORK.value ? inNetwork : outOfNetwork;
        if (
          !network[rxCode]?.costSharingPolicy &&
          !network[rxCode]?.benefitCovered &&
          (isEditMode || isMobile)
        ) {
          return !isMobile ? (
            <MissingInfo missingInfoText="Missing Info" />
          ) : null;
        }
        return (
          <div>
            {isMobile && (
              <div className={styles.serviceHeaderMobile}>
                {RX_TIER_LABELS.find((tier) => tier.value === rxCode)?.label}
              </div>
            )}
            <MDVFormattedValue
              removeSup={planType === BENEFIT_TYPES.VISION}
              data={network[rxCode]}
              formatter={fieldFormatter}
              isEdit={isEditMode}
            />
          </div>
        );
      } else if (rxType === 'mailRxCost') {
        const { inNetwork = {}, outOfNetwork = {} } = mailOrderRxCosts;
        const network =
          selectedNetwork === IN_NETWORK.value ? inNetwork : outOfNetwork;
        if (
          !network[rxCode]?.costSharingPolicy &&
          !network[rxCode]?.benefitCovered &&
          (isEditMode || isMobile)
        ) {
          return !isMobile ? (
            <MissingInfo missingInfoText="Missing Info" />
          ) : null;
        }
        return (
          <div>
            {isMobile && (
              <div className={styles.serviceHeaderMobile}>
                {
                  MAIL_ORDER_RX_TIER_LABELS.find(
                    (tier) => tier.value === rxCode
                  )?.label
                }
              </div>
            )}
            <MDVFormattedValue
              removeSup={planType === BENEFIT_TYPES.VISION}
              data={network[rxCode]}
              formatter={fieldFormatter}
            />
          </div>
        );
      }
    },
    [selectedNetwork, mailOrderRxCosts, isEditMode, isMobile, planType]
  );

  const showAdditionalServices = () => {
    const rows = [] as any;
    additionalServices
      .filter((service) => {
        if (planType === MDVTypes.DENTAL && !benguide.isAdvanceProfile) {
          return !DENTAL_ADVANCED_PROFILE_PARAMETERS.includes(
            service.fieldValue
          );
        }
        if (planType === MDVTypes.VISION && !benguide.isAdvanceProfile) {
          return !VISION_ADVANCED_PROFILE_PARAMETERS.includes(
            service.fieldValue
          );
        }
        return true;
      })
      .forEach((service, key) => {
        const { serviceBenefitCode, fieldFormatter = '' } = service;
        rows.push(
          <div key={key} className={styles.rowWrapper}>
            {getServiceText(serviceBenefitCode, customServices, fieldFormatter)}
          </div>
        );
      });
    return rows;
  };

  const getEditCustomFieldModal = (
    isModelOpen: boolean,
    closeModal: () => void,
    customField: CustomField
  ) => {
    const handleOnSave = async (customField: CustomField) => {
      await dispatch(editCustomFieldValue(customField));
      await dispatch(getCustomFieldsToState());
      closeModal();
    };
    return (
      <EditCustomRowModal
        visible={isModelOpen}
        onClose={closeModal}
        onSave={handleOnSave}
        customFieldData={customField}
      />
    );
  };
  const upClient = useUPClient();

  return (
    <div className={styles.medicalCardValuesWrapper}>
      {attachedPlans.length > 1 && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            customModal={() => <></>}
            overlayComponent={
              <div className={styles.moveOverlay}>
                {!hideLeftMoveButton && (
                  <div className={styles.left} onClick={moveLeft}>
                    <img src={iconLeftArrow} alt="move-left-icon" />
                    <label>Move</label>
                  </div>
                )}
                {!hideRightMoveButton && (
                  <div className={styles.right} onClick={moveRight}>
                    <label>Move</label>
                    <img src={iconRightArrow} alt="move-right-icon" />
                  </div>
                )}
              </div>
            }
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}

      <div
        className={
          selectedPlanForCompare.includes(plan.id)
            ? styles.selectedCardShadow
            : styles.topShadow
        }
      ></div>
      {dataGroups.map((titleGroup, titleGroupIndex) => {
        const { event } = titleGroup;
        if (titleGroup.isSeparateRowEvent) {
          return titleGroup?.elements?.map((title: any) => {
            if (title.fieldName === CUSTOM_FIELD) {
              return plan?.customFields?.length === 0 ? (
                <>
                  <div className={styles.emptyCustom}></div>
                </>
              ) : (
                plan?.customFields?.map(
                  (customField: CustomField, index: number) => {
                    return (
                      <div key={index} className={styles.rowWrapper}>
                        {isEditMode ? (
                          <EditableHotspot
                            key={index}
                            alwaysVisible={false}
                            editIcon={<img src={iconEdit} alt="edit" />}
                            editText="Edit"
                            customModal={(
                              isModalVisible: boolean,
                              closeModal: () => void
                            ) =>
                              getEditCustomFieldModal(
                                isModalVisible,
                                closeModal,
                                customField
                              )
                            }
                            editIconWrapperClassName={styles.singleRowEdit}
                          >
                            <MDVCardCustomValue
                              title={title}
                              data={customField}
                              isMobile={isMobile}
                              isEditMode={isEditMode}
                            />
                          </EditableHotspot>
                        ) : (
                          <MDVCardCustomValue
                            title={title}
                            data={customField}
                            isMobile={isMobile}
                            isEditMode={isEditMode}
                          />
                        )}
                      </div>
                    );
                  }
                )
              );
            }
            if (title.fieldName === 'spaceField' && !isMobile) {
              return <div className={styles.titleSeperator} />;
            }
          });
        }
        return (
          <EditableHotspot
            key={titleGroupIndex}
            alwaysVisible={false}
            editIcon={<img src={iconEdit} alt="edit" />}
            editText="Edit"
            useChannel={{
              client: upClient,
              channel: getMDVChannel(),
              event: event,
              data: plan,
              dbgMetaData: benguide,
            }}
          >
            {titleGroup.elements.map((title: any, titleIndex: number) => {
              if (showRxRow(title, attachedPlans, selectedNetwork)) {
                if (
                  title?.fieldName === IND_WITHIN_FAMILY_DEDUCTIBLE &&
                  !nonExistDeductibleIndividual?.hasOwnProperty(
                    IND_WITHIN_FAMILY_DEDUCTIBLE
                  )
                ) {
                  return null;
                }
                if (
                  title?.fieldName === IND_WITHIN_FAMILY_OOP_MAX &&
                  !nonExistDeductibleIndividual?.hasOwnProperty(
                    IND_WITHIN_FAMILY_OOP_MAX
                  )
                ) {
                  return null;
                }
                if (
                  !(
                    nonExistCustomServices &&
                    nonExistCustomServices.hasOwnProperty(
                      title.serviceBenefitCode
                    ) &&
                    nonExistCustomServices[title.serviceBenefitCode] ===
                      attachedPlans.length
                  ) &&
                  (!(
                    nonExistRxCosts &&
                    nonExistRxCosts.hasOwnProperty(
                      title.fieldName + title.rxCode
                    ) &&
                    nonExistRxCosts[title.fieldName + title.rxCode] ===
                      attachedPlans.length
                  ) ||
                    (title.rxCode && isEditMode))
                ) {
                  if (!title.fieldName) {
                    return <>{getPlanValues(title)}</>;
                  } else {
                    return (
                      <div key={titleIndex} className={styles.rowWrapper}>
                        {getPlanValues(title)}
                      </div>
                    );
                  }
                }
                return null;
              }
            })}
          </EditableHotspot>
        );
      })}

      {summarySection}
      {planType === MDVTypes.MEDICAL && (
        <PlanTypeInfoDrawer
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          isHSA={plan.hsaCompatible}
          planType={plan.type}
        />
      )}
      <ProviderSearchModal
        visible={isOpenProviderSearch}
        onCancel={() => setIsOpenProviderSearch(false)}
        carrier={plan.benefitCarrier}
        planName={plan.name}
        networkName={plan.planNetworkName}
      />
    </div>
  );
};

export default MDVCardValues;

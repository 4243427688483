import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import Plan from 'model/Plan';
import { convertToOptions, convertToWebLinkType } from 'utils/commonUtil';
import Option from 'model/Option';
import WebLinkType from 'model/WebLinkType';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { openDocument, openWebLink } from 'modules/benefitsWellness/util';
import MDVPlan from 'model/MDVPlan';
import styles from './viewResourceModal.module.less';

interface ViewResourcesModalProps {
  plan: Plan | MDVPlan;
  isVisible: boolean;
  setIsVisible: Function;
  benefitKind: string;
}

const ViewResourcesModal: FC<ViewResourcesModalProps> = (
  props: ViewResourcesModalProps
) => {
  const { plan, isVisible, setIsVisible, benefitKind } = props;

  const [additionalPlanDocumentTypes, setAdditionalPlanDocumentTypes] =
    useState<Option[]>([]);
  const [selectedWebLinkList, setSelectedWebLinkList] = useState<WebLinkType[]>(
    []
  );

  useEffect(() => {
    if (plan) {
      if (plan.additionalDocuments || plan.documents) {
        setAdditionalPlanDocumentTypes(
          convertToOptions(plan.documents).concat(
            convertToOptions(plan.additionalDocuments)
          )
        );
      }
      if (plan.additionalWeblinks) {
        setSelectedWebLinkList(convertToWebLinkType(plan.additionalWeblinks));
      }
    }
  }, [plan, setSelectedWebLinkList]);

  const renderList = (docType: Option, index: number) => {
    return (
      <div key={index}>
        <LinkButton
          onClick={() => openDocument(plan.id, docType.value, benefitKind)}
        >
          {docType.value ? docType.value : docType.label}
        </LinkButton>
      </div>
    );
  };
  const renderWebList = (webLink: WebLinkType, index: number) => {
    return (
      <div key={index}>
        <LinkButton onClick={() => openWebLink(webLink.weblink, benefitKind)}>
          {webLink.planDocumentName}
        </LinkButton>
      </div>
    );
  };

  return (
    <ConfirmationDialog
      width="550px"
      title="PLAN RESOURCES"
      visible={isVisible}
      footer={[
        <Button
          className={styles.doneButton}
          key="submit"
          type="primary"
          onClick={() => setIsVisible(false)}
        >
          Done
        </Button>,
      ]}
    >
      {additionalPlanDocumentTypes.map(renderList)}
      {selectedWebLinkList.map(renderWebList)}
    </ConfirmationDialog>
  );
};

export default ViewResourcesModal;

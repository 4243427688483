import React, { FC, useEffect, useState } from 'react';
import { Spin, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams, useLocation } from 'react-router-dom';

import { benguideUrl, onSchedEnvironment } from 'utils/apiUtil';
import { getHashWithToken } from 'utils/routeUtils';

import styles from './onSched.module.less';

type OnSchedProps = {
  serviceId: string;
  locationId: string;
  clientId: string;
  consultationType: string;
};

const OnSched: FC<OnSchedProps> = (props: OnSchedProps) => {
  const { serviceId, clientId, locationId, consultationType } = props;

  const [availabilityElement, setAvailabilityElement] = useState<any>();
  const { hash = '', employerName = '' } = useParams();
  const search = useLocation().search;
  const previewToken = new URLSearchParams(search).get('previewToken');

  const benguideHomePgUrl =
    benguideUrl + getHashWithToken(hash, previewToken || '', employerName);
  useEffect(() => {
    // eslint-disable-next-line new-cap
    const onsched = (window as any).OnSched(clientId, onSchedEnvironment);

    const elements = onsched.elements();

    const availabilityParams = {
      locationId: locationId,
      serviceId: serviceId,
      roundRobin: 1,
      location: 'Refer to the booking confirmation email for meeting details',
      appointmentBM: {
        customFields: {
          field1: benguideHomePgUrl,
          field2: consultationType,
        },
      },
    };

    // Include any additional options (optional)
    const availabilityOptions = {
      resourceConferenceUrl: true,
    };

    // Create the availability element
    const availability = elements.create(
      'availability',
      availabilityParams,
      availabilityOptions
    );

    const elAvailability = document.getElementById('availability');
    if (elAvailability) {
      elAvailability.addEventListener('clickTime', () => {
        // Locate the textbox field
        waitForEl('#onsched-field-message').then((onschedFieldMessage: any) => {
          // Make the field "required"
          onschedFieldMessage.required = true;
          // Change the placeholder text
          onschedFieldMessage.placeholder =
            "Please provide a brief summary of what you'd like to discuss in your consultation (highly recommended)";
        });
      });
    }
    elAvailability &&
      elAvailability.addEventListener('getAvailability', (e: any) => {
        setAvailabilityElement(e);
      });

    // Mount the element (which triggers the API)
    availability.mount('availability');
  }, [serviceId, clientId, locationId, benguideHomePgUrl, consultationType]);

  const waitForEl = (selector: any) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const antIcon = <LoadingOutlined className={styles.loadingIcon} spin />;

  return (
    <div className={styles.onSchedWrapper}>
      {!availabilityElement && <Spin indicator={antIcon} />}
      <Row>
        <Col>
          <div id="availability"></div>
        </Col>
      </Row>
    </div>
  );
};

export default OnSched;

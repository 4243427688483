import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  getDentalPlanList,
  reloadDentalSection,
} from 'modules/benefitsWellness/slices/dentalSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  RELOAD_DENTAL_PLAN_LIST,
  RELOAD_DENTAL_PLAN,
  DENTAL_PLAN_CHANNEL,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';

interface DentalPlansChannelProps {
  setOverlayHide?: () => void;
}

const DentalPlansChannel = (props: DentalPlansChannelProps) => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  return (
    <UPClient
      channel={DENTAL_PLAN_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_DENTAL_PLAN_LIST:
            dispatch(
              getDentalPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_DENTAL_PLAN:
            dispatch(
              reloadDentalSection(benguide.masterId, benguide.latestRevision)
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            if (props.setOverlayHide) {
              props.setOverlayHide();
            }
            break;
        }
      }}
    />
  );
};

export default DentalPlansChannel;

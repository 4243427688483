import axios from 'axios';
import VoluntaryBenefitsPlan from 'model/VoluntaryBenefitsPlan';
import { baseApi } from 'utils/apiUtil';
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  http,
} from 'utils/httpUtil';

const planUrl = baseApi + '/v1/plans/voluntary-benefits';

export const getPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return http.get(`${planUrl}/roster?`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-classes': benefitClasses.join(','),
    },
  });
};

export const updateVoluntaryBenefitResources = (
  payload: VoluntaryBenefitsPlan
) => {
  const newPayload = { ...payload };
  const formData = new FormData();
  const entries = Object.entries(newPayload);
  entries.forEach((item) => {
    if (
      item[1] !== null &&
      item[1] !== undefined &&
      (item[0] === 'file' || Object.keys(item[1]).length !== 0)
    ) {
      formData.append(item[0], item[1]);
    }
  });

  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    withCredentials: true,
  };

  return axios.put(`${planUrl}/${payload.id}`, formData, config);
};

export const getAllVoluntaryBenefitsPlans = (
  employerId: string,
  planYearId: string,
  benefitKinds: string[],
  benefitClasses: string[]
) => {
  return http.get(`${planUrl}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': benefitKinds.join(','),
      'benefit-classes': benefitClasses.join(','),
    },
  });
};

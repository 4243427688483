export const MEDICAL_PLAN_CHANNEL = 'medical.plans';
export const BENGUIDE_COMMON_CHANNEL = 'benguide.common';
export const UP_COMMON_CHANNEL = 'up.common';

export const DENTAL_PLAN_CHANNEL = 'dental.plans';
export const RELOAD_DENTAL_PLAN_LIST = 'RELOAD_DENTAL_PLAN_LIST';
export const RELOAD_DENTAL_PLAN = 'RELOAD_DENTAL_PLAN';
export const CLOSE_MODALS = 'CLOSE_MODALS';

export const TELEHEALTH_PLAN_CHANNEL = 'telehealth.plans';
export const RELOAD_TELEHEALTH_PLAN_LIST = 'RELOAD_TELEHEALTH_PLAN_LIST';
export const RELOAD_TELEHEALTH_PLAN = 'RELOAD_TELEHEALTH_PLAN';
export const OPEN_TELEHEALTH_PLAN_EDIT_MODAL =
  'OPEN_TELEHEALTH_PLAN_EDIT_MODAL';

export const VISION_PLAN_CHANNEL = 'vision.plans';
export const RELOAD_VISION_PLAN_LIST = 'RELOAD_VISION_PLAN_LIST';
export const RELOAD_VISION_PLAN = 'RELOAD_VISION_PLAN';

export const OPEN_ADD_NEW_PLAN_MODAL = 'OPEN_ADD_NEW_PLAN_MODAL';
export const OPEN_DEDUCTIBLE_MODAL = 'OPEN_DEDUCTIBLE_MODAL';
export const OPEN_BASIC_INFO_MODAL = 'OPEN_BASIC_INFO_MODAL';
export const OPEN_SERVICE_MODAL = 'OPEN_SERVICE_MODAL';
export const OPEN_RX_MODAL = 'OPEN_RX_MODAL';
export const ENABLE_EDIT_MODE = 'ENABLE_EDIT_MODE';
export const OPEN_RATES_MODAL = 'OPEN_RATES_MODAL';

// TODO: Remove sample event once MDV development done
export const OPEN_SAMPLE_MODAL = 'OPEN_SAMPLE_MODAL';

export const DEDUCTIBLE_SAVED = 'DEDUCTIBLE_SAVED';
export const RELOAD_MEDICAL_PLAN_LIST = 'RELOAD_MEDICAL_PLAN_LIST';
export const RELOAD_MEDICAL_PLAN = 'RELOAD_MEDICAL_PLAN';
export const CLOSE_DBG_MODAL = 'CLOSE_DBG_MODAL';
export const BENGUIDE_LOADING_COMPLETED = 'BENGUIDE_LOADING_COMPLETED';
export const RESET_NOTIFICATION_OVERLAY = 'RESET_NOTIFICATION_OVERLAY';

export const CLIENT_UP = 'CLIENT_UP';

export const CREATE_BENGUIDE_CHANNEL = 'create.benguide';
export const EDIT_BENGUIDE_CHANNEL = 'edit.benguide';
export const BENGUIDE_NOTIFICATIONS = 'benguide.notifications';
export const CLOSE_CREATE_BENGUIDE_MODAL = 'CLOSE_CREATE_BENGUIDE_MODAL';
export const EDIT_BENGUIDE_STARTED = 'EDIT_BENGUIDE_STARTED';

export const WELLBEING_PLAN_CHANNEL = 'wellbeing.plans';
export const RELOAD_WELLBEING_PLAN_LIST = 'RELOAD_WELLBEING_PLAN_LIST';
export const RELOAD_WELLBEING_PLAN = 'RELOAD_WELLBEING_PLAN';
export const OPEN_WELLBEING_PLAN_EDIT_MODAL = 'OPEN_WELLBEING_PLAN_EDIT_MODAL';

export const HOLIDAY_AND_TIMEOFF_CHANNEL = 'holiday.timeoff.plans';
export const RELOAD_HOLIDAY_AND_TIMEOFF_PLAN_LIST =
  'RELOAD_HOLIDAY_AND_TIMEOFF_PLAN_LIST';
export const OPEN_HOLIDAY_AND_TIMEOFF_EDIT_MODAL =
  'OPEN_HOLIDAY_AND_TIMEOFF_EDIT_MODAL';
export const RELOAD_HOLIDAY_AND_TIMEOFF_SECTION =
  'RELOAD_HOLIDAY_AND_TIMEOFF_SECTION';

export const FAMILY_AND_LEAVE_CHANNEL = 'family.leave.plans';
export const RELOAD_FAMILY_AND_LEAVE_PLAN_LIST =
  'RELOAD_FAMILY_AND_LEAVE_PLAN_LIST';
export const OPEN_FAMILY_AND_LEAVE_EDIT_MODAL =
  'OPEN_FAMILY_AND_LEAVE_EDIT_MODAL';
export const RELOAD_FAMILY_AND_LEAVE_SECTION =
  'RELOAD_FAMILY_AND_LEAVE_SECTION';

export const ADDITIONAL_PERKS_CHANNEL = 'additional.perks.plans';
export const RELOAD_ADDITIONAL_PERKS_PLAN_LIST =
  'RELOAD_ADDITIONAL_PERKS_PLAN_LIST';
export const RELOAD_ADDITIONAL_PERKS_SECTION =
  'RELOAD_ADDITIONAL_PERKS_SECTION';
export const OPEN_ADDITIONAL_PERKS_EDIT_MODAL =
  'OPEN_ADDITIONAL_PERKS_EDIT_MODAL';

export const LIFE_AND_DISABILITY_CHANNEL = 'life.and.disability.plans';
export const RELOAD_LIFE_AND_DISABILITY_PLAN_LIST =
  'RELOAD_LIFE_AND_DISABILITY_PLAN_LIST';
export const RELOAD_LIFE_AND_DISABILITY_PLAN =
  'RELOAD_LIFE_AND_DISABILITY_PLAN';
export const OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL =
  'OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL';

export const VOLUNTARY_BENEFITS_CHANNEL = 'voluntary.benefits.plans';
export const RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST =
  'RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST';
export const OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL =
  'OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL';
export const RELOAD_VOLUNTARY_BENEFITS_SECTION =
  'RELOAD_VOLUNTARY_BENEFITS_SECTION';

export const REFRESH_BENGUIDE = 'REFRESH_BENGUIDE';
export const BENGUIDE_NOTIFICATION_RECEIVED = 'BENGUIDE_NOTIFICATION_RECEIVED';
export const BENGUIDE_ACK_NOTIFICATION = 'BENGUIDE_ACK_NOTIFICATION';
export const BENGUIDE_SAVE_COMPLETED = 'BENGUIDE_SAVE_COMPLETED';
export const EXIT_BENGUIDE = 'EXIT_BENGUIDE';
export const PREVIEW_BENGUIDE = 'PREVIEW_BENGUIDE';
export const PUBLISH_BENGUIDE = 'PUBLISH_BENGUIDE';
export const BENGUIDE_NOTIFICATION_SHOW_OVERLAY =
  'BENGUIDE_NOTIFICATION_SHOW_OVERLAY';
export const BENGUIDE_NOTIFICATION_HIDE_OVERLAY =
  'BENGUIDE_NOTIFICATION_HIDE_OVERLAY';

export const FOUR_O_ONE_K_PLAN_CHANNEL = 'four.o.one.k.plans';
export const RELOAD_FOUR_O_ONE_K_PLAN_LIST = 'RELOAD_FOUR_O_ONE_K_PLAN_LIST';
export const RELOAD_FOUR_O_ONE_K_PLAN = 'RELOAD_FOUR_O_ONE_K_PLAN';
export const OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL =
  'OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL';

export const TAX_ADVANTAGED_PLAN_CHANNEL = 'tax.advantage.plans';
export const RELOAD_TAX_ADVANTAGED_PLAN_LIST =
  'RELOAD_TAX_ADVANTAGED_PLAN_LIST';
export const RELOAD_TAX_ADVANTAGED_PLAN = 'RELOAD_TAX_ADVANTAGED_PLAN';
export const OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL =
  'OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL';

export const OPEN_CUSTOM_ROW_MODAL = 'OPEN_CUSTOM_ROW_MODAL';

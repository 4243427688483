import { FC, useCallback, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  findFrequencyByBenefitKind,
  getAgeBandedPlans,
} from 'modules/benefitsWellness/util';
import MDVPlansWrapper from 'modules/benefitsWellness/components/MDVPlansWrapper/MDVPlansWrapper';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import {
  getVisionPlanList,
  saveAndReloadVisionSection,
} from 'modules/benefitsWellness/slices/visionSlice';
import useBenguide from 'hooks/benguide';
import MDVPlan from 'model/MDVPlan';
import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';
import { VISION_PLAN_CHANNEL } from 'modules/clients/UPClient/UPClientConst';
import VisionPlansChannel from 'modules/clients/UPClient/channels/VisionPlansChannel';
import NoMDVPlansView from 'components/NoPlansView/NoMDVPlansView';
import VisionCardWrapper from 'modules/benefitsWellness/components/VisionCardWrapper/VisionCardWrapper';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  AGE_BAND,
  FOUR_TIER,
  N_TIER,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import Rate from 'model/Rate';

import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { NotificationContextProvider } from 'context/NotificationContext';

import styles from './vision.module.less';

const Vision: FC = () => {
  const { visionPlanList, visionSection } = useAppSelector(
    (state) => state.vision
  );
  const { rateValidations } = useAppSelector((state) => state.benefitWellness);
  const { plans } = visionSection;
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const [planListUpdateFlag, setPlanListUpdateFlag] = useState<boolean>(false);
  const { masterId, planYearId, employerId, benefitClasses } = benguide;
  const isEditMode = benguide.isEditMode;
  const updatePlanList = (
    visionPlanList: MDVPlan[],
    orderByPlanNames: boolean
  ) => {
    setPlanListUpdateFlag(!planListUpdateFlag);
    dispatch(
      saveAndReloadVisionSection(masterId, visionPlanList, orderByPlanNames)
    );
  };
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const enablePlanRecommender = plans.length > 1;
  const contribFreq = findFrequencyByBenefitKind(
    benguide.contributionFrequencies,
    BENEFIT_CATEGORY.VISION.value
  );
  const { enabled } = contribFreq;

  const reloadAllVisionPlans = useCallback(() => {
    dispatch(getVisionPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, benefitClasses, dispatch]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  const foundRates =
    (!isEmpty(plans) &&
      plans
        .map((plan) => plan.rates)
        .filter((plan) => plan)
        .map((rate) => {
          return Object.values(rate);
        })
        .reduce((arr1, arr2) => {
          return arr1.concat(arr2);
        }, [])
        .map((rate) => (rate as Rate).type)) ||
    [];

  const rateType = foundRates.includes(N_TIER)
    ? N_TIER
    : foundRates.includes(FOUR_TIER)
    ? FOUR_TIER
    : AGE_BAND;

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.visionWrapper}>
        <VisionPlansChannel />
        <BenefitGuideRefreshChannel benefitKind={MDVTypes.VISION} />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <MDVPlansWrapper
          planType={MDVTypes.VISION}
          addedPlansCount={plans.length}
          enablePlanRecommender={enablePlanRecommender}
          showPlanRecommender={false}
          disablePlanRecommenderOnEdit={!(plans.length > 1)}
          hasMismatchContributions={
            rateValidations.hasMismatchContributions ||
            !enabled ||
            getAgeBandedPlans(plans).length === plans.length
          }
          rateType={rateType}
          planListUpdateFlag={planListUpdateFlag}
        />
        {plans.length > 0 ? (
          <VisionCardWrapper
            onUpdatePlanList={updatePlanList}
            allPlans={visionPlanList.data}
            reloadPlans={reloadAllVisionPlans}
            showAlert={showAlert}
            isLoading={visionPlanList.inProgress}
          />
        ) : isEditMode ? (
          <AddOrRemovePlans
            visible={true}
            withoutModal={true}
            title="Vision"
            onSave={updatePlanList}
            addedPlanList={plans}
            allPlans={visionPlanList.data}
            reloadPlans={reloadAllVisionPlans}
            isLoading={visionPlanList.inProgress}
            channel={VISION_PLAN_CHANNEL}
            showAlert={showAlert}
          />
        ) : (
          <NoMDVPlansView />
        )}
        {plans.length > 0 && (
          <div className={styles.bottomText}>{LEGAL_DISCLAIMER}</div>
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default Vision;

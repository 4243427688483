import {
  CSSProperties,
  FC,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Input } from 'antd';
import { SketchPicker } from 'react-color';

import styles from './colorPicker.module.less';

export type RGBAModel = {
  r: string | number;
  g: string | number;
  b: string | number;
  a: string | number;
};

export type Color = {
  rgb: RGBAModel;
  hex: string;
};

type ColorPickerProps = {
  disabled?: boolean;
  handleClick: () => void;
  styleColor: CSSProperties;
  displayColorPicker?: boolean;
  textColor: string;
  name: string;
  value: string;
  placeholder: string;
  handleInputChange: (values: any) => void;
  handleChange: (values: any) => void;
  handleClose: () => void;
  ref: React.Ref<any>;
};

const ColorPicker: FC<ColorPickerProps> = forwardRef(
  (
    {
      disabled = false,
      handleClick,
      styleColor,
      displayColorPicker = false,
      textColor,
      name,
      placeholder,
      value,
      handleInputChange,
      handleChange,
      handleClose,
    }: ColorPickerProps,
    ref
  ) => {
    const [hex, setHexValue] = useState<string>(value);
    useImperativeHandle(
      ref,
      () => ({
        clearColor() {
          setHexValue('');
        },
      }),
      []
    );

    const changeHandler = (event: any) => {
      const { value } = event.target;
      setHexValue(value);
      handleInputChange(event);
    };

    const sketchPickerChange = (event: any) => {
      const { hex } = event;
      setHexValue(hex);
      handleChange(event);
    };

    return (
      <div className={styles.colorPicker}>
        {displayColorPicker ? (
          <div className={styles.popover}>
            <div className={styles.cover} onClick={handleClose} />
            <SketchPicker color={textColor} onChange={sketchPickerChange} />
          </div>
        ) : null}
        <div
          className={`${styles.swatch} ${
            disabled ? styles.disableBackground : ''
          }`}
          onClick={() => !disabled && handleClick()}
        >
          <div style={styleColor}></div>
        </div>
        <Input
          name={name}
          className={styles.textBox}
          value={hex}
          onClick={handleClick}
          placeholder={placeholder}
          onChange={changeHandler}
          disabled={disabled}
          onKeyDown={handleClose}
        />
      </div>
    );
  }
);
ColorPicker.displayName = 'ColorPicker';
export default ColorPicker;

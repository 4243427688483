import React, { FC, MouseEventHandler, useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/redux';

import useBenguide from 'hooks/benguide';
import AnnualCostBreakdown from 'modules/planRecommender/components/AnnualCostBreakdown/AnnualCostBreakdown';
import {
  getFrequencyName,
  PLAN_RECOMMENDER_QUESTIONS,
} from 'modules/planRecommender/pages/constants';
import { getCurrentAnswerByQuestionType } from 'modules/planRecommender/pages/PlanRecommender/PlanRecommender';
import PlanRecommenderPlanMobile from 'modules/planRecommender/components/PlanRecommenderPlan/PlanRecommenderPlanMobile/PlanRecommenderPlanMobile';
import leftRoundIcon from 'images/icons/icon-left-round-arrow.svg';
import rightRoundIcon from 'images/icons/icon-right-round-arrow.svg';
import { getMaxRxCostCount } from 'utils/commonUtil';
import styles from './PlanRecommenderPlansMobile.module.less';

type PlanRecommenderPlansProps = {
  plans: any;
  onEdit: MouseEventHandler<HTMLSpanElement>;
  benAdminUrl: string;
  downloadResultPdf: MouseEventHandler<HTMLButtonElement>;
  questions: any;
  answersInProgress: boolean;
  isEditDrawerOpen: boolean;
  resultInProgress: boolean;
};
let isDisableArrowLeft = false;
let isDisableArrowRight = false;
const PlanRecommenderPlans: FC<PlanRecommenderPlansProps> = (
  props: PlanRecommenderPlansProps
) => {
  const [activePlan, setActivePlan] = useState<number>(0);
  const [questions] = useState(props.questions);

  const { benefitClasses } = useBenguide();
  const { plans } = useAppSelector((state) => state.planRecommender);
  if (activePlan === 0) {
    isDisableArrowLeft = true;
  } else {
    isDisableArrowLeft = false;
  }

  if (activePlan === plans.length - 1) {
    isDisableArrowRight = true;
  } else {
    isDisableArrowRight = false;
  }

  const goToPrevious = () => {
    const isFirstSlide = activePlan === 0;
    const newIndex = isFirstSlide ? plans.length - 1 : activePlan - 1;
    setActivePlan(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = activePlan === plans.length - 1;
    const newIndex = isLastSlide ? 0 : activePlan + 1;
    setActivePlan(newIndex);
  };

  const goToSlide = (index: number) => {
    setActivePlan(index);
  };
  // Transforms rates object to extract rates for first benefit class of the benguide
  const transformedPlans = useMemo(() => {
    const firstBenefitClass: undefined | string =
      benefitClasses && benefitClasses[0];

    if (plans.length > 0 && firstBenefitClass) {
      return plans.map((plan) => {
        const clonedPlan = cloneDeep(plan);
        let selectedRate = null;
        for (const group in plan?.rates) {
          if (group === firstBenefitClass) {
            selectedRate = plan.rates[group];
            break;
          }
        }

        if (selectedRate) {
          clonedPlan.rates = selectedRate;
        }
        return clonedPlan;
      });
    }
    return [];
  }, [plans, benefitClasses]);

  const rxTierCount = getMaxRxCostCount(transformedPlans);

  const getRecommendedPlanName = (plans: any) => {
    const recommendedPlan =
      plans.length > 0
        ? plans.find((plan: any) => (plan ? plan.rank === 1 : false))
        : {};
    const { name = '' } = recommendedPlan || {};
    return name;
  };

  const checkPlanTypesNTier = () => {
    let isNTierPlan = false;
    transformedPlans.forEach((plan: any) => {
      const { rates } = plan || {};
      if (rates) {
        const { type } = rates;
        if (type === 'N_TIER') {
          isNTierPlan = true;
        }
      }
    });
    return isNTierPlan;
  };

  const getRecommendedPlanFrequency = (plans: any) => {
    const recommendedPlan =
      plans.length > 0
        ? plans.find((plan: any) => (plan ? plan.rank === 1 : false))
        : {};
    const { annualPremiumConfig = {} } = recommendedPlan || {};
    const { premiumFrequency = '' } = annualPremiumConfig || {};
    return premiumFrequency;
  };

  const getHeaderText = () => {
    const coveringType = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.COVERAGE
    ) || [0];

    const isProceduresNone = (
      getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.PROCEDURES
      ) || []
    ).includes('NONE');

    const doctorVisits = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.DOCTOR_VISITS
    );

    const specialistVisits = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.SPECIALIST_VISITS
    );

    const doctorPreferenceArray = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.DOCTOR_PREFERENCE
    );

    const doctorPreference = doctorPreferenceArray
      ? doctorPreferenceArray[0]
      : undefined;

    const prescriptions = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.PRESCRIPTIONS
    ) || [0, 0, 0, 0];

    const drugsType = ['low', 'medium', 'high', 'specialty'].filter(
      (drug, index) => {
        return prescriptions[index] > 0;
      }
    );
    const textSegments = [
      <React.Fragment key={1}>
        This is based on covering benefits for{' '}
      </React.Fragment>,
      <React.Fragment key={2}>
        <u>{(PLAN_RECOMMENDER_HEADER_TEXTS.COVERAGE as any)[coveringType]}</u>{' '}
      </React.Fragment>,
      <React.Fragment key={3}>
        {(PLAN_RECOMMENDER_HEADER_TEXTS.PROCEDURES as any)[isProceduresNone]}{' '}
      </React.Fragment>,
      <React.Fragment key={4}>
        {(PLAN_RECOMMENDER_HEADER_TEXTS.DOCTOR_VISITS as any)[doctorVisits]}{' '}
      </React.Fragment>,
      <React.Fragment key={5}>
        {
          (PLAN_RECOMMENDER_HEADER_TEXTS.SPECIALIST_VISITS as any)[
            specialistVisits
          ]
        }{' '}
      </React.Fragment>,
      <React.Fragment key={6}>
        {
          (PLAN_RECOMMENDER_HEADER_TEXTS.DOCTOR_PREFERENCE as any)[
            doctorPreference
          ]
        }{' '}
      </React.Fragment>,
      <React.Fragment key={7}>
        {drugsType !== undefined && drugsType.length === 0 ? (
          <>
            and <u>not taking any prescription drugs.</u>{' '}
          </>
        ) : (
          <>
            and using{' '}
            <u>
              {drugsType !== undefined &&
                drugsType.join(', ').replace(/, ([^,]*)$/, ' & $1')}{' '}
              cost drugs
            </u>
            .
          </>
        )}
      </React.Fragment>,
    ];

    return textSegments;
  };

  const { onEdit, downloadResultPdf, answersInProgress, resultInProgress } =
    props;

  const recommendedPlanName = getRecommendedPlanName(transformedPlans);

  const recommendedPlanFrequency =
    getRecommendedPlanFrequency(transformedPlans);
  const classes = [
    `${styles.planRecommenderPlansWrapper} planRecommenderPlansWrapper`,
  ];
  if (transformedPlans.length === 0) {
    classes.push(styles.planRecommenderPlansWrapperEmpty);
  }
  const isNTierPlan = checkPlanTypesNTier();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;
  return (
    <div className={classes.join(' ')}>
      {!props.isEditDrawerOpen && (
        <div
          className={`${styles.planRecommenderLoaderOverlay} ${
            answersInProgress || resultInProgress ? styles.show : styles.hidden
          }`}
        >
          <Spin indicator={antIcon} className={styles.loader} />
        </div>
      )}

      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          {recommendedPlanName &&
            !answersInProgress &&
            !resultInProgress &&
            `We recommend the ${recommendedPlanName} plan for you!`}
          {!recommendedPlanName &&
            !answersInProgress &&
            !resultInProgress &&
            `Sorry we don't have any recommendations for you!`}
        </div>
        <div className={styles.subTitle}>
          {getHeaderText()}
          <span
            className={`${styles.ml_1} ${styles.editLink}`}
            onClick={onEdit}
          >
            Edit
          </span>
        </div>
      </div>
      <div className={styles.plansWrapper}>
        <div className={styles.plansTitleWrapper}>
          {!isNTierPlan && (
            <div
              className={`${styles.titleField} ${styles.monthlyPremium}`}
            >{`${getFrequencyName(recommendedPlanFrequency)} Premium`}</div>
          )}
          <div className={styles.titleField}>Deductible</div>
          <div className={styles.titleField}>Out-of-Pocket-Max</div>
          <div className={styles.titleField}>Primary/Specialist Copay</div>
          <div className={styles.titleField}>Inpatient</div>
          <div className={styles.titleField}>Outpatient</div>
          <div className={styles.titleField}>Retail Rx Tier 1 / 2 / 3 / 4</div>
          <div className={`${styles.titleField} ${styles.hsa}`}>
            HSA Contribution
          </div>
          <div className={`${styles.titleField} ${styles.annualCost}`}>
            Est. Annual Cost
          </div>
          <div className={`${styles.titleField} ${styles.annualCostBreakdown}`}>
            Est. Annual Cost Breakdown
          </div>
        </div>
        <div className={styles.plansDetailsWrapper}>
          {!answersInProgress && !resultInProgress && (
            <div className={styles.planEmptyMsgWrapper}>
              <div className={styles.planEmptyMsg}>
                Sorry, we’re unable to make any recommendations. You may need to
                edit and refine your responses.
              </div>
              <button
                className={`${styles.nextButton} nextButton`}
                onClick={onEdit}
              >
                Edit Responses
              </button>
            </div>
          )}

          <div>
            <div className={`${styles.plan} ${styles.planEmpty}`}></div>
            <div className={`${styles.plan} ${styles.planEmpty}`}></div>
            <div className={`${styles.plan} ${styles.planEmpty}`}></div>
            <div className={styles.planRecoMainWrapper}>
              {!isEmpty(transformedPlans) && (
                <>
                  <div>
                    {!isDisableArrowLeft && (
                      <img
                        src={leftRoundIcon}
                        className={styles.alignLeft}
                        onClick={goToPrevious}
                      />
                    )}
                    {!isDisableArrowRight && (
                      <img
                        src={rightRoundIcon}
                        className={styles.alignRight}
                        onClick={goToNext}
                      />
                    )}
                  </div>
                  <div className={styles.planCardsContainer}>
                    {!isDisableArrowLeft && (
                      <PlanRecommenderPlanMobile
                        key={activePlan - 1}
                        index={activePlan - 1}
                        plan={transformedPlans[activePlan - 1]}
                        rank={transformedPlans[activePlan - 1].rank}
                        activePlan={activePlan}
                        onClick={() => {
                          setActivePlan(activePlan - 1);
                        }}
                        isNTierPlan={isNTierPlan}
                        dbgPlanFrequency={recommendedPlanFrequency}
                        maxRxTiers={rxTierCount}
                      />
                    )}
                  </div>
                  <div className={styles.planCardsContainer1}>
                    <PlanRecommenderPlanMobile
                      key={activePlan}
                      index={activePlan}
                      plan={transformedPlans[activePlan]}
                      rank={transformedPlans[activePlan].rank}
                      activePlan={activePlan}
                      onClick={() => {
                        goToSlide(activePlan);
                      }}
                      isNTierPlan={isNTierPlan}
                      dbgPlanFrequency={recommendedPlanFrequency}
                      maxRxTiers={rxTierCount}
                    />
                  </div>
                  <div className={styles.planCardsContainer2}>
                    {!isDisableArrowRight && (
                      <PlanRecommenderPlanMobile
                        key={activePlan + 1}
                        index={activePlan + 1}
                        plan={transformedPlans[activePlan + 1]}
                        rank={transformedPlans[activePlan + 1].rank}
                        activePlan={activePlan}
                        onClick={() => {
                          setActivePlan(activePlan + 1);
                        }}
                        isNTierPlan={isNTierPlan}
                        dbgPlanFrequency={recommendedPlanFrequency}
                        maxRxTiers={rxTierCount}
                      />
                    )}
                  </div>
                  <div></div>
                </>
              )}
            </div>
          </div>
          <div className={styles.annualCost}>Est. Annual Cost Breakdown</div>
          <div className={styles.breakdownWrapper}>
            <AnnualCostBreakdown activePlan={transformedPlans[activePlan]} />
          </div>
        </div>
      </div>
      {/* TODO - Removed temporary */}
      {/* {benAdminUrl && (
        <div className={styles.footerLink}>
          <button
            className={`${styles.nextButton} nextButton`}
            onClick={() => {
              window.open(benAdminUrl, '_blank');
            }}
          >
            Go to my BenAdmin
          </button>
        </div>
      )} */}
      <div className={styles.footerLink}>
        <button
          className={`${styles.nextButton} ${styles.downloadResultButton} nextButton`}
          onClick={downloadResultPdf}
        >
          Download Results
        </button>
      </div>
      {/* TODO - Removed temporary */}
      {/* {plans.length > 0 && isbenefitsConsultationEnabled && (
        <div className={`${styles.footerLink} pt-2`}>
          <div className={styles.scheduleMeetingLinkBtn} onClick={openCalendly}>
            Questions? Schedule a Benefits Consultation
          </div>
        </div>
      )} */}
    </div>
  );
};

const PLAN_RECOMMENDER_HEADER_TEXTS = {
  COVERAGE: {
    EE: ' just you, ',
    ES: ' you and your partner, ',
    EC: ' you and child(ren), ',
    EF: ' you and your family, ',
  },
  DOCTOR_VISITS: {
    0: (
      <>
        {' '}
        <u>not</u> visiting the doctor,{' '}
      </>
    ),
    2: (
      <>
        visiting the doctor <u>rarely</u>,{' '}
      </>
    ),
    5: (
      <>
        visiting the doctor <u>occasionally</u>,{' '}
      </>
    ),
    10: (
      <>
        visiting the doctor <u>often</u>,{' '}
      </>
    ),
    15: (
      <>
        visiting the doctor <u>frequently</u>,
      </>
    ),
  },
  PROCEDURES: {
    true: (
      <>
        <u>not</u> planning a pregnancy or any procedures,
      </>
    ),
    false: (
      <>
        planning a <u>pregnancy or procedures</u>,
      </>
    ),
  },
  SPECIALIST_VISITS: {
    0: (
      <>
        {' '}
        <u>not</u> visiting a specialist,{' '}
      </>
    ),
    2: (
      <>
        visiting a specialist <u>rarely</u>,
      </>
    ),
    5: (
      <>
        visiting a specialist <u>occasionally</u>,
      </>
    ),
    10: (
      <>
        visiting a specialist <u>often</u>,
      </>
    ),
    15: (
      <>
        visiting a specialist <u>frequently</u>,
      </>
    ),
  },
  DOCTOR_PREFERENCE: {
    IN_NETWORK: (
      <>
        using a <u>narrow network</u>,
      </>
    ),
    BROAD_RANGE: (
      <>
        using a <u>wide network,</u>
      </>
    ),
    KAISER_ONLY: (
      <>
        using a <u>Kaiser</u> ,
      </>
    ),
  },
};
export default PlanRecommenderPlans;

import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import Section from 'model/Section';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
import * as FourOOneKPlanService from 'services/FourOOneKPlanService';
import * as BenguideService from 'services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';

import { SectionName } from 'modules/home/constants';
import FourOOneKPlan from 'model/FourOOneKPlan';

const FOUR_ONE_K_SECTIONS = [
  SectionName.FOUR_ONE_K_OVERVIEW_TEXT,
  SectionName.FOUR_ONE_K_CONTRIBUTIONS_TEXT,
  SectionName.FOUR_ONE_K_ADVANTAGES_TEXT,
];

interface FourOOneKState {
  fourOOneKPlanList: {
    inProgress: boolean;
    error: any;
    data: FourOOneKPlan[];
  };
  fourOOneKSection: {
    inProgress: boolean;
    enable: boolean;
    plans: FourOOneKPlan[];
    error: any;
  };
  fourOOneKDefaultRichMedia: {
    inProgress: boolean;
    error: any;
    media: { [key: string]: string };
  };
}

const initialState: FourOOneKState = {
  fourOOneKPlanList: {
    inProgress: false,
    error: null,
    data: [],
  },
  fourOOneKSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  fourOOneKDefaultRichMedia: {
    inProgress: false,
    error: null,
    media: {
      [SectionName.FOUR_ONE_K_OVERVIEW_TEXT]: '',
      [SectionName.FOUR_ONE_K_CONTRIBUTIONS_TEXT]: '',
      [SectionName.FOUR_ONE_K_ADVANTAGES_TEXT]: '',
    },
  },
};

const fourOOneKSlice = createSlice({
  name: 'fourOOneK',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.fourOOneKPlanList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.fourOOneKPlanList.inProgress = false;
      state.fourOOneKPlanList.error = null;
      state.fourOOneKPlanList.data = payload;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.fourOOneKPlanList.inProgress = false;
      state.fourOOneKPlanList.error = payload;
      state.fourOOneKPlanList.data = [];
    },
    fourOOneKSectionUpdateStart: (state) => {
      state.fourOOneKSection.inProgress = true;
    },
    fourOOneKSectionUpdateCompleted: (state, { payload }) => {
      state.fourOOneKSection.inProgress = false;
      state.fourOOneKSection.enable = payload.enable;
      state.fourOOneKSection.plans = payload.plans;
      state.fourOOneKSection.error = null;
    },
    fourOOneKSectionUpdateFailed: (state, { payload }) => {
      state.fourOOneKSection.inProgress = false;
      state.fourOOneKSection.error = payload;
    },
    getRichMediaDefaultContentStarted: (state) => {
      state.fourOOneKDefaultRichMedia = {
        ...state.fourOOneKDefaultRichMedia,
        inProgress: true,
      };
    },
    getRichMediaDefaultContentCompleted: (state, { payload }) => {
      const { content, propertyName } = payload;
      state.fourOOneKDefaultRichMedia = {
        ...state.fourOOneKDefaultRichMedia,
        media: {
          ...state.fourOOneKDefaultRichMedia.media,
          [propertyName]: content,
        },
        inProgress: false,
        error: null,
      };
    },
    getRichMediaDefaultContentFailed: (state, { payload }) => {
      state.fourOOneKDefaultRichMedia = {
        ...state.fourOOneKDefaultRichMedia,
        inProgress: false,
        error: payload,
      };
    },
  },
});

export const {
  planListFetchingStarted,
  planListFetchingCompleted,
  planListFetchingFailed,
  fourOOneKSectionUpdateStart,
  fourOOneKSectionUpdateCompleted,
  fourOOneKSectionUpdateFailed,
  getRichMediaDefaultContentStarted,
  getRichMediaDefaultContentCompleted,
  getRichMediaDefaultContentFailed,
} = fourOOneKSlice.actions;

export const fetchFourOOneKPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    FourOOneKPlanService.getFourOOneKPlanList(
      employerId,
      planYearId,
      benefitClasses
    )
      .then(({ data }: AxiosResponse) => {
        const { content } = data;
        dispatch(planListFetchingCompleted(content));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateFourOOneKSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    const planPromises = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('retirements', planId || id, revision);
    });
    const plans = (await Promise.all(planPromises)).map(
      (plan: any) => plan.data as Section
    );

    dispatch(fourOOneKSectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const addPlans = (
  benguideId: string,
  request: AddPlanApiRequestPayload
) => {
  return async (dispatch: Dispatch) => {
    BenguideService.addPlans(benguideId, request)
      .then(({ data }) => {
        dispatch(reloadFourOOneKSection(benguideId, data.latestRevision));
      })
      .catch((error) => {
        dispatch(fourOOneKSectionUpdateFailed(error));
      });
  };
};

export const reloadFourOOneKSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getPlanSection(
      benguideId,
      SectionName.RETIREMENT_401K,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateFourOOneKSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaDefaultContent = (
  benguideId: string,
  planId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getRichMediaDefaultContentStarted());
    FOUR_ONE_K_SECTIONS.forEach((section) => {
      BenguideService.getRichMediaContentForPlan(
        benguideId,
        section,
        planId,
        true
      )
        .then(({ data }) => {
          const { content } = data;
          dispatch(
            getRichMediaDefaultContentCompleted({
              content,
              propertyName: section,
            })
          );
        })
        .catch((error) => {
          dispatch(getRichMediaDefaultContentFailed(error));
        });
    });
  };
};

export default fourOOneKSlice.reducer;

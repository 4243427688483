import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  getMedicalPlanList,
  reloadMedicalSection,
} from 'modules/benefitsWellness/slices/medicalSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  MEDICAL_PLAN_CHANNEL,
  RELOAD_MEDICAL_PLAN_LIST,
  RELOAD_MEDICAL_PLAN,
  BENGUIDE_NOTIFICATIONS,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
} from 'modules/clients/UPClient/UPClientConst';

const MedicalPlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  return (
    <UPClient
      channel={MEDICAL_PLAN_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_MEDICAL_PLAN_LIST:
            dispatch(
              getMedicalPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_MEDICAL_PLAN:
            dispatch(
              reloadMedicalSection(benguide.masterId, benguide.latestRevision)
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default MedicalPlansChannel;

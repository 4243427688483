import axios from 'axios';
import WellbeingPlan from 'model/WellbeingPlan';

import { baseApi } from 'utils/apiUtil';
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  http,
} from 'utils/httpUtil';
const planUrl = baseApi + '/v1/plans';

export const getWellbeingPlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return http.get(`${planUrl}/wellbeings/roster`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-classes': benefitClasses.join(','),
    },
  });
};

export const updateWellbeingPlanResources = (payload: WellbeingPlan) => {
  const formData = new FormData();
  Object.entries({ ...payload }).forEach((item) => {
    if (
      item[1] !== null &&
      item[1] !== undefined &&
      (item[0] === 'file' || Object.keys(item[1]).length !== 0)
    ) {
      formData.append(item[0], item[1]);
    }
  });
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    withCredentials: true,
  };
  return axios.put(`${planUrl}/wellbeings/${payload.id}`, formData, config);
};

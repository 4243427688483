import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Menu, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { NotificationContextProvider } from 'context/NotificationContext';
import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';
import PageHeader from 'components/PageHeader/PageHeader';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as LifeDisabilityIcon } from 'images/icons/side-nav/life-and-disability.svg';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  fetchLifePlans,
  getDefaultText,
  getLifeAndDisabilitySection,
  getPlansFromPlanRefs,
  listAllLifePlans,
  saveLifePlanSection,
} from 'modules/benefitsWellness/slices/lifeAndDisabilitySilce';
import Plan from 'model/Plan';
import AddPlanMultipleApiRequestPayload from 'model/AddPlanMultipleApiRequestPayload';
import PlanSelectButton from 'components/PlanSelectButton/PlanSelectButton';
import {
  LIFE_AND_DISABILITY_CARD_ORDER,
  LIFE_PLAN_TYPES,
  VOLUNTARY_LIFE_PLAN_TYPES,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  LIFE_AND_DISABILITY_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import LifeAndDisabilityCard from 'modules/benefitsWellness/components/LifeAndDisabilityCard/LifeAndDisabilityCard';
import LifeAndDisabilityPlansChannel from 'modules/clients/UPClient/channels/LifeAndDisabilityPlansChannel';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import { buildPlanRefsFromPlan } from 'modules/benefitsWellness/util';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import { usePrevious } from 'hooks/usePrevious';
import styles from './lifeAndDisability.module.less';

const LifeAndDisability: FC = () => {
  const { isEditMode, latestRevision } = useBenguide();
  const upClient = useUPClient();

  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const benguideData = useAppSelector((state) => state.benguide.data);
  const lifePlanList = useAppSelector(
    (state) => state.lifeAndDisability.planList.data
  );
  const lifePlanListinProgress = useAppSelector(
    (state) => state.lifeAndDisability.planList.inProgress
  );

  const lifePlanListinSaveInProgress = useAppSelector(
    (state) => state.lifeAndDisability.planList.saveInProgress
  );

  const previousLifePlanListinSaveInProgress = usePrevious(
    lifePlanListinSaveInProgress
  );

  const planRefs = useAppSelector(
    (state) => state.lifeAndDisability.lifeAndDisabilitySection.plans
  );
  const { selectedPlans, inProgress } = useAppSelector(
    (state) => state.lifeAndDisability
  );
  const { data: selectedPlansData, inProgress: selectedPlansInProgress } =
    selectedPlans;

  useEffect(() => {
    const { employerId, planYearId, benefitClasses } = benguideData || {};
    if (employerId && planYearId && isEditMode) {
      dispatch(fetchLifePlans({ employerId, planYearId, benefitClasses }));
    }
  }, [benguideData, isEditMode, dispatch]);

  useEffect(() => {
    if (previousLifePlanListinSaveInProgress && !lifePlanListinSaveInProgress) {
      dispatch(
        getLifeAndDisabilitySection({
          benGuideId: benguideData.masterId,
          revision: latestRevision,
        })
      );
    }
  }, [
    dispatch,
    previousLifePlanListinSaveInProgress,
    lifePlanListinSaveInProgress,
    benguideData.masterId,
    latestRevision,
  ]);

  useEffect(() => {
    dispatch(getPlansFromPlanRefs({ planRefs }));
  }, [planRefs, dispatch]);

  useEffect(() => {
    if (selectedPlansData) {
      const uniquePlansList = uniqBy(selectedPlansData, 'benefitKind');
      if (uniquePlansList) {
        uniquePlansList.forEach((element: any) => {
          if (element.id && element.benefitKind) {
            dispatch(
              getDefaultText(
                benguideData.masterId,
                element.id,
                element.benefitKind
              )
            );
          }
        });
      }
    }
  }, [dispatch, selectedPlansData, benguideData.masterId]);

  const handleSave = (planList: Plan[], orderByPlanNames: boolean) => {
    const sectionAPIRequest: AddPlanMultipleApiRequestPayload = {
      newPlans: [],
    };

    Object.keys({ ...LIFE_PLAN_TYPES, ...VOLUNTARY_LIFE_PLAN_TYPES }).forEach(
      (benefitKind) => {
        const plansForBenefitKind = planList
          .filter((plan) => plan.benefitKind === benefitKind)
          .map((plan) =>
            buildPlanRefsFromPlan(plan, BENEFIT_CATEGORY.LIFE.value)
          );
        sectionAPIRequest.newPlans.push({
          sectionName: benefitKind,
          plans: plansForBenefitKind,
          orderByPlanNames: orderByPlanNames,
        });
      }
    );

    dispatch(
      saveLifePlanSection({
        benGuideId: benguideData.masterId as string,
        revision: benguideData.revision as number,
        addPlansPayload: sectionAPIRequest,
      })
    );
  };

  const listAllPlans = useCallback(() => {
    dispatch(
      listAllLifePlans(
        benguideData.employerId,
        benguideData.planYearId,
        benguideData.benefitClasses
      )
    );
  }, [
    benguideData.benefitClasses,
    benguideData.employerId,
    benguideData.planYearId,
    dispatch,
  ]);

  const sortedPlans = useMemo(() => {
    return [...selectedPlansData].sort(
      (a, b) =>
        LIFE_AND_DISABILITY_CARD_ORDER.indexOf(a.benefitKind) -
        LIFE_AND_DISABILITY_CARD_ORDER.indexOf(b.benefitKind)
    );
  }, [selectedPlansData]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.wrapper}>
        <LifeAndDisabilityPlansChannel />
        <BenefitGuideRefreshChannel benefitKind={BENEFIT_CATEGORY.LIFE.value} />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle={'Life & Disability'}
          pageHeaderIcon={<LifeDisabilityIcon className={styles.headerIcon} />}
          managePlansButton={
            isEditMode &&
            selectedPlansData.length > 0 && (
              <EditableHotspot
                alwaysVisible={true}
                customModal={(visible: boolean, onClose: Function) => (
                  <AddOrRemovePlans
                    visible={visible}
                    onClose={onClose}
                    title="Life & Disability"
                    onSave={handleSave}
                    addedPlanList={selectedPlansData}
                    allPlans={lifePlanList}
                    reloadPlans={listAllPlans}
                    channel={LIFE_AND_DISABILITY_CHANNEL}
                    actionContent={
                      <PlanSelectButton
                        overlay={getAddLifePlanMenu(upClient)}
                        selectLabel="Create New Plan"
                      />
                    }
                    showAlert={showAlert}
                    isLoading={lifePlanListinProgress}
                  />
                )}
              >
                <Button>+ Add or Remove Plans</Button>
              </EditableHotspot>
            )
          }
        />
        <div className={styles.planCardsSection}></div>

        {(inProgress || selectedPlansInProgress) &&
        selectedPlansData.length === 0 ? (
          <Spin />
        ) : sortedPlans.length > 0 ? (
          sortedPlans.map((plan) => (
            <LifeAndDisabilityCard key={plan.id} plan={plan} />
          ))
        ) : (
          isEditMode && (
            <AddOrRemovePlans
              visible
              withoutModal
              title="Life & Disability"
              onSave={handleSave}
              addedPlanList={selectedPlansData}
              allPlans={lifePlanList}
              reloadPlans={listAllPlans}
              channel={LIFE_AND_DISABILITY_CHANNEL}
              actionContent={
                <PlanSelectButton
                  overlay={getAddLifePlanMenu(upClient)}
                  selectLabel="Create New Plan"
                  className={isEmpty(lifePlanList) ? styles.darkButton : ''}
                />
              }
              showAlert={showAlert}
              isLoading={lifePlanListinProgress}
            />
          )
        )}
        {selectedPlansData.length > 0 && (
          <div className={styles.disclaimer}>{LEGAL_DISCLAIMER}</div>
        )}
        {!(inProgress || selectedPlansInProgress) &&
          selectedPlansData.length === 0 &&
          !isEditMode && <NoPlansView className={styles.noPlanView} />}
      </div>
    </NotificationContextProvider>
  );
};

export default LifeAndDisability;

const getAddLifePlanMenu = (upClient: { postMessage: Function }) => {
  return (
    <Menu>
      <Menu.Item key="basicPlans" className={styles.menuItemHeader}>
        BASIC PLANS
      </Menu.Item>
      {Object.values(LIFE_PLAN_TYPES).map((item) => (
        <Menu.Item key={item.value}>
          <EditableHotspot
            alwaysVisible={false}
            useChannel={{
              client: upClient,
              channel: LIFE_AND_DISABILITY_CHANNEL,
              event: OPEN_ADD_NEW_PLAN_MODAL,
              data: { ...item },
            }}
          >
            {item.label}
          </EditableHotspot>
        </Menu.Item>
      ))}
      <Menu.Item key="voluntaryPlans" className={styles.menuItemHeader}>
        VOLUNTARY PLANS
      </Menu.Item>
      {Object.values(VOLUNTARY_LIFE_PLAN_TYPES).map((item) => (
        <Menu.Item key={item.value}>
          <EditableHotspot
            alwaysVisible={false}
            useChannel={{
              client: upClient,
              channel: LIFE_AND_DISABILITY_CHANNEL,
              event: OPEN_ADD_NEW_PLAN_MODAL,
              data: { ...item },
            }}
          >
            {item.label}
          </EditableHotspot>
        </Menu.Item>
      ))}
    </Menu>
  );
};

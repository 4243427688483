import { FC, useEffect, useState } from 'react';

import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';
import MDVNetworkSelector from 'modules/benefitsWellness/components/MDVNetworkSelector/MDVNetworkSelector';
import MDVPlansFilters from 'modules/benefitsWellness/components/MDVPlansFilter/MDVPlansFilters';
import MDVPlanHeaderWrapper from 'modules/benefitsWellness/components/MDVPlanHeaderWrapper/MDVPlanHeaderWrapper';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';

import styles from './mdvPlansWrapper.module.less';

type MDVPlansWrapperProps = {
  planType: MDVTypes;
  addedPlansCount: number;
  enablePlanRecommender: boolean;
  showPlanRecommender: boolean;
  disablePlanRecommenderOnEdit: boolean;
  rateType?: string;
  hasMismatchContributions: boolean;
  onView?: Function;
  closeContributions?: boolean;
  isUpdated?: boolean;
  planListUpdateFlag?: boolean;
};

const MDVPlansWrapper: FC<MDVPlansWrapperProps> = ({
  planType,
  addedPlansCount,
  showPlanRecommender,
  disablePlanRecommenderOnEdit,
  rateType = '',
  hasMismatchContributions,
  onView = () => {},
  closeContributions = true,
  isUpdated = false,
  planListUpdateFlag,
}: MDVPlansWrapperProps) => {
  const currentBenguide = useBenguide();
  const { isTablet } = useAppSelector((state) => state.layout);
  const isEditMode = currentBenguide.isEditMode;
  const [isOpenContributionModal, setIsOpenContributionModal] =
    useState<boolean>(false);

  useEffect(() => {
    onView(isOpenContributionModal);
  }, [isOpenContributionModal, onView]);

  return (
    <div className={styles.mdvPlansWrapper}>
      <MDVPlanHeaderWrapper
        planType={planType}
        addedPlansCount={addedPlansCount}
        rateType={rateType}
        hideCoverageFilter={
          hasMismatchContributions && !currentBenguide.isEditMode
        }
        planListUpdateFlag={planListUpdateFlag}
      />
      <MDVPlansFilters
        show={showPlanRecommender}
        showFilters={
          !isEditMode &&
          addedPlansCount > 0 &&
          planType !== MDVTypes.DENTAL &&
          planType !== MDVTypes.VISION
        }
        onView={setIsOpenContributionModal}
        disabledOnEdit={disablePlanRecommenderOnEdit}
        isEditMode={isEditMode}
        rateType={rateType}
        benefitKind={planType}
        closeContributions={closeContributions}
        isUpdated={isUpdated}
      />
      {isTablet && <MDVNetworkSelector className={styles.networkSelector} />}
    </div>
  );
};

export default MDVPlansWrapper;

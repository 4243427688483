import { createSlice, Dispatch } from '@reduxjs/toolkit';
import set from 'lodash/set';

import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import Section from 'model/Section';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
import * as PlanService from 'modules/auth/services/PlanService';
import * as BenguideService from 'services/BenGuideService';
import * as VoluntaryBenefitsService from 'modules/benefitsWellness/services/VoluntaryBenefitsPlanService';
import VoluntaryBenefitsPlan from 'model/VoluntaryBenefitsPlan';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import { SectionName } from 'modules/home/constants';

type VolBenefitsState = {
  planList: {
    inProgress: boolean;
    data: VoluntaryBenefitsPlan[];
    error: any;
  };
  volBenefitsSection: {
    inProgress: boolean;
    enable: boolean;
    error: any;
    plans: VoluntaryBenefitsPlan[];
  };
  richMedia: any;
  defaultRichMedia: {
    inProgress: boolean;
    error: any;
    data: {
      [key in string]: string;
    };
  };
};

const initialState = {
  planList: {
    inProgress: false,
    data: [],
    error: null,
  },
  volBenefitsSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  richMedia: {},
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.ACCIDENT]: '',
      [SectionName.CRITICAL_ILLNESS]: '',
      [SectionName.HOSPITAL]: '',
      [SectionName.CUSTOM_VOLUNTARY_BENEFIT]: '',
    },
  },
} as VolBenefitsState;

const voluntaryBenefitsSlice = createSlice({
  name: 'voluntaryBenefits',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.data = payload;
      state.planList.error = null;
      state.planList.inProgress = false;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
    },
    volBenefitsSectionUpdateStarted: (state) => {
      state.volBenefitsSection.inProgress = true;
    },
    volBenefitsSectionUpdateCompleted: (state, { payload }) => {
      state.volBenefitsSection.inProgress = false;
      state.volBenefitsSection.enable = payload.enable;
      state.volBenefitsSection.plans = payload.plans;
      state.volBenefitsSection.error = null;
    },
    volBenefitsSectionUpdateFailed: (state, { payload }) => {
      state.volBenefitsSection.inProgress = false;
      state.volBenefitsSection.error = payload;
    },
    richMediaFetchingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingCompleted: (state, { payload }) => {
      const { planId, content } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, content);
      set(state, `richMedia.${planId}.error`, null);
    },
    richMediaUpdatingFailed: (state, { payload }) => {
      const { planId, error } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, '');
      set(state, `richMedia.${planId}.error`, error);
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      (state.defaultRichMedia.data as any)[payload.section] =
        payload.media.content;
    },
    getDefaultMediaFailed(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    listAllVolunataryPlansStarted(state) {
      state.planList.inProgress = true;
      state.planList.data = [];
    },
    listAllVoluntaryPlansSuccess(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = payload;
    },
    listAllVoluntaryPlansFailed(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = [];
      state.planList.error = payload;
    },
  },
});

export const {
  planListFetchingCompleted,
  planListFetchingFailed,
  planListFetchingStarted,
  volBenefitsSectionUpdateStarted,
  volBenefitsSectionUpdateCompleted,
  volBenefitsSectionUpdateFailed,
  richMediaFetchingStarted,
  richMediaUpdatingCompleted,
  richMediaUpdatingFailed,
  richMediaUpdatingStarted,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  listAllVolunataryPlansStarted,
  listAllVoluntaryPlansSuccess,
  listAllVoluntaryPlansFailed,
} = voluntaryBenefitsSlice.actions;

export const listAllVoluntaryPlans = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listAllVolunataryPlansStarted());
    try {
      const response =
        await VoluntaryBenefitsService.getAllVoluntaryBenefitsPlans(
          employerId,
          planYearId,
          [
            SectionName.ACCIDENT,
            SectionName.CRITICAL_ILLNESS,
            SectionName.HOSPITAL,
            SectionName.CUSTOM_VOLUNTARY_BENEFIT,
          ],
          benefitClasses
        );
      dispatch(listAllVoluntaryPlansSuccess(response.data));
    } catch (error) {
      dispatch(listAllVoluntaryPlansFailed(error));
    }
  };
};

export const fetchVolBenefitsPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    VoluntaryBenefitsService.getPlanList(employerId, planYearId, benefitClasses)
      .then((response) => {
        const { data } = response || {};
        const { content } = data;
        dispatch(planListFetchingCompleted(content));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateVoluntaryBenefitsSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    const planPromisses = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('voluntary-benefits', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as Section
    );
    dispatch(volBenefitsSectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const addPlans = (
  benguideId: string,
  request: Array<AddPlanApiRequestPayload>
) => {
  return async (dispatch: Dispatch) => {
    BenguideService.addMultiplePlans(benguideId, { newPlans: request })
      .then(({ data }) => {
        dispatch(
          reloadVoluntaryBenefitsSection(benguideId, data.latestRevision)
        );
      })
      .catch((error) => {
        dispatch(volBenefitsSectionUpdateFailed(error));
      });
  };
};

export const reloadVoluntaryBenefitsSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getPlanSection(
      benguideId,
      benefitCategory.CUSTOM_VOLUNTARY_BENEFIT.value,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateVoluntaryBenefitsSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaContent = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaFetchingStarted(planId));
    BenguideService.getRichMediaContent(benguideId, benefitKind, planId)
      .then(({ data }) => {
        const { content } = data;
        dispatch(richMediaUpdatingCompleted({ planId, content }));
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId, error }));
      });
  };
};

export const updateRichMediaContent = (
  benguideId: string,
  planId: string,
  content: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaUpdatingStarted(planId));
    BenguideService.updateRichMediaContent(
      benguideId,
      benefitKind,
      planId,
      content
    )
      .then(() => {
        dispatch(richMediaUpdatingCompleted({ planId, content }));
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId, error }));
      });
  };
};

export const getDefaultText = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    let sectionName = SectionName.ACCIDENT;
    if (benefitKind === benefitCategory.CRITICAL_ILLNESS.value) {
      sectionName = SectionName.CRITICAL_ILLNESS;
    } else if (benefitKind === benefitCategory.HOSPITAL.value) {
      sectionName = SectionName.HOSPITAL;
    } else if (benefitKind === benefitCategory.CUSTOM_VOLUNTARY_BENEFIT.value) {
      sectionName = SectionName.CUSTOM_VOLUNTARY_BENEFIT;
    }
    BenguideService.getRichMediaContentForPlan(
      benguideId,
      sectionName,
      planId,
      true
    )
      .then(({ data }) => {
        dispatch(
          getDefaultMediaSuccess({
            section: sectionName,
            media: data,
          })
        );
      })
      .catch((error) => {
        dispatch(getDefaultMediaFailed(error));
      });
  };
};

export default voluntaryBenefitsSlice.reducer;

import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import LifePlan from 'model/LifePlan';
import * as LifePLanService from 'modules/benefitsWellness/services/LifePlanService';
import * as BenGuideService from 'services/BenGuideService';
import * as planService from 'modules/auth/services/PlanService';
import PlanReference from 'model/PlanReference';
import { getPlans } from 'modules/auth/services/PlanService';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
import Plan from 'model/Plan';
import AddPlanMultipleApiRequestPayload from 'model/AddPlanMultipleApiRequestPayload';
import Section from 'model/Section';
import { SectionName } from 'modules/home/constants';
import {
  LIFE_PLAN_TYPES,
  VOLUNTARY_LIFE_PLAN_TYPES,
} from 'modules/benefitsWellness/constants/BenefitConstants';

interface LifeAndDisabilityState {
  planList: {
    inProgress: boolean;
    saveInProgress: boolean;
    data: Plan[];
  };
  lifeAndDisabilitySection: {
    enabled: boolean;
    plans: PlanReference[];
  };
  selectedPlans: {
    data: LifePlan[];
    inProgress: boolean;
  };
  inProgress: boolean;
  defaultRichMedia: {
    inProgress: boolean;
    error: any;
    data: {
      [key in string]: string;
    };
  };
}

const initialState = {
  planList: {
    inProgress: false,
    saveInProgress: false,
    data: [],
  },
  lifeAndDisabilitySection: {
    enabled: false,
    plans: [],
  },
  selectedPlans: { data: [], inProgress: false },
  inProgress: false,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.BASIC_LIFE]: '',
      [SectionName.BASIC_ADD]: '',
      [SectionName.BASIC_LIFE_AND_ADD]: '',
      [SectionName.LTD]: '',
      [SectionName.STD]: '',
      [SectionName.VOLUNTARY_ADD]: '',
      [SectionName.VOLUNTARY_LIFE]: '',
      [SectionName.VOLUNTARY_LIFE_ADD]: '',
      [SectionName.VOLUNTARY_STD]: '',
      [SectionName.VOLUNTARY_LTD]: '',
    },
  },
} as LifeAndDisabilityState;

type PlanFetchData = {
  employerId: string;
  planYearId: string;
  benefitClasses: string[];
};

type SaveLifePlanData = {
  benGuideId: string;
  addPlansPayload: AddPlanMultipleApiRequestPayload;
  revision: number;
};

export const fetchLifePlans = createAsyncThunk<any, PlanFetchData>(
  'lifeAndDisability/fetchLifePlans',
  async ({ employerId, planYearId, benefitClasses }) => {
    const response = await LifePLanService.getLifeAndDisabilityPlanList(
      employerId,
      planYearId,
      benefitClasses
    );
    return response.data;
  }
);

export const saveLifePlanSection = createAsyncThunk<
  SectionApiRequestResponse,
  SaveLifePlanData
>(
  'lifeAndDisability/saveLifePlans',
  async ({ benGuideId, addPlansPayload }) => {
    const response = await BenGuideService.addMultiplePlans(
      benGuideId,
      addPlansPayload
    );
    return response.data;
  }
);

export const getPlansFromPlanRefs = createAsyncThunk<
  LifePlan[],
  { planRefs: PlanReference[] }
>('lifeAndDisability/getPlansFromPlanRefs', async ({ planRefs }) => {
  const planPromises = planRefs.map((plan) => {
    const { planId, revision } = plan;
    return getPlans('insurances', planId, revision);
  });
  const promiseResponses = await Promise.all(planPromises);
  return promiseResponses.map((plan) => plan.data);
});

export const getLifeAndDisabilitySection = createAsyncThunk<
  SectionApiRequestResponse,
  { benGuideId: string; revision: number }
>('lifeAndDisability/getLifeAndDisabilitySection', async ({ benGuideId }) => {
  const response = await BenGuideService.getBenefitPageSection(
    benGuideId,
    'LIFE_AND_DISABILITY'
  );
  return response.data;
});

const lifeAndDisabilitySlice = createSlice({
  name: 'lifeAndDisability',
  initialState,
  reducers: {
    updateLifeSection(state, { payload }) {
      state.lifeAndDisabilitySection = payload;
    },
    lifeAndDisabilitySectionUpdateCompleted: (state, { payload }) => {
      state.selectedPlans.data = payload.plans;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      (state.defaultRichMedia.data as any)[payload.section] =
        payload.media.content;
    },
    getDefaultMediaFailed(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    listAllLifePlansStarted(state) {
      state.planList.inProgress = true;
      state.planList.data = [];
    },
    listAllLifePlansSuccess(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = payload;
    },
    listAllLifePlansFailed(state) {
      state.planList.inProgress = false;
      state.planList.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLifePlans.pending, (state) => {
      state.planList.inProgress = true;
    });
    builder.addCase(fetchLifePlans.fulfilled, (state, { payload }) => {
      state.planList.data = payload;
      state.planList.inProgress = false;
    });
    builder.addCase(saveLifePlanSection.pending, (state) => {
      state.planList.inProgress = true;
      state.planList.saveInProgress = true;
    });
    builder.addCase(saveLifePlanSection.fulfilled, (state) => {
      state.planList.inProgress = false;
      state.planList.saveInProgress = false;
    });
    builder.addCase(getPlansFromPlanRefs.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(getPlansFromPlanRefs.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.selectedPlans.data = payload;
    });
    builder.addCase(getLifeAndDisabilitySection.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(
      getLifeAndDisabilitySection.fulfilled,
      (state, { payload }) => {
        state.inProgress = false;
        state.lifeAndDisabilitySection = payload.benefitPage;
      }
    );
  },
});

export const listAllLifePlans = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listAllLifePlansStarted());
    try {
      const response = await LifePLanService.getAllLifeAndDisabilityPlans(
        employerId,
        planYearId,
        [LIFE_PLAN_TYPES.BASIC_ADD.value],
        benefitClasses
      );

      dispatch(listAllLifePlansSuccess(response.data));
    } catch (error) {
      dispatch(listAllLifePlansFailed());
    }
  };
};

export const updateLifeAndDisaPlanSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    const planPromisses = section.plans.map((plan) => {
      const { id, planId, revision } = plan;
      return planService.getPlans('insurances', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as LifePlan
    );
    dispatch(updateLifeSection(section));
    dispatch(lifeAndDisabilitySectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const getDefaultText = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    let sectionName = SectionName.BASIC_LIFE;
    if (benefitKind === LIFE_PLAN_TYPES.BASIC_ADD.value) {
      sectionName = SectionName.BASIC_ADD;
    } else if (benefitKind === LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value) {
      sectionName = SectionName.BASIC_LIFE_AND_ADD;
    } else if (benefitKind === LIFE_PLAN_TYPES.STD.value) {
      sectionName = SectionName.STD;
    } else if (benefitKind === LIFE_PLAN_TYPES.LTD.value) {
      sectionName = SectionName.LTD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value) {
      sectionName = SectionName.VOLUNTARY_ADD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value) {
      sectionName = SectionName.VOLUNTARY_LIFE;
    } else if (
      benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value
    ) {
      sectionName = SectionName.VOLUNTARY_LIFE_ADD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value) {
      sectionName = SectionName.VOLUNTARY_STD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value) {
      sectionName = SectionName.VOLUNTARY_LTD;
    }
    BenGuideService.getRichMediaContentForPlan(
      benguideId,
      sectionName,
      planId,
      true
    )
      .then(({ data }) => {
        dispatch(
          getDefaultMediaSuccess({
            section: sectionName,
            media: data,
          })
        );
      })
      .catch((error) => {
        dispatch(getDefaultMediaFailed(error));
      });
  };
};

export const {
  updateLifeSection,
  lifeAndDisabilitySectionUpdateCompleted,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  listAllLifePlansStarted,
  listAllLifePlansSuccess,
  listAllLifePlansFailed,
} = lifeAndDisabilitySlice.actions;

export default lifeAndDisabilitySlice.reducer;

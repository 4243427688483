import { FC } from 'react';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { viewPlanSummary } from 'modules/benefitsWellness/util';

import styles from './planDocumentLinks.module.less';
type Props = {
  planId: string;
  benefitKind: string;
  documents: { [key: string]: string };
  className?: string;
};

const PlanDocumentLinks: FC<Props> = (props: Props) => {
  const { documents, planId, benefitKind, className = '' } = props;

  return (
    <div className={className}>
      {Object.keys(documents).map((docKey) => (
        <LinkButton
          key={docKey}
          className={styles.documentLink}
          onClick={() => viewPlanSummary(planId, docKey, benefitKind)}
        >
          Plan Resources
        </LinkButton>
      ))}
    </div>
  );
};

export default PlanDocumentLinks;

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'hooks/redux';

import ImageUploader from 'components/ImageUploader/ImageUploader';
import EditableHotspot, {
  SIDE_BAR,
} from 'components/EditableHotspot/EditableHotspot';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import { benguideApiV2 } from 'utils/apiUtil';

import { handleImageUpload } from 'modules/home/slices/homeSlice';
import { ImageType } from 'modules/home/constants';

import editIcon from 'images/icon-edit.svg';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { getBase64Image } from 'utils/screenshotUtil';
import useBenguide from 'hooks/benguide';

type HeaderLogoProps = {
  employerLogo: string;
  originalLogoRef?: string;
  logoCropParams?: OriginalImageCropParams;
  masterId: string;
  defaultEmployerLogo: string;
  defaultOriginalEmployerLogo: string;
  defaultEmployerLogoCropParams: OriginalImageCropParams;
  className?: string;
};

const HeaderLogo = ({
  employerLogo,
  masterId,
  defaultEmployerLogo,
  originalLogoRef,
  logoCropParams,
  defaultOriginalEmployerLogo,
  defaultEmployerLogoCropParams,
  className = '',
}: HeaderLogoProps) => {
  const dispatch = useAppDispatch();
  const [logoImg, setHomeImg] = useState<string>('');
  const [originalLogo, setOriginalLogo] = useState<string>('');
  const [logoCrop, setLogoCrop] = useState<OriginalImageCropParams | null>(
    null
  );
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [defaultOriginalBase64Logo, setDefaultOriginalBase64Logo] =
    useState('');
  const imageUploaderRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const { revision, latestRevision } = useBenguide();

  useEffect(() => {
    if (!logoImg) {
      if (employerLogo) {
        setHomeImg(
          `${benguideApiV2}/${masterId}/common-images?id=${employerLogo}&type=${
            ImageType.EMPLOYER_LOGO
          }&revision=${latestRevision || revision}&revision=${
            latestRevision || revision
          }`
        );
      } else {
        setHomeImg(defaultEmployerLogo);
        getBase64Image(defaultOriginalEmployerLogo, true).then((data) => {
          setOriginalLogo(data as string);
          setDefaultOriginalBase64Logo(data as string);
        });
        setLogoCrop(defaultEmployerLogoCropParams);
      }
    }
  }, [
    employerLogo,
    masterId,
    defaultEmployerLogo,
    defaultOriginalEmployerLogo,
    defaultEmployerLogoCropParams,
    logoImg,
    latestRevision,
    revision,
  ]);

  useEffect(() => {
    if (originalLogoRef) {
      setOriginalLogo(
        `${benguideApiV2}/${masterId}/common-images?&type=${
          ImageType.EMPLOYER_LOGO
        }&original=true&revision=${latestRevision || revision}`
      );
    }
  }, [originalLogoRef, masterId, revision, latestRevision]);

  useEffect(() => {
    if (logoCropParams) {
      setLogoCrop(logoCropParams);
    }
  }, [logoCropParams]);

  useEffect(() => {
    if (defaultOriginalEmployerLogo) {
      getBase64Image(defaultOriginalEmployerLogo, true).then((data) =>
        setDefaultOriginalBase64Logo(data as string)
      );
    }
  }, [defaultOriginalEmployerLogo]);

  const handleLogoImageUpload = (
    image: string,
    original?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setLoadingImage(true);
    setHomeImg(image);
    setLogoCrop(cropArea || null);
    setOriginalLogo(original || '');

    if (original && cropArea) {
      dispatch(
        handleImageUpload(
          masterId,
          image,
          ImageType.EMPLOYER_LOGO,
          original,
          cropArea,
          () => {
            (imageUploaderRef?.current as any)?.close();
            setLoadingImage(false);
          }
        )
      );
    }
  };

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setSaveDisable(true)}
      />
      <div className={`${className}`}>
        <EditableHotspot
          section={SIDE_BAR}
          alwaysVisible={!logoImg}
          editText="Edit Logo"
          editIcon={<img src={editIcon} alt="Edit Logo" />}
          customModal={(visible: boolean, onClose: Function) => (
            <ImageUploader
              title="Logo"
              cropShape="rect"
              uploadedImage={logoImg}
              aspect={4 / 3}
              loading={false}
              onConfirm={handleLogoImageUpload}
              showOperations
              uploadNewImgText="+ Upload New Image"
              isOpenImageTray={visible}
              onCloseImgTray={onClose}
              uploadImgAfterResetText="Upload Image"
              hideUploader={true}
              customCropper={{ width: 220, height: 80 }}
              defaultLogo={defaultOriginalBase64Logo}
              customUploaderSize={520}
              cropperHelperText="Fit logo within constraints above."
              unCroppedImage={originalLogo}
              defaultCropArea={logoCrop || undefined}
              defaultImageCropArea={defaultEmployerLogoCropParams}
              ref={imageUploaderRef}
              preventCloseOnConfirm={true}
              saving={loadingImage}
            />
          )}
        >
          {<></>}
        </EditableHotspot>
      </div>
    </NotificationContextProvider>
  );
};
export default HeaderLogo;

import { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PlanDocumentLinks from 'components/PlanDocumentLinks/PlanDocumentLinks';
import { DOCUMENT_TYPE } from 'constants/commonConstants';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';
import IconEdit from 'images/icon-edit.svg';
import {
  TAX_ADVANTAGED_PLAN_CHANNEL,
  OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import * as BenguideService from 'services/BenGuideService';

import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import { COMMUTER_PLAN_CONTENT_TABS } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';
import PlanCardHeader from 'modules/benefitsWellness/components/PlanCardHeader/PlanCardHeader';
import ContributionField from 'modules/taxAdvantaged/components/ContributionField/ContributionField';
import PlanCardSectionEditor from 'components/PlanCardSectionEditor/PlanCardSectionEditor';

import styles from './commuterPlanCard.module.less';
type Props = {
  plan: TaxAdvantageAccountsPlan;
};

const CommuterPlanCard: FC<Props> = (props: Props) => {
  const { plan } = props;

  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [selectedEditorContent, setSelectedEditorContent] =
    useState<string>('');
  const [editorContent, setEditorContent] = useState<{ [key: string]: string }>(
    {
      [SectionName.COMMUTER_OVERVIEW_TEXT]: '',
      [SectionName.COMMUTER_ELIGIBILITY_TEXT]: '',
      [SectionName.COMMUTER_EXPENSES_TEXT]: '',
      [SectionName.COMMUTER_ADVANTAGES_TEXT]: '',
      [SectionName.COMMUTER_CONSIDERATIONS_TEXT]: '',
    }
  );
  const [planContent, setPlanContent] = useState<{ [key: string]: string }>({
    [SectionName.COMMUTER_OVERVIEW_TEXT]: '',
    [SectionName.COMMUTER_ELIGIBILITY_TEXT]: '',
    [SectionName.COMMUTER_EXPENSES_TEXT]: '',
    [SectionName.COMMUTER_ADVANTAGES_TEXT]: '',
    [SectionName.COMMUTER_CONSIDERATIONS_TEXT]: '',
  });
  const [selectedOption, setSelectedOption] = useState<string>(
    SectionName.COMMUTER_OVERVIEW_TEXT
  );
  const [activeTab, setActiveTab] = useState<string>(
    SectionName.COMMUTER_OVERVIEW_TEXT
  );

  const upClient = useUPClient();
  const { masterId, isEditMode } = useBenguide();

  const defaultRichMedia = useAppSelector(
    (state) => state.commuter.defaultRichMedia.data
  );

  const planCardEditorRef = useRef<any>();

  useEffect(() => {
    getContent(masterId, plan.id);
  }, [masterId, plan.id]);

  const getContent = async (masterId: string, planId: string) => {
    setContentLoading(true);
    try {
      const promises = await Promise.all(
        COMMUTER_PLAN_CONTENT_TABS.map((section) => {
          const result = BenguideService.getRichMediaContentForPlan(
            masterId,
            section.code,
            planId,
            false
          ).then((response) => ({
            section: section.code,
            data: response.data,
          }));
          return result;
        })
      );
      promises.forEach((sectionData) => {
        setPlanContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        setEditorContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        if (sectionData.section === SectionName.COMMUTER_OVERVIEW_TEXT) {
          setSelectedEditorContent(sectionData.data.content);
          if (planCardEditorRef.current) {
            planCardEditorRef.current.changeContent(sectionData.data.content);
          }
        }
      });
    } catch (error) {}
    setContentLoading(false);
  };

  const getDefaultRichMediaForSection = (selectTab: string) => {
    const sectionPropertyName = COMMUTER_PLAN_CONTENT_TABS.find(
      (section) => section.code === selectTab
    )?.code;
    if (sectionPropertyName && defaultRichMedia[sectionPropertyName]) {
      return defaultRichMedia[sectionPropertyName];
    }
    return '';
  };

  const saveContent = async () => {
    const editedContent = Object.entries(editorContent)
      .filter(([key, value]) => {
        return planContent[key] !== value;
      })
      .map(([key, value]) => ({ section: key, content: value }));
    setContentLoading(true);

    const editPromises = async () => {
      try {
        for (const section of editedContent) {
          await BenguideService.updateRichMediaContent(
            masterId,
            section.section,
            plan.id,
            section.content
          );
          setPlanContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          setEditorContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          if (activeTab === section.section) {
            setSelectedEditorContent(section.content);
          }
        }
      } catch (error) {}
    };
    editPromises();

    setContentLoading(false);
  };

  const updateEditorContent = (data: string) => {
    setSelectedEditorContent(data);
    setEditorContent((prevData) => ({ ...prevData, [selectedOption]: data }));
  };

  const onSelectOptionChange = (section: SectionName) => {
    setSelectedOption(section);
    setSelectedEditorContent(editorContent[section]);
  };

  const resetAll = () => {
    setEditorContent({ ...planContent });
    setSelectedOption(activeTab);
    setSelectedEditorContent(planContent[activeTab]);
  };

  const onTabsChange = (key: string) => {
    setActiveTab(key);
    onSelectOptionChange(key as SectionName);
  };

  const handleResetDefault = () => {
    const defaultContent = getDefaultRichMediaForSection(selectedOption);
    setEditorContent((prevState) => ({
      ...prevState,
      [selectedOption]: defaultContent,
    }));
    setSelectedEditorContent(defaultContent);
    planCardEditorRef.current.changeContent(defaultContent);
  };

  return (
    <PlanCardContainer>
      <div className={styles.planDetailsWrapper}>
        <div className={styles.planDetailsContainer}>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit"
            editIcon={<img src={IconEdit} alt="edit icon" />}
            useChannel={{
              client: upClient,
              channel: TAX_ADVANTAGED_PLAN_CHANNEL,
              event: OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
              data: plan,
            }}
            className={styles.hotSpot}
          >
            <div className={styles.planDetails}>
              <PlanCardHeader
                planName={plan.name}
                carrier={plan.benefitCarrier}
              />
              <div className={styles.planInfoValues}>
                <div className={styles.contributionFields}>
                  {plan.employerMonthlyContribution !== null &&
                    plan.employerMonthlyContribution !== undefined && (
                      <ContributionField
                        label="Employer Contributions per Month"
                        value={plan.employerMonthlyContribution}
                        type="employer"
                      />
                    )}
                  {plan.maxMonthlyContribution !== null &&
                    plan.maxMonthlyContribution !== undefined && (
                      <ContributionField
                        label="Max Monthly Contribution"
                        value={plan.maxMonthlyContribution}
                        type="general"
                      />
                    )}
                </div>
                <div className={styles.otherContribution}>
                  {plan.employerContribution.otherContribution}
                </div>
                {Object.keys(plan.documents).includes(
                  DOCUMENT_TYPE.PLAN_SUMMARY.value
                ) && (
                  <PlanDocumentLinks
                    planId={plan.id}
                    benefitKind={plan.benefitKind}
                    documents={{
                      [DOCUMENT_TYPE.PLAN_SUMMARY.value]:
                        plan.documents[DOCUMENT_TYPE.PLAN_SUMMARY.value],
                    }}
                  />
                )}
              </div>
            </div>
          </EditableHotspot>
        </div>
      </div>
      <div className={styles.tabsArea}>
        {contentLoading ? (
          <Spin />
        ) : (
          <PlanCardSectionEditor
            ref={planCardEditorRef}
            activeKey={activeTab}
            defaultActiveKey={SectionName.COMMUTER_OVERVIEW_TEXT}
            onTabClick={onTabsChange}
            editorSelectedTab={selectedOption}
            onEditorTabChange={onSelectOptionChange}
            tabs={COMMUTER_PLAN_CONTENT_TABS}
            planContent={planContent}
            isEditMode={isEditMode}
            resetAll={resetAll}
            selectedEditorContent={selectedEditorContent}
            onResetToDefault={handleResetDefault}
            selectedTabDefaultContent={getDefaultRichMediaForSection(
              selectedOption
            )}
            onUpdateContent={updateEditorContent}
            onResetToCurrent={() => {
              setEditorContent({ ...planContent });
            }}
            benguideId={masterId}
            onSaveContent={saveContent}
          />
        )}
      </div>
    </PlanCardContainer>
  );
};

export default CommuterPlanCard;

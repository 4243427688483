import { FC, useEffect, useRef, useState } from 'react';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useAppDispatch } from 'hooks/redux';

import { createRichMedia } from 'modules/newHireChecklist/slices/nhcSlice';
import { SectionName } from 'modules/home/constants';

type NHCEditorProps = {
  isOpenModal: boolean;
  closeModal: Function;
  editorContent: string;
  updateEditorContent: (data: string) => void;
  resetToDefault: () => void;
  isResetEnabled: boolean;
  benguideId?: string;
  defaultContent?: string;
  resetToCurrentValue: Function;
};

const NHCEditor: FC<NHCEditorProps> = (props: NHCEditorProps) => {
  const {
    isOpenModal,
    closeModal,
    editorContent,
    updateEditorContent,
    resetToDefault,
    isResetEnabled,
    benguideId,
    defaultContent,
    resetToCurrentValue,
  } = props;
  const textEditorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<string>(editorContent);

  useEffect(() => {
    if (isOpenModal) {
      setContent(editorContent || '');
      textEditorRef.current.changeEditorContent(editorContent);
    }
  }, [isOpenModal, editorContent]);

  const resetContent = () => {
    resetToDefault();
  };

  const closeOverviewModal = () => {
    resetToCurrentValue();
    if (textEditorRef.current) {
      textEditorRef.current.clearTextEditorContent();
    }
    closeModal();
  };

  const handleEditorChange = (data: string) => {
    setContent(data);
    updateEditorContent(data);
  };

  const handleSubmit = () => {
    if (benguideId)
      dispatch(createRichMedia(benguideId, SectionName.NHC_TEXT, content));
    closeModal();
  };

  return (
    <HalfScreenModal
      width={900}
      title="New Hire Checklist"
      visible={isOpenModal}
      onReset={resetContent}
      onCancel={closeOverviewModal}
      onSubmit={handleSubmit}
      confirmModalShow={true}
      isResetEnabled={isResetEnabled}
      increaseResetSpacing
      afterClose={() => textEditorRef?.current?.resetUndo()}
    >
      <RichTextEditor
        limit={10000}
        initialValue={defaultContent}
        onChange={handleEditorChange}
        defaultText={editorContent}
        ref={textEditorRef}
        masterId={benguideId}
        height={960}
      />
    </HalfScreenModal>
  );
};

export default NHCEditor;

export const ADDITIONAL_RESOURCE_TYPES = {
  FILE: 'file',
  WEBLINK: 'webLink',
};

export const FILE = 'FILE';
export const WEBLINK = 'WEBLINK';
export const PLAN_DOCUMENT = 'PLAN_DOCUMENT';
export const PLAN_SUMMARY = 'PLAN_SUMMARY';
export const PLAN_ADDITIONAL_DOCUMENT = 'PLAN_ADDITIONAL_DOCUMENT';

export const FORMAT_VALIDATE: string = 'FORMAT';
export const SIZE_VALIDATE: string = 'SIZE';
export const INVALID_WEBLINK: string = 'INVALID_WEBLINK';

export const MEDICAL_PLAN_DOCUMENT_TYPE = {
  SBC: { value: 'SBC', label: 'SBC' },
  COC: { value: 'COC', label: 'COC' },
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
};

export const DV_PLAN_DOCUMENT_TYPE = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  COC: { value: 'COC', label: 'COC File' },
};

export const TAX_ADVANTAGED_DOC_TYPE = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  PLAN_DOCUMENT: { value: 'PLAN_DOCUMENT', label: 'Plan Document' },
};

export const LIFE_DOCUMENT_TYPE = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  PORTABILITY_CONVERSION_FORM: {
    value: 'PORTABILITY_AND_CONVERSION',
    label: 'Portability & Conversion Form',
  },
  COC: { value: 'COC', label: 'COC File' },
};

export const STD_DOCUMENT_TYPE = {
  CLAIM_FORM_STD: { value: 'CLAIM_FORM_STD', label: 'STD Claim Form' },
  CLAIM_FORM_STD_LTD: {
    value: 'CLAIM_FORM_STD_LTD',
    label: 'STD to LTD Claim Form',
  },
};

export const LTD_DOCUMENT_TYPE = {
  CLAIM_FORM_LTD: { value: 'CLAIM_FORM_LTD', label: 'LTD Claim Form' },
};

export const VOL_LIFE_DOCUMENT_TYPE = {
  EOI: { value: 'EOI', label: 'EOI Form' },
};

export const RETIREMENT_DOC_TYPE = {
  PLAN_SUMMARY: { value: 'PLAN_DOCUMENT', label: 'Plan Document' },
  FOUR_O_ONE_K_ROLLOVER_FORM: {
    value: 'FOUR_O_ONE_K_ROLLOVER_FORM',
    label: '401(k) Rollover Form',
  },
};

export const TAX_ADVANTAGED_ACCOUNT = 'TAX_ADVANTAGED_ACCOUNT';
export const LIFE = 'Life & Disability';

export const CARRIER_TYPE = {
  SAVING: 'SAVING',
  COMMUTER: 'COMMUTER',
  RX_DELIVERY_RX_COUPONS: 'RX_DELIVERY_RX_COUPONS',
  TELEHEALTH: 'TELEHEALTH',
};

export const VOLUNTARY_LIFE_PLAN_TYPE = [
  'VOLUNTARY_LIFE',
  'VOLUNTARY_ADD',
  'VOLUNTARY_LIFE_ADD',
  'VOLUNTARY_STD',
  'VOLUNTARY_LTD',
];

export const BASIC_LIFE_TYPE = [
  'BASIC_LIFE',
  'BASIC_ADD',
  'BASIC_LIFE_AND_ADD',
  'STD',
  'LTD',
];

export const DUPLICATE_VALIDATION = 'validation.duplicate.exists';

export const MEDICAL_DOCS_ORDER = ['PLAN_SUMMARY', 'SBC', 'COC'];
export const DV_DOCS_ORDER = ['PLAN_SUMMARY', 'COC'];
export const BASIC_LIFE_AND_ADD_DOCS_ORDER = [
  'PLAN_SUMMARY',
  'COC',
  'PORTABILITY_AND_CONVERSION',
];
export const STD_DOCS_ORDER = [
  'PLAN_SUMMARY',
  'COC',
  'PORTABILITY_AND_CONVERSION',
  'CLAIM_FORM_STD',
  'CLAIM_FORM_STD_LTD',
];
export const LTD_DOCS_ORDER = [
  'PLAN_SUMMARY',
  'COC',
  'PORTABILITY_AND_CONVERSION',
  'CLAIM_FORM_LTD',
];
export const VOL_LIFE_DOCS_ORDER = [
  'PLAN_SUMMARY',
  'COC',
  'PORTABILITY_AND_CONVERSION',
  'EOI',
];
export const RETIREMENT_DOCS_ORDER = [
  'FOUR_O_ONE_K_ROLLOVER_FORM',
  'PLAN_DOCUMENT',
];
export const TAX_ADVANTAGED_DOCS_ORDER = ['PLAN_SUMMARY', 'PLAN_DOCUMENT'];

export const TAX_ADVANTAGED_TYPE = {
  HSA: 'HSA',
  HRA: 'HRA',
  FSA: 'FSA',
  DCAP_FSA: 'DCAP_FSA',
  LIMITED_FSA: 'LIMITED_FSA',
  COMMUTER_GENERIC: 'COMMUTER_GENERIC',
};

export const ALL_DOCUMENT_TYPE = [
  'SBC',
  'COC',
  'PLAN_SUMMARY',
  'PLAN_DOCUMENT',
  'PORTABILITY_AND_CONVERSION',
  'CLAIM_FORM_STD',
  'CLAIM_FORM_STD_LTD',
  'CLAIM_FORM_LTD_STD',
  'CLAIM_FORM_LTD',
  'EOI',
  'BIKING_PLAN',
  'BIKING_SUMMARY_PLAN',
  'PARKING_PLAN',
  'PARKING_SUMMARY_PLAN',
  'TRANSIT_PLAN',
  'TRANSIT_SUMMARY_PLAN',
  'FOUR_O_ONE_K_ROLLOVER_FORM',
];

export const AdditionalResourcesRadioTypes = {
  FILE: 'file',
  WEBLINK: 'webLink',
};

import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Space, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { getTimezone } from 'countries-and-timezones';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import HomeTextWrapper from 'modules/home/components/HomeTextWrapper/HomeTextWrapper';
import ImageColorCustomizer from 'components/ImageColorCustomizer/ImageColorCustomizer';
import { saveSnapshotImage } from 'modules/home/slices/basicInfoSlice';

import {
  getOEMeeting,
  updateOEMeetingSuccess,
  uploadOEVideoStarted,
} from 'modules/home/slices/oeMeetingSlice';
import {
  getBenefitEnrollButton,
  getCheckList,
  clearBasicInfoSuccess,
} from 'modules/home/slices/homeSlice';
import OpenEnrollmentModal from 'modules/home/components/OpenEnrollmentModal/OpenEnrollmentModal';
import OEMeetingState from 'modules/home/enums/OEMeetingState';
import { getOEMeetingState } from 'modules/home/util';

import {
  getEffectiveDates,
  BANNER_DATE_FORMAT,
  getFormattedInputDate,
} from 'utils/dateUtil';

import iconEdit from 'images/icon-edit.svg';
import { ReactComponent as OEMeetingIcon } from 'images/icons/icon-oe-meeting.svg';

import { getBase64Image, saveScreenshot } from 'utils/screenshotUtil';
import { benguideApiV2 } from 'utils/apiUtil';
import { addHttp } from 'utils/httpUtil';

import { ImageType, TIME_ZONES, HOME_CHECK_LIST } from 'modules/home/constants';
import { getOEMeetingVideoRecording } from 'modules/home/services/BenGuideService';
import OEMeetingRecordType from 'modules/home/enums/OEMeetingRecordType';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  EDIT_BENGUIDE_CHANNEL,
  EDIT_BENGUIDE_STARTED,
} from 'modules/clients/UPClient/UPClientConst';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import OEMeetingType from 'modules/home/enums/OEMeetingType';
import ContactSupport from 'components/ContactSupport/ContactSupport';
import { trackEvents } from 'utils/initGA4';

import styles from './home.module.less';

const Home: FC = () => {
  const location = useLocation();
  const locationState: any = location.state;
  const { benguide } = useAppSelector((state) => state);
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);

  const {
    backgroundImageReference,
    sectionImageUploadSuccess,
    contentOverviewSuccess,
    contentBenefitSuccess,
    benefitEnrollLinkSuccess,
    commonImageUploadInProgress,
  } = useAppSelector((state) => state.home);
  const oeMeetingData = useAppSelector((state) => state.oeMeeting.oeMeeting);
  const oeMeetingSuccess = useAppSelector(
    (state) => state.oeMeeting.oeMeetingSuccess
  );
  const requestTypeOEMeeting = useAppSelector(
    (state) => state.oeMeeting.requestType
  );
  const oeMeetingInProgress = useAppSelector(
    (state) => state.oeMeeting.inProgress
  );
  const { isTablet } = useAppSelector((state) => state.layout);

  const dispatch = useAppDispatch();
  const {
    data: benguideData,
    inProgress,
    mode,
    updateBenguideStylesSuccess,
  } = benguide;
  const {
    startDate = '',
    endDate = '',
    overviewText = '',
    checkListText = '',
    styles: styleConfig = {},
    masterId = '',
    overviewImage,
    revision,
    latestRevision,
    oeMeeting,
  } = benguideData || {};
  const isEditMode = mode === BENGUIDE_MODE_EDIT;
  const [node, setNode] = useState<ReactNode>();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isHomeImageLoaded, setIsHomeImageLoaded] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [base64, setBase64] = useState<string>('');

  const textRef = useRef<HTMLDivElement>(null);
  const ref = useRef();

  const fetchOeMeeting = useCallback(() => {
    if (!oeMeetingData && oeMeeting && oeMeeting.oemeetingReference) {
      dispatch(getOEMeeting(masterId, oeMeeting.oemeetingReference));
    }
  }, [dispatch, masterId, oeMeetingData, oeMeeting]);

  const createThumbnail = useCallback(() => {
    setIsImageLoaded(false);
    setIsHomeImageLoaded(false);
    setNode(node);
    const data = saveScreenshot(textRef.current);
    data.then((src) => {
      if (src) {
        dispatch(saveSnapshotImage(benguideData?.masterId, src));
        dispatch(clearBasicInfoSuccess());
      }
    });
  }, [node, benguideData?.masterId, dispatch]);

  useEffect(() => {
    ref.current = styleConfig && styleConfig.backgroundImageUrl;
  }, [styleConfig]);

  const oeMeetingState = oeMeetingData && getOEMeetingState(oeMeetingData);
  const upClient = useUPClient();

  useEffect(() => {
    if (isEditMode) {
      upClient.postMessage({
        channel: EDIT_BENGUIDE_CHANNEL,
        event: EDIT_BENGUIDE_STARTED,
        data: benguideData.masterId,
      });
    }
  }, [isEditMode, benguideData.masterId, upClient]);

  useEffect(() => {
    if (textRef.current && node !== textRef.current) {
      if (!inProgress && benguideData !== null && isEditMode) {
        if (
          !isEmpty(styleConfig.backgroundImageUrl) &&
          !isEmpty(backgroundImage)
        ) {
          if (
            isImageLoaded ||
            (locationState && locationState.isBasicInfo) ||
            sectionImageUploadSuccess ||
            contentBenefitSuccess ||
            contentOverviewSuccess ||
            oeMeetingSuccess ||
            benefitEnrollLinkSuccess ||
            updateBenguideStylesSuccess ||
            isHomeImageLoaded
          ) {
            createThumbnail();
          }
        } else if (
          (locationState && locationState.isBasicInfo) ||
          sectionImageUploadSuccess ||
          contentBenefitSuccess ||
          contentOverviewSuccess ||
          oeMeetingSuccess ||
          benefitEnrollLinkSuccess ||
          updateBenguideStylesSuccess ||
          isHomeImageLoaded
        ) {
          createThumbnail();
        }
      }
    }
  }, [
    backgroundImage,
    benefitEnrollLinkSuccess,
    benguideData,
    contentBenefitSuccess,
    contentOverviewSuccess,
    dispatch,
    inProgress,
    isEditMode,
    locationState,
    node,
    oeMeetingSuccess,
    sectionImageUploadSuccess,
    styleConfig.backgroundImageUrl,
    updateBenguideStylesSuccess,
    isImageLoaded,
    isHomeImageLoaded,
    createThumbnail,
  ]);

  useEffect(() => {
    if (
      !isEmpty(benguideData) &&
      !isEmpty(styleConfig.backgroundImageUrl) &&
      !commonImageUploadInProgress
    ) {
      setBackgroundImage(
        `${benguideApiV2}/${masterId}/common-images?id=${
          styleConfig.backgroundImageUrl
        }&type=${ImageType.BACKGROUND_IMAGE}&revision=${
          latestRevision || revision
        }`
      );
      getBase64Image(backgroundImage).then((data: any) => {
        setBase64(data);
        setIsImageLoaded(true);
      });
    }
    if (
      isEmpty(styleConfig.backgroundImageUrl) &&
      typeof styleConfig.backgroundImageUrl === 'string'
    ) {
      setBackgroundImage('');
    }
  }, [
    benguideData,
    styleConfig,
    masterId,
    backgroundImageReference,
    backgroundImage,
    dispatch,
    commonImageUploadInProgress,
    latestRevision,
    revision,
  ]);

  useEffect(() => {
    if (masterId) {
      fetchOeMeeting();
      dispatch(getBenefitEnrollButton(masterId));
    }
  }, [masterId, dispatch, fetchOeMeeting]);

  useEffect(() => {
    if (masterId && requestTypeOEMeeting === updateOEMeetingSuccess.type) {
      fetchOeMeeting();
    }
  }, [
    masterId,
    dispatch,
    fetchOeMeeting,
    requestTypeOEMeeting,
    oeMeetingData,
    isEditMode,
    oeMeeting,
  ]);

  useEffect(() => {
    if (masterId) {
      dispatch(getCheckList(masterId, HOME_CHECK_LIST));
    }
    // eslint-disable-next-line
  }, [masterId, dispatch]);

  const checkHomeImageLoad = () => {
    setIsHomeImageLoaded(true);
  };

  const getOEMeetingTime = () => {
    if (oeMeetingData && oeMeetingData.meetingTime && oeMeetingData.timezone) {
      return getFormattedInputDate(
        oeMeetingData.meetingTime,
        BANNER_DATE_FORMAT
      );
    }
    return '';
  };

  const getTimezoneAbbreviationOrText = () => {
    let abbreviationText = '';
    if (oeMeetingData && oeMeetingData.timezone) {
      const zone = TIME_ZONES.find(
        (timezone) => timezone.id === oeMeetingData.timezone
      );
      if (zone) {
        if (zone.abbreviation) {
          abbreviationText = zone.abbreviation;
        } else {
          const timezoneObj = getTimezone(zone.id);
          const timezoneText = zone.name;
          abbreviationText = timezoneObj
            ? `(GMT${timezoneObj.dstOffsetStr}) ${timezoneText}`
            : `(GMT ${timezoneText}`;
        }
      }
    }
    return abbreviationText;
  };

  const getOEMeetingStateDisplayText = (): OEMeetingState | '' => {
    if (oeMeetingState) {
      if (oeMeetingState.inProgress) {
        return OEMeetingState.IN_PROGRESS;
      } else if (oeMeetingState.recorded) {
        return OEMeetingState.RECORDED;
      }
    }
    return '';
  };

  const openOEMeetingLink = () => {
    if (oeMeetingState && oeMeetingData) {
      if (oeMeetingState.inProgress && oeMeetingData.meetingUrl) {
        window.open(addHttp(oeMeetingData.meetingUrl), '_blank');
      } else if (
        oeMeetingState.recorded &&
        oeMeetingData.meetingRecordUrl &&
        oeMeetingData.oeMeetingRecordType === OEMeetingRecordType.URL
      ) {
        window.open(addHttp(oeMeetingData.meetingRecordUrl), '_blank');
      } else if (
        oeMeetingState.recorded &&
        oeMeetingData.oeVideoFile &&
        oeMeetingData.oeMeetingRecordType === OEMeetingRecordType.VIDEO_FILE
      ) {
        masterId &&
          window.open(getOEMeetingVideoRecording(masterId, false), '_blank');
      } else if (
        oeMeetingData.oeMeetingType === OEMeetingType.FUTURE &&
        oeMeetingData.meetingUrl
      ) {
        window.open(addHttp(oeMeetingData.meetingUrl));
      }

      trackEvents({
        category: 'Open Enrollment Meeting',
        action: 'open_enrollment_meeting',
        label: 'Open Enrollment Meeting',
      });
    }
  };

  if (inProgress) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  const oeMeetingStatus = getOEMeetingStateDisplayText();
  const addOEMeetingBtnInProgressCheck =
    requestTypeOEMeeting !== uploadOEVideoStarted.type
      ? !oeMeetingInProgress
      : true;
  const oeMeetingAddBtnStyle = `${styles.customizerWrapper} ${styles.oeMeeting} ${styles.oeMeetingAdd}`;
  const oeMeetingBannerStyle = `${styles.oeMeetingWrapper} ${
    isEditMode ? '' : styles.viewMode
  }`;

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <div
        className={styles.homeWrapper}
        ref={textRef}
        style={{
          backgroundImage: `${
            !isEmpty(backgroundImage) &&
            !isEmpty(styleConfig.backgroundImageUrl)
              ? `url(${base64})`
              : ''
          }`,
        }}
      >
        <BenefitGuideRefreshChannel />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <Row>
          <Col span={12}>
            <strong className={styles.benguideTitle}>Benefits Guide</strong>
          </Col>
          {isEditMode && (
            <Col className={styles.customizerWrapper}>
              <EditableHotspot
                alwaysVisible={true}
                customModal={(visible: boolean, onClose: Function) => (
                  <ImageColorCustomizer
                    isOpenModal={visible}
                    closeModal={onClose}
                    benguideData={benguideData}
                    backgroundImage={backgroundImage}
                  />
                )}
              >
                <div className={styles.customizerHotspot}>
                  Edit Background & Colors
                  <img src={iconEdit} alt="edit" />
                </div>
              </EditableHotspot>
            </Col>
          )}
        </Row>
        {startDate && endDate && (
          <span className={styles.enrollmentDateText}>
            {<span>{getEffectiveDates(startDate, endDate)}</span>}
          </span>
        )}
        <Row>
          {isEditMode &&
            addOEMeetingBtnInProgressCheck &&
            (!oeMeetingData || oeMeetingState?.finished) && (
              <Col>
                <div
                  className={`${styles.customizerWrapper} ${styles.oeMeeting}`}
                >
                  <EditableHotspot
                    alwaysVisible={true}
                    customModal={(visible: boolean, onClose: Function) => (
                      <OpenEnrollmentModal
                        isOpenModal={visible}
                        closeModal={onClose}
                        benGuideId={masterId}
                        revision={revision}
                      />
                    )}
                  >
                    <div className={styles.customizerHotspot}>
                      Add Open Enrollment Meeting Info
                      <img src={iconEdit} alt="edit" />
                    </div>
                  </EditableHotspot>
                </div>
              </Col>
            )}
        </Row>
        {!oeMeetingState?.finished &&
          oeMeetingData &&
          ((oeMeetingData.displayMeetingInfo && !isEditMode) || isEditMode) && (
            <div
              className={
                oeMeetingData.displayMeetingInfo
                  ? oeMeetingBannerStyle
                  : oeMeetingAddBtnStyle
              }
            >
              <EditableHotspot
                alwaysVisible={!oeMeetingData.displayMeetingInfo}
                editText={
                  oeMeetingData.displayMeetingInfo
                    ? 'Add or Edit Open Enrollment Meeting Info'
                    : undefined
                }
                editIcon={
                  oeMeetingData.displayMeetingInfo ? (
                    <img src={iconEdit} alt="edit" />
                  ) : undefined
                }
                viewModeAction={openOEMeetingLink}
                customModal={(visible: boolean, onClose: Function) => (
                  <OpenEnrollmentModal
                    isOpenModal={visible}
                    closeModal={onClose}
                    benGuideId={masterId}
                    oeMeeting={oeMeetingData}
                    revision={revision}
                  />
                )}
              >
                {oeMeetingData.displayMeetingInfo ? (
                  <div className={styles.oeMeetingSection}>
                    <OEMeetingIcon className={styles.oeMeetingIcon} />
                    <div>
                      {oeMeetingStatus === OEMeetingState.RECORDED
                        ? 'Watch Open Enrollment Meeting'
                        : `${oeMeetingStatus} Open Enrollment Meeting: ${getOEMeetingTime()} ${getTimezoneAbbreviationOrText()}`}
                    </div>
                  </div>
                ) : (
                  <div className={styles.customizerHotspot}>
                    Add Open Enrollment Meeting Info
                    <img src={iconEdit} alt="edit" />
                  </div>
                )}
              </EditableHotspot>
            </div>
          )}
        <HomeTextWrapper
          isEditMode={isEditMode}
          isOverview={true}
          initialTextOverview={overviewText}
          title="Overview"
          benguideData={benguideData}
          overviewImage={overviewImage}
          checkHomeImageLoad={checkHomeImageLoad}
        />
        <HomeTextWrapper
          isOverview={false}
          initialTextBenefit={checkListText}
          title="Explore Your Benefits Guide"
          benguideData={benguideData}
        />
        {isTablet && (
          <ContactSupport
            className={styles.contactSupportContainer}
            btnWrapperClassName={styles.supportBtnWrapper}
          />
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default Home;

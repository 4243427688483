import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'hooks/redux';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { NotificationContext } from 'context/NotificationContext';

import { getCustomFieldsToState } from 'modules/benefitsWellness/slices/medicalSlice';
import { updateCustomFields } from 'modules/benefitsWellness/services/BenGuideService';
import iconRemove from 'images/icons/icon-remove-plan-rec.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import CustomField from 'model/CustomField';
import styles from './customRowModal.module.less';

const { Text } = Typography;

type CustomRowModalProps = {
  isOpenModal: boolean;
  closeModal: () => void;
  benguideId: string;
  planList: any[];
};

const CustomRowModal = ({
  isOpenModal,
  closeModal,
  benguideId,
  planList,
}: CustomRowModalProps) => {
  const dispatch = useAppDispatch();
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);
  const [rowToRemove, setRowToRemove] = useState<number | null>(null);
  const notificationFlag = useContext(NotificationContext);
  const [isCharLimitExceeded, setIsCharLimitExceeded] = useState(false);

  const planIds = planList.map((plan) => plan.id);

  useEffect(() => {
    if (isOpenModal) {
      const fieldsMap = new Map<string, CustomField>();

      if (planList?.length > 0) {
        planList.forEach((item) => {
          item?.customFields?.forEach((customField: CustomField) => {
            const key = `${customField.id}_${customField.fieldName}`;
            if (!fieldsMap.has(key)) {
              fieldsMap.set(key, {
                id: customField.id,
                planId: item.planId,
                fieldName: customField.fieldName,
                fieldValue: customField.fieldValue,
              });
            }
          });
        });
      }

      if (fieldsMap.size === 0) {
        setCustomFields([
          {
            id: '',
            planId: '',
            fieldName: '',
            fieldValue: '',
          },
        ]);
      } else {
        setCustomFields(Array.from(fieldsMap.values()));
      }
    }
  }, [isOpenModal, planList]);

  const handleInputChange =
    (index: number, key: 'fieldName' | 'fieldValue') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCustomRows = [...customFields];
      newCustomRows[index] = { ...newCustomRows[index], [key]: e.target.value };
      setCustomFields(newCustomRows);
      setIsCharLimitExceeded(
        newCustomRows.some((row) => row.fieldName.length > 24)
      );
    };

  const handleRemoveRow = (index: number) => {
    setRowToRemove(index);
    setIsConfirmationDialogVisible(true);
  };

  const confirmRemoveRow = () => {
    if (rowToRemove !== null) {
      const newCustomRows = customFields.filter((_, i) => i !== rowToRemove);
      setCustomFields(newCustomRows);
      setRowToRemove(null);
    }
    setIsConfirmationDialogVisible(false);
  };

  const handleAddRow = () => {
    setCustomFields([
      ...customFields,
      {
        id: '',
        planId: '',
        fieldName: '',
        fieldValue: '',
      },
    ]);
  };

  const validateTitleEmpty = () => {
    return customFields.some(
      (row) => row.fieldName.trim() === '' || row.fieldName.trim() === null
    );
  };
  const handleSubmit = useCallback(async () => {
    if (!isCharLimitExceeded) {
      try {
        // Map over all planIds and customFields to create a combined array
        const allFormattedCustomFields = planIds.flatMap((planId) =>
          customFields.map((field) => ({
            id: field.id,
            planId: planId, // Assign the current planId
            fieldName: field.fieldName,
            fieldValue: field.fieldValue,
          }))
        );

        // Call the updateCustomFields API with the entire array
        await updateCustomFields(benguideId, allFormattedCustomFields);

        dispatch(getCustomFieldsToState());
        closeModal();
      } catch (error) {
        console.error('Error updating custom fields:', error);
        // Handle the error accordingly
      }
    }
  }, [
    dispatch,
    benguideId,
    closeModal,
    customFields,
    isCharLimitExceeded,
    planIds,
  ]);

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <HalfScreenModal
      width="552px"
      title="Manage Custom Rows"
      visible={isOpenModal}
      onCancel={handleCloseModal}
      onSubmit={handleSubmit}
      className={styles.customRowWrapper}
      footer={[
        <Button
          className={`${styles.btn} ${styles.cancel}`}
          key="cancel"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>,
        <Button
          className={styles.btn}
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={isCharLimitExceeded || validateTitleEmpty()}
        >
          Done
        </Button>,
      ]}
    >
      {notificationFlag && (
        <div className={styles.fixedAlertMessage}>
          Error updating custom rows
        </div>
      )}
      <div>
        <p>
          Custom Rows will only be viewable in the benefits guide and can
          contain any additional information related to this plan.
        </p>
      </div>
      {customFields.map((row, index) => (
        <div key={index} className={styles.customRow}>
          <Row align="middle" gutter={8}>
            <Col>
              <img
                src={iconRemove}
                alt="remove"
                onClick={() => {
                  if (customFields.length > 1) {
                    handleRemoveRow(index);
                  }
                }}
                className={
                  customFields.length > 1
                    ? styles.iconClickable
                    : styles.iconDefault
                }
              />
            </Col>
            <Col>
              <Text strong>Row Name</Text>
            </Col>
            <Col style={{ flex: 1 }}>
              <Input
                value={row.fieldName}
                onChange={handleInputChange(index, 'fieldName')}
                maxLength={24}
                placeholder="Row Name"
                className={styles.inputField}
              />
            </Col>
          </Row>
          <Row justify="end">
            <Text className={styles.charLimit}>
              Character Limit: {row.fieldName.length}/24
            </Text>
          </Row>
        </div>
      ))}

      <LinkButton onClick={handleAddRow} style={{ marginTop: '16px' }}>
        <div>
          <PlusCircleOutlined className={styles.addIcon} />
        </div>
        <div className={styles.linkHotspot}>Add Additional Custom Row</div>
      </LinkButton>

      <ConfirmationDialog
        title="Delete Row"
        visible={isConfirmationDialogVisible}
        okText="Delete Row"
        cancelText="Cancel"
        onOk={confirmRemoveRow}
        onCancel={() => setIsConfirmationDialogVisible(false)}
        flipFooterOrder={true}
      >
        <p>Are you sure you want to delete this row?</p>
      </ConfirmationDialog>
    </HalfScreenModal>
  );
};

export default CustomRowModal;

import React, {
  FC,
  ReactElement,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import {
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_RECEIVED,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import UPClient from 'modules/clients/UPClient/UPClient';
import { NotificationContext } from 'context/NotificationContext';
import {
  NOTIFICATION_GENERAL_ARCHIVED_STATE,
  NOTIFICATION_GENERAL_UPDATE_STATE,
} from 'constants/commonConstants';

import styles from './editableHotspot.module.less';

export type EditablePostMessage = {
  client: any;
  channel: string;
  event: string;
  data: any;
  dbgMetaData?: any;
};

type EditableHotspotProps = {
  alwaysVisible: boolean;
  editText?: string;
  editIcon?: ReactElement;
  editableComponent?: ReactElement;
  children: any;
  overlayComponent?: ReactNode;
  customModal?: Function;
  useChannel?: EditablePostMessage;
  viewModeAction?: React.MouseEventHandler<HTMLDivElement> | undefined;
  className?: string;
  section?: string;
  editIconWrapperClassName?: string;
};

const EditableHotspot: FC<EditableHotspotProps> = (
  props: EditableHotspotProps
) => {
  const {
    children,
    alwaysVisible,
    editText = '',
    editIcon,
    editableComponent = <></>,
    overlayComponent,
    customModal,
    useChannel,
    viewModeAction,
    className = '',
    section = GLOBAL,
    editIconWrapperClassName = '',
  } = props;

  const { benguide } = useSelector((state: any) => state);
  const {
    mode,
    hotspotted: hotspotted_,
    data,
    hotspottedSection,
    latestRevision,
  } = benguide;
  const hotspotted =
    hotspottedSection === GLOBAL
      ? hotspotted_
      : hotspottedSection === section && hotspotted_;

  const upClient = useUPClient();
  const notificationFlag = useContext(NotificationContext);

  const [mouseEntered, setMouseEntered] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isNotificationInPlace, setNotificationInPlace] =
    useState<boolean>(false);

  const isEditMode = mode === BENGUIDE_MODE_EDIT;
  const isActive = isEditMode && (mouseEntered || alwaysVisible || hotspotted);
  const overlayClicked = () => {
    if (useChannel) {
      useChannel.client.postMessage({
        channel: useChannel.channel,
        event: useChannel.event,
        data: useChannel.data,
        dbgMetaData: { ...data, latestRevision },
      });
    } else {
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (isModalVisible && isNotificationInPlace) {
      upClient.postMessage({
        channel: BENGUIDE_NOTIFICATIONS,
        event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
        data: {},
      });
    } else if (isModalVisible) {
      upClient.postMessage({
        channel: BENGUIDE_NOTIFICATIONS,
        event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
        data: {},
      });
    }
  }, [upClient, isModalVisible, isNotificationInPlace]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const closeModal = () => {
    setMouseEntered(false);
    setIsModalVisible(false);
    if (notificationFlag) {
      upClient.postMessage({
        channel: BENGUIDE_NOTIFICATIONS,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  return (
    <div
      className={`${styles.editableHotspotWrapper} ${
        isActive ? styles.active : styles.noActive
      } ${alwaysVisible ? styles.alwaysVisible : ''}  ${
        hotspotted ? styles.hotspotted : styles.noHotspotted
      } editableHotspotWrapper ${
        overlayComponent ? styles.noPointer : ''
      } ${className}`}
      onMouseOver={() => setMouseEntered(!notificationFlag)}
      onMouseLeave={() => setMouseEntered(false)}
      onClick={!isEditMode && viewModeAction ? viewModeAction : () => {}}
    >
      <div
        className={`${styles.overlayWrapper} overlayWrapper `}
        onClick={overlayClicked}
      >
        {overlayComponent ? (
          overlayComponent
        ) : (
          <div
            className={`${styles.textWrapper} textWrapper ${editIconWrapperClassName}`}
          >
            {editText}
            {editIcon}
          </div>
        )}
      </div>
      {children}
      {customModal ? (
        customModal(isModalVisible, closeModal)
      ) : (
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={closeModal}
          footer={null}
          closeIcon={false}
        >
          {editableComponent}
        </Modal>
      )}
      <UPClient
        channel={BENGUIDE_NOTIFICATIONS}
        subscribe={(event: string, data: any) => {
          if (event === BENGUIDE_NOTIFICATION_RECEIVED) {
            if (
              data.state === NOTIFICATION_GENERAL_UPDATE_STATE &&
              (!data.newStatus ||
                data.newStatus !== NOTIFICATION_GENERAL_ARCHIVED_STATE)
            ) {
              setNotificationInPlace(true);
            }
          }
        }}
      />
    </div>
  );
};

export default EditableHotspot;

export const GLOBAL = 'GLOBAL';
export const SIDE_BAR = 'SIDE_BAR';

import { http as axios } from 'utils/httpUtil';
import { baseApi } from 'utils/apiUtil';

export const getLifeAndDisabilityPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return axios.get(`${baseApi}/v1/plans/insurances`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': 'BASIC_LIFE',
      'benefit-classes': benefitClasses.join(','),
    },
  });
};

export const getAllLifeAndDisabilityPlans = (
  employerId: string,
  planYearId: string,
  benefitKinds: string[],
  benefitClasses: string[]
) => {
  return axios.get(`${baseApi}/v1/plans/insurances`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': benefitKinds.join(','),
      'benefit-classes': benefitClasses.join(','),
    },
  });
};

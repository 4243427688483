import { FC, useRef, useEffect, useState, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';

import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';

import {
  createRichMediaBenefit,
  updateChecklistSection,
  getHomeContentPage,
  getCheckList,
  updateCheckList,
  resetUpdateBenefitRichTextCompleted,
  getRichMediaBenefit,
  updateChecklistLocally,
} from 'modules/home/slices/homeSlice';

import { BenGuideStatus } from 'constants/commonConstants';
import {
  HIGHLIGHT_OPTIONS,
  ContentTypes,
  SectionName,
  Section,
  CHECKLIST_OPTIONS,
  HOME_CHECK_LIST,
} from 'modules/home/constants';
import styles from './exploreBenefitEditor.module.less';

type ExploreBenefitEditorProps = {
  isOpenModal: boolean;
  closeModal: Function;
  textWdith: number;
  editorContent: string;
  updateEditorContent: (data: string) => void;
  resetToDefault: () => void;
  isResetEnabled: boolean;
  benguideData?: any; // Benguide object might differ
  defaultTextBenefit: string;
  resetCurrentValue: Function;
  disableResetter: Function;
  title: string;
};

const ExploreBenefitEditor: FC<ExploreBenefitEditorProps> = (
  props: ExploreBenefitEditorProps
) => {
  const {
    isOpenModal,
    closeModal,
    textWdith,
    editorContent,
    updateEditorContent,
    resetToDefault,
    isResetEnabled,
    benguideData,
    defaultTextBenefit,
    resetCurrentValue,
    disableResetter,
    title: _title,
  } = props;
  const { masterId = '', revision, status } = benguideData || {};
  const { isEditMode } = useBenguide();

  const {
    checkList: checkedOptions,
    updateBenefitRichTextCompleted,
    checkListOriginal: checkedOptionsOriginal,
    latestRevision,
  } = useAppSelector((state) => state.home);
  const { medical } = useAppSelector((state: any) => state);
  const checkList = cloneDeep(checkedOptions);
  const checkListOriginal = cloneDeep(checkedOptionsOriginal);
  const textEditorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const { medicalSection } = medical || {};
  const { plans = [] } = medicalSection || {};
  const [title, setTitle] = useState<string>(_title);

  useEffect(() => {
    setTitle(_title);
  }, [_title]);

  const selectRevision = useCallback(() => {
    if (status !== BenGuideStatus.PUBLISHED) {
      if (!isEditMode) {
        return revision;
      } else if (updateBenefitRichTextCompleted) {
        return latestRevision;
      }
    }
  }, [
    isEditMode,
    latestRevision,
    revision,
    status,
    updateBenefitRichTextCompleted,
  ]);

  useEffect(() => {
    if (updateBenefitRichTextCompleted) {
      dispatch(updateCheckList(masterId, checkList));
      dispatch(resetUpdateBenefitRichTextCompleted());
      closeModal();
    }
  }, [
    updateBenefitRichTextCompleted,
    masterId,
    dispatch,
    closeModal,
    checkList,
  ]);

  const resetContent = () => {
    setTitle(_title);
    textEditorRef?.current?.clearTextEditorContent(defaultTextBenefit);
    resetToDefault();
    const checkList: string[] = [];
    HIGHLIGHT_OPTIONS.forEach((content) => {
      if (isEnabled(content.objectName)) {
        checkList.push(content.value);
      }
    });
    dispatch(updateChecklistLocally(checkList));
  };

  const closeBenefitModal = () => {
    setTitle(_title);
    resetCurrentValue();
    if (textEditorRef.current) {
      textEditorRef.current.clearTextEditorContent();
    }
    dispatch(getHomeContentPage(masterId, Section.HOME));
    dispatch(getCheckList(masterId, HOME_CHECK_LIST, selectRevision()));
    dispatch(getRichMediaBenefit(masterId));
    closeModal();
  };

  const handleEditorChange = (data: string) => {
    updateEditorContent(data);
  };

  const handleSubmit = () => {
    dispatch(
      createRichMediaBenefit(
        masterId,
        SectionName.HOME_BENEFIT_GUIDE_DESCRIPTION,
        editorContent,
        title
      )
    );
  };

  const isEnabled = (value: string | undefined) => {
    if (ContentTypes.MEDICAL_PLAN_RECOMMENDOR === value) {
      return plans.length > 1 && benguide?.planRecommenderEnabled;
    }
    if (value && benguideData.hasOwnProperty(value)) {
      return benguideData[value].enabled;
    }
    return false;
  };

  const getCheckedState = (value: string | undefined) => {
    if (!isEmpty(checkList)) {
      if (value === ContentTypes.NEW_HIRE_CHECKLIST) {
        return (
          benguide?.newHireCheckList?.enabled &&
          checkList.includes(CHECKLIST_OPTIONS.NEW_HIRE_CHECKLIST)
        );
      } else if (value === ContentTypes.HOW_INSURANCE_WORKS) {
        return (
          benguide?.howInsuranceWorks?.enabled &&
          checkList.includes(CHECKLIST_OPTIONS.HOW_INSURANCE_WORKS)
        );
      } else if (value === ContentTypes.DATES_AND_ELIGIBILITY) {
        return (
          benguide?.datesAndEligibility?.enabled &&
          checkList.includes(CHECKLIST_OPTIONS.DATES_AND_ELIGIBILITY)
        );
      } else if (value === ContentTypes.MEDICAL_PLAN_RECOMMENDOR) {
        return (
          benguide?.planRecommenderEnabled &&
          checkList.includes(CHECKLIST_OPTIONS.MEDICAL_PLAN_RECOMMENDER)
        );
      }
    }
    return false;
  };

  const updateOption = (checkedValue: string) => {
    if (checkList) {
      const optionIndex = checkList.findIndex((option: string) => {
        return option === checkedValue;
      });
      if (optionIndex >= 0) {
        checkList.splice(optionIndex, 1);
      } else {
        checkList.push(checkedValue);
      }
    }

    if (isEmpty(checkList) && isEmpty(checkListOriginal)) {
      disableResetter(true);
    } else {
      checkList.sort();
      checkListOriginal.sort();
      if (
        checkList.length === checkListOriginal.length &&
        isEqual(checkList, checkListOriginal)
      ) {
        disableResetter(true);
      } else {
        disableResetter(false);
      }
    }
  };

  const toggleCheckList = (value: string | undefined) => {
    if (value === ContentTypes.NEW_HIRE_CHECKLIST) {
      updateOption(CHECKLIST_OPTIONS.NEW_HIRE_CHECKLIST);
    }
    if (value === ContentTypes.HOW_INSURANCE_WORKS) {
      updateOption(CHECKLIST_OPTIONS.HOW_INSURANCE_WORKS);
    }
    if (value === ContentTypes.DATES_AND_ELIGIBILITY) {
      updateOption(CHECKLIST_OPTIONS.DATES_AND_ELIGIBILITY);
    }
    if (value === ContentTypes.MEDICAL_PLAN_RECOMMENDOR) {
      updateOption(CHECKLIST_OPTIONS.MEDICAL_PLAN_RECOMMENDER);
    }
    dispatch(updateChecklistSection(checkList));
  };

  return (
    <HalfScreenModal
      width={`${textWdith + 88}px`}
      title={title}
      visible={isOpenModal}
      onReset={resetContent}
      onCancel={closeBenefitModal}
      onSubmit={handleSubmit}
      confirmModalShow={true}
      isResetEnabled={isResetEnabled}
      footerStyles={styles.footerBenefitEditor}
      editableTitle={true}
      onChangeTitle={setTitle}
      destroyOnClose={true}
    >
      <RichTextEditor
        limit={1500}
        initialValue={editorContent}
        onChange={handleEditorChange}
        showMediaUpload={false}
        defaultText={editorContent}
        ref={textEditorRef}
        masterId={masterId}
        revision={selectRevision()}
      />

      <div className={styles.befefitsWrapper}>
        <div className={styles.benefitTitle}>
          You may choose areas of the guide to highlight and link to:
        </div>
        {HIGHLIGHT_OPTIONS.map((content, index) => (
          <div className={styles.benefit} key={index}>
            <StyledCheckbox
              value={content.value}
              disabled={!isEnabled(content.objectName)}
              checked={getCheckedState(content.objectName)}
              onChange={() => toggleCheckList(content.objectName)}
            >
              {content.label}
            </StyledCheckbox>
            <br />
            <div className={styles.benefitDescription}>
              {content.description}
            </div>
          </div>
        ))}
      </div>
    </HalfScreenModal>
  );
};

export default ExploreBenefitEditor;

import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import PlanSelectButton from 'components/PlanSelectButton/PlanSelectButton';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import TelehealthCard from 'modules/benefitsWellness/components/TelehealthCard/TelehealthCard';
import AlertMessage from 'components/AlertMessage/AlertMessage';

import TelehealthPlansChannel from 'modules/clients/UPClient/channels/TelehealthPlansChannel';
import useUPClient from 'modules/clients/UPClient/useUPClient';

import PlanReference from 'model/PlanReference';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';

import { ReactComponent as TelehealthIcon } from 'images/pageHeaderIcons/telehealth.svg';

import {
  fetchTelehealthPlanList,
  addMultiplePlans,
  getDefaultText,
} from 'modules/benefitsWellness/slices/telehealthSlice';

import {
  BENEFIT_CATEGORY,
  TELEHEALTH_BENEFITS_KIND,
} from 'constants/commonConstants';
import {
  TELEHEALTH_PLAN_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { NotificationContextProvider } from 'context/NotificationContext';

import styles from './telehealth.module.less';

const planData = [
  benefitCategory.TELEHEALTH,
  benefitCategory.RX_DELIVERY,
  benefitCategory.RX_COUPONS,
];

const Telehealth: FC = () => {
  const dispatch = useAppDispatch();
  const { telehealth } = useAppSelector((state: any) => state);
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const upClient = useUPClient();

  const sortPlans = (nonSortedPlanList = []) => {
    const order = [
      benefitCategory.TELEHEALTH.value,
      benefitCategory.RX_DELIVERY.value,
      benefitCategory.RX_COUPONS.value,
    ];
    let sortedplanList = [];
    sortedplanList = nonSortedPlanList.sort(function (a: any, b: any) {
      return order.indexOf(a.benefitKind) - order.indexOf(b.benefitKind);
    });
    return sortedplanList;
  };

  const { planList: nonSortedPlanList, telehealthSection = [] } = telehealth;
  let telehealthPlanList = cloneDeep(nonSortedPlanList);
  telehealthPlanList = sortPlans(telehealthPlanList.data);
  const { plans: addedPlans = [] } = telehealthSection;
  const benguide = useBenguide();
  const { masterId, planYearId, employerId, benefitClasses } = benguide;

  const isEditMode = benguide.isEditMode;
  const hasPlan = telehealthPlanList && telehealthPlanList.length !== 0;

  useEffect(() => {
    dispatch(fetchTelehealthPlanList(employerId, planYearId, benefitClasses));
  }, [dispatch, employerId, planYearId, benefitClasses]);

  useEffect(() => {
    if (addedPlans) {
      const uniquePlansList = uniqBy(addedPlans, 'benefitKind');
      if (uniquePlansList) {
        uniquePlansList.forEach((element: any) => {
          if (element.id && element.benefitKind) {
            dispatch(getDefaultText(masterId, element.id, element.benefitKind));
          }
        });
      }
    }
  }, [dispatch, addedPlans, masterId]);

  const updatePlans = (plans: [], orderByPlanNames: boolean) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        planName: plan.name,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });

    const addedPlansPayload: Array<AddPlanApiRequestPayload> = [];
    TELEHEALTH_BENEFITS_KIND.forEach((benefitKind: string) => {
      return addedPlansPayload.push({
        sectionName: benefitKind,
        plans: planReferences.filter(
          (planRef) =>
            planRef.benefitKind && planRef.benefitKind === benefitKind
        ),
        orderByPlanNames: orderByPlanNames,
      });
    });
    dispatch(addMultiplePlans(masterId, addedPlansPayload));
  };

  const reloadAllTelehealthPlans = useCallback(() => {
    dispatch(fetchTelehealthPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, dispatch, benefitClasses]);

  const telehealthRxDeliveryTypes = () => {
    return (
      <Menu>
        {planData.map((item, key) => {
          return (
            <Menu.Item key={key} className={styles.menuItemHeader}>
              <EditableHotspot
                alwaysVisible={false}
                useChannel={{
                  client: upClient,
                  channel: TELEHEALTH_PLAN_CHANNEL,
                  event: OPEN_ADD_NEW_PLAN_MODAL,
                  data: { item },
                }}
              >
                {item.label}
              </EditableHotspot>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const getCards = () => {
    const orderedBenefits = [
      { value: 'TELEHEALTH', label: 'Telehealth Services' },
      { value: 'RX_DELIVERY', label: 'Rx Delivery' },
      { value: 'RX_COUPONS', label: 'Rx Coupons' },
    ];
    return orderedBenefits.map((plan) => (
      <div key={plan.value}>
        {addedPlans.find(
          (addedPlan: { benefitKind: string }) =>
            addedPlan.benefitKind === plan.value
        ) && <div className={styles.planHeading}>{plan.label}</div>}
        <div className={styles.telehealthContainer}>
          {addedPlans
            .filter(
              (addedPlan: { benefitKind: string }) =>
                addedPlan.benefitKind === plan.value
            )
            .map((plan: any, index: any) => (
              <TelehealthCard key={index} plan={plan} />
            ))}
        </div>
      </div>
    ));
  };

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.telehealthWrapper}>
        <TelehealthPlansChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.TELEHEALTH.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle="Telehealth/Rx Delivery"
          pageHeaderIcon={<TelehealthIcon />}
          customIconStyle={styles.telehealthIcon}
        />
        {isEditMode && !isEmpty(addedPlans) && (
          <div className={styles.addOrRemovePlansWrapper}>
            <EditableHotspot
              alwaysVisible={true}
              customModal={(visible: boolean, onClose: Function) => (
                <AddOrRemovePlans
                  visible={visible}
                  onClose={onClose}
                  title="Telehealth/Rx Delivery"
                  onSave={updatePlans}
                  addedPlanList={addedPlans}
                  allPlans={telehealthPlanList}
                  reloadPlans={reloadAllTelehealthPlans}
                  channel={TELEHEALTH_PLAN_CHANNEL}
                  actionContent={
                    <PlanSelectButton
                      overlay={telehealthRxDeliveryTypes()}
                      selectLabel="Create New Plan"
                      className={!hasPlan ? styles.darkButton : ''}
                    />
                  }
                  showAlert={showAlert}
                  isLoading={telehealthPlanList.inProgress}
                />
              )}
            >
              <Button>+ Add or Remove Plans</Button>
            </EditableHotspot>
          </div>
        )}

        {addedPlans.length > 0
          ? getCards()
          : isEditMode && (
              <AddOrRemovePlans
                visible
                withoutModal={true}
                title="Telehealth/Rx Delivery"
                onSave={updatePlans}
                addedPlanList={addedPlans}
                allPlans={telehealthPlanList}
                reloadPlans={reloadAllTelehealthPlans}
                channel={TELEHEALTH_PLAN_CHANNEL}
                actionContent={
                  <PlanSelectButton
                    overlay={telehealthRxDeliveryTypes()}
                    selectLabel="Create New Plan"
                    className={!hasPlan ? styles.darkButton : ''}
                  />
                }
                showAlert={showAlert}
                isLoading={telehealthPlanList.inProgress}
              />
            )}

        {isEmpty(addedPlans) && !isEditMode && (
          <NoPlansView className={styles.noPlanView} />
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default Telehealth;

import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import { reloadMedicalSection } from 'modules/benefitsWellness/slices/medicalSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  BENGUIDE_NOTIFICATIONS,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  RELOAD_TAX_ADVANTAGED_PLAN,
  RELOAD_TAX_ADVANTAGED_PLAN_LIST,
  TAX_ADVANTAGED_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  TaxAdvantagedSection,
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import {
  fetchCommuterPlans,
  getCommuterSection,
} from 'modules/taxAdvantaged/slices/commuterSlice';
import {
  fetchFSAPlans,
  getFSASection,
} from 'modules/taxAdvantaged/slices/fsaSlice';
import {
  fetchHRAPlans,
  getHRASection,
} from 'modules/taxAdvantaged/slices/hraSlice';
import {
  fetchHSAPlans,
  getHSASection,
} from 'modules/taxAdvantaged/slices/hsaSlice';

const TaxAdvantagedPlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  const getPlanSectionData = (benefitKind: string) => {
    switch (benefitKind) {
      case 'HSA':
        dispatch(
          getHSASection({
            benguideId: benguide.masterId,
          })
        );
        break;
      case 'HRA':
        dispatch(
          getHRASection({
            benguideId: benguide.masterId,
          })
        );
        break;
      case 'COMMUTER_GENERIC':
        dispatch(
          getCommuterSection({
            benguideId: benguide.masterId,
          })
        );
        break;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA
        .benefitKind:
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HEALTHCARE_FSA.benefitKind:
      case 'LIMITED_FSA':
        dispatch(
          getFSASection({
            benguideId: benguide.masterId,
          })
        );
        break;
      default:
        break;
    }
  };

  const fetchPlanList = (benefitKind: TaxAdvantagedSection) => {
    switch (benefitKind) {
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HSA.benefitKind:
        dispatch(
          fetchHSAPlans({
            employerId: benguide.employerId,
            planYearId: benguide.planYearId,
            benefitClasses: benguide.benefitClasses,
          })
        );
        dispatch(
          reloadMedicalSection(benguide?.masterId, benguide?.latestRevision)
        );
        break;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HRA.benefitKind:
        dispatch(
          fetchHRAPlans({
            employerId: benguide.employerId,
            planYearId: benguide.planYearId,
            benefitClasses: benguide.benefitClasses,
          })
        );
        dispatch(
          reloadMedicalSection(benguide?.masterId, benguide?.latestRevision)
        );
        break;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.COMMUTER.benefitKind:
        dispatch(
          fetchCommuterPlans({
            employerId: benguide.employerId,
            planYearId: benguide.planYearId,
            benefitClasses: benguide.benefitClasses,
          })
        );
        break;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA
        .benefitKind:
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HEALTHCARE_FSA.benefitKind:
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.LIMITED_PURPOSE_FSA
        .benefitKind:
        dispatch(
          fetchFSAPlans({
            employerId: benguide.employerId,
            planYearId: benguide.planYearId,
            benefitClasses: benguide.benefitClasses,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <UPClient
      channel={TAX_ADVANTAGED_PLAN_CHANNEL}
      subscribe={(event: string, data: any) => {
        switch (event) {
          case RELOAD_TAX_ADVANTAGED_PLAN:
            if (data && data.benefitKind) {
              getPlanSectionData(data.benefitKind);
              dispatch(
                reloadMedicalSection(benguide.masterId, benguide.latestRevision)
              );
            }
            break;
          case RELOAD_TAX_ADVANTAGED_PLAN_LIST:
            if (data && data.benefitKind) {
              fetchPlanList(data.benefitKind);
            }
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default TaxAdvantagedPlansChannel;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'antd';

import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import Spinner from 'components/Spinner/Spinner';
import {
  getRichMediaContent,
  updateRichMediaContent,
} from 'modules/additionalPerks/slices/additionalPerksSlice';
import {
  ADDITIONAL_PERKS_CHANNEL,
  OPEN_ADDITIONAL_PERKS_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import iconEdit from 'images/icon-edit.svg';
import AdditionalPerksPlan from 'model/AdditionalPerksPlan';
import AddYourText from 'modules/benefitsWellness/components/AddYourText/AddYourText';
import BenefitCarrier from 'model/BenefitCarrier';

import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './additionalPerksCard.module.less';

type AdditionalPerksCardProps = {
  plan: AdditionalPerksPlan;
};

const AdditionalPerksCard: FC<AdditionalPerksCardProps> = ({
  plan,
}: AdditionalPerksCardProps) => {
  const upClient = useUPClient();

  const benguide = useBenguide();
  const dispatch = useAppDispatch();
  const { richMedia } = useAppSelector((state) => state.additionalPerks);
  const { masterId, isEditMode } = benguide;
  const defaultContent = '';
  const { id, benefitKind, benefitCarrier = {} as BenefitCarrier } = plan;
  const { benefitDescription = '' } = benefitCarrier;
  const richMediaForPlan = get(richMedia, id);
  const { content } = richMediaForPlan || {};

  const isContentReceived = content !== undefined;

  const [editorContent, setEditorContent] = useState<string>(defaultContent);
  const [isVisible, setIsVisible] = useState(false);

  const loadContent = useCallback(() => {
    dispatch(getRichMediaContent(masterId, id));
  }, [dispatch, masterId, id]);

  const saveContent = (content: string) => {
    dispatch(updateRichMediaContent(masterId, id, content));
  };

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  useEffect(() => {
    setEditorContent(content || defaultContent);
  }, [content]);

  const showAddContentBtn = useMemo(() => {
    return isEmpty(content) && isEditMode;
  }, [content, isEditMode]);

  const { carrier } = plan;
  const { logoUrl } = carrier || {};

  const setCurrentConetnt = () => {
    setEditorContent(content);
  };

  return (
    <div className={styles.perksCardWrapper}>
      <Row>
        <Col span={24}>
          <div className={styles.summaryWrapper}>
            <EditableHotspot
              alwaysVisible={false}
              editText="Edit"
              editIcon={<img src={iconEdit} alt="edit" />}
              useChannel={{
                client: upClient,
                channel: ADDITIONAL_PERKS_CHANNEL,
                event: OPEN_ADDITIONAL_PERKS_EDIT_MODAL,
                data: plan,
              }}
            >
              <div className={styles.summary}>
                {logoUrl && (
                  <div className={styles.icon}>
                    <img src={logoUrl} alt="" />
                  </div>
                )}
                <div className={styles.planName}>{plan.name}</div>
                {(!isEmpty(plan.documents) ||
                  !isEmpty(plan.additionalDocuments) ||
                  !isEmpty(plan.additionalWeblinks)) && (
                  <div
                    className={styles.viewDetailsLink}
                    onClick={() => setIsVisible(true)}
                  >
                    Plan Resources
                  </div>
                )}
              </div>
            </EditableHotspot>
          </div>
        </Col>
      </Row>
      <div
        className={
          showAddContentBtn ? styles.addTextWrapper : styles.description
        }
      >
        {!isContentReceived ? (
          <Spinner />
        ) : (
          <EditableHotspot
            className={showAddContentBtn ? styles.addTextHotspot : ''}
            alwaysVisible={showAddContentBtn}
            editText={showAddContentBtn ? '' : 'Edit'}
            editIcon={
              showAddContentBtn ? <></> : <img src={iconEdit} alt="edit" />
            }
            customModal={(visible: boolean, onClose: Function) => (
              <PlanCardEditor
                isOpenModal={visible}
                closeModal={onClose}
                editorContent={editorContent}
                isResetEnabled={true}
                resetToCurrentValue={() => {}}
                resetToDefault={() => {}}
                defaultContent={`<p>${benefitDescription}</p>`}
                updateEditorContent={setEditorContent}
                benguideId={masterId}
                onSubmit={saveContent}
                setCurrentConetnt={setCurrentConetnt}
              />
            )}
          >
            {showAddContentBtn ? (
              <AddYourText />
            ) : (
              <div className={styles.content}>
                <ContentPreviewer
                  htmlContent={
                    isEmpty(editorContent) ? defaultContent : editorContent
                  }
                />
              </div>
            )}
          </EditableHotspot>
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={benefitKind}
      />
    </div>
  );
};
export default AdditionalPerksCard;

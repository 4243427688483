import { FC, useEffect, useState } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import BenefitCarrier from 'model/BenefitCarrier';
import defaultImage from 'images/default-image.png';

import styles from './planCardHeader.module.less';

type Props = {
  planName: string;
  carrier?: BenefitCarrier;
  className?: string;
};

const PlanCardHeader: FC<Props> = (props: Props) => {
  const { planName, carrier, className = '' } = props;

  const [logoError, setLogoError] = useState(false);
  const previousLogo = usePrevious(carrier?.logoUrl);

  useEffect(() => {
    if (carrier && carrier.logoUrl) {
      if (previousLogo !== carrier.logoUrl) {
        setLogoError(false);
      }
    }
  }, [previousLogo, carrier]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.carrierLogo}>
        {carrier && (
          <>
            <img
              src={logoError ? defaultImage : carrier.logoUrl}
              alt={`${carrier.name}`}
              onError={() => setLogoError(true)}
            />
            {logoError && carrier?.name}
          </>
        )}
      </div>
      <div className={styles.planName}>{planName}</div>
    </div>
  );
};

export default PlanCardHeader;

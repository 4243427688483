import { FC, useState, useRef, useEffect, useContext } from 'react';

import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import reactCSS from 'reactcss';
import hexToRgb from 'hex-to-rgba';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';

import ColorPicker, {
  Color,
  RGBAModel,
} from 'components/ColorPicker/ColorPicker';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';

import {
  handleImageColorCustomizerUpdate,
  handleImageColorCustomizerUpdateWithoutImage,
} from 'modules/home/slices/homeSlice';
import { ImageType } from 'modules/home/constants';

import { rgbaToHex, validateHex } from 'utils/commonUtil';
import {
  colorConfigurationTypes,
  ERROR_MESSAGE_PLAN_EDITING,
} from 'constants/commonConstants';
import { NotificationContext } from 'context/NotificationContext';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { benguideApiV2 } from 'utils/apiUtil';
import { getBase64Image } from 'utils/screenshotUtil';

import styles from './imageColorCustomizer.module.less';

type ImageColorCustomizerProps = {
  isOpenModal: boolean;
  closeModal: Function;
  benguideData?: any; // Benguide object might differ
  backgroundImage?: string;
};

const DEFAULT_COLOR = '#000000';

const ImageColorCustomizer: FC<ImageColorCustomizerProps> = (
  props: ImageColorCustomizerProps
) => {
  const { isOpenModal, closeModal, benguideData, backgroundImage } = props;
  const { masterId = '', styles: styleConfig = {} } = benguideData || {};
  const { COLOR_THEME, HOME_TEXT_COLOR } = colorConfigurationTypes;
  const { backgroundImageReference } = useAppSelector((state) => state.home);
  const [selectedImgName, setSelectedImgName] = useState<string>(
    `${styleConfig.backgroundImageUrl}.png`
  );
  const [backgroundImg, setBackgroundImg] = useState<string>('');
  const [originalBgImage, setOriginalBgImage] = useState<string | null>(null);
  const [cropArea, setCropArea] = useState<OriginalImageCropParams | null>(
    null
  );
  const [isVisibleTextColorPicker, setIsVisibleTextColorPicker] =
    useState<boolean>(false);
  const [isVisibleColorThemePicker, setIsVisibleColorThemePicker] =
    useState<boolean>(false);
  const [homeTextColor, setHomeTextColor] = useState<RGBAModel>({
    r: '0',
    g: '0',
    b: '0',
    a: '0',
  });
  const [colorTheme, setColorTheme] = useState<RGBAModel>({
    r: '0',
    g: '0',
    b: '0',
    a: '0',
  });
  const [originalImgRef, setOriginalImgRef] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { revision, latestRevision } = useBenguide();

  const notificationFlag = useContext(NotificationContext);

  useEffect(() => {
    if (typeof backgroundImage === 'string') {
      setBackgroundImg(backgroundImage);
    }
  }, [backgroundImage]);

  useEffect(() => {
    if (masterId && styleConfig?.originalBackgroundImageUrl) {
      const originalImgUrl = `${benguideApiV2}/${masterId}/common-images?type=${
        ImageType.BACKGROUND_IMAGE
      }&original=true&revision=${latestRevision || revision}`;
      getBase64Image(originalImgUrl).then((data: unknown) => {
        setOriginalBgImage(data as string);
      });
    }
  }, [
    styleConfig?.originalBackgroundImageUrl,
    masterId,
    revision,
    latestRevision,
  ]);

  useEffect(() => {
    if (styleConfig) {
      setHomeTextColor(
        convertHexToRgba(
          styleConfig.homeTextColor ? styleConfig.homeTextColor : DEFAULT_COLOR
        )
      );
      setColorTheme(
        convertHexToRgba(
          styleConfig.colorTheme ? styleConfig.colorTheme : DEFAULT_COLOR
        )
      );
      if (styleConfig?.originalImageCropParams) {
        setCropArea(styleConfig.originalImageCropParams);
      }
      if (styleConfig?.originalBackgroundImageUrl) {
        setOriginalImgRef(styleConfig.originalBackgroundImageUrl);
      }
    }
  }, [styleConfig]);

  const getDefaultStyle = (type: RGBAModel) => {
    return {
      width: '19px',
      height: '17px',
      background: `rgb(
            ${type.r !== null ? type.r : 0},
            ${type.g !== null ? type.g : 0},
            ${type.b !== null ? type.b : 0}`,
    };
  };
  const homeScreenRef = useRef<any>(null);
  const colorThemeRef = useRef<any>(null);

  const colorPickerStyles = () =>
    reactCSS({
      default: {
        homeTextColor: {
          ...getDefaultStyle(homeTextColor),
          borderRight: 'none',
        },
        colorTheme: {
          ...getDefaultStyle(colorTheme),
          borderRight: 'none',
        },
      },
    });

  const handleClose = (type: string) => {
    if (type === HOME_TEXT_COLOR) {
      setIsVisibleTextColorPicker(false);
    } else if (type === COLOR_THEME) {
      setIsVisibleColorThemePicker(false);
    }
  };

  const handleColorChange = (color: Color, type: string) => {
    if (type === HOME_TEXT_COLOR) {
      setHomeTextColor(color.rgb);
    } else if (type === COLOR_THEME) {
      setColorTheme(color.rgb);
    }
  };

  const handleClick = (type: string) => {
    if (type === HOME_TEXT_COLOR) {
      setIsVisibleTextColorPicker(!isVisibleTextColorPicker);
    } else if (type === COLOR_THEME) {
      setIsVisibleColorThemePicker(!isVisibleColorThemePicker);
    }
  };

  const handleColorInput = (event: any) => {
    const { name, value } = event.target;
    const _value = isEqual(value.charAt(0), '#') ? value : '#'.concat(value);
    if (validateHex(_value) && _value.length === 7) {
      const rgba = convertHexToRgba(_value);
      if (name === HOME_TEXT_COLOR) {
        setHomeTextColor(rgba);
      } else if (name === COLOR_THEME) {
        setColorTheme(rgba);
      }
    }
  };

  const convertHexToRgba = (hex: string) => {
    const rgbaStr = hexToRgb(hex);
    const rgbArr = rgbaStr
      .substring(5, rgbaStr.length - 2)
      .replace(/ /g, '')
      .split(',');
    const rgba = {
      r: parseInt(rgbArr[0]),
      g: parseInt(rgbArr[1]),
      b: parseInt(rgbArr[2]),
      a: 1,
    };
    return rgba;
  };

  const convertToColorString = (rgba: RGBAModel) => {
    const { r, g, b, a } = rgba;
    return `rgba(${r},${g},${b},${a})`;
  };

  const resetToDefault = () => {
    if (loading) {
      return;
    }
    const DEFAULT_COLOR = { r: 0, g: 0, b: 0, a: 0 };
    setHomeTextColor(DEFAULT_COLOR);
    setColorTheme(DEFAULT_COLOR);
    setBackgroundImg('');
    homeScreenRef.current.clearColor();
    colorThemeRef.current.clearColor();
  };

  const closeCustomizerModal = () => {
    if (loading) {
      return;
    }
    setColorTheme(
      convertHexToRgba(
        styleConfig.colorTheme ? styleConfig.colorTheme : DEFAULT_COLOR
      )
    );
    setHomeTextColor(
      convertHexToRgba(
        styleConfig.homeTextColor ? styleConfig.homeTextColor : DEFAULT_COLOR
      )
    );
    if (backgroundImage) {
      setBackgroundImg(backgroundImage);
    } else {
      setBackgroundImg('');
    }
    closeModal();
  };

  const handleUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setBackgroundImg(image);
    originalImage && setOriginalBgImage(originalImage);
    cropArea && setCropArea(cropArea);
  };

  const handleImageRemove = () => {
    setBackgroundImg('');
    setOriginalBgImage(null);
    setCropArea(null);
    setOriginalBgImage(null);
  };

  const submitColorPicker = () => {
    setLoading(true);
    if (isEmpty(backgroundImg) || !/^data/.test(backgroundImg)) {
      dispatch(
        handleImageColorCustomizerUpdateWithoutImage(
          masterId,
          rgbaToHex(convertToColorString(homeTextColor)),
          rgbaToHex(convertToColorString(colorTheme)),
          // if background image is empty considered to be removed
          styleConfig.backgroundImageUrl && !isEmpty(backgroundImg)
            ? styleConfig.backgroundImageUrl
            : '',
          originalImgRef,
          cropArea
        )
      ).then(() => {
        setLoading(false);
        closeModal();
      });
    } else if (originalBgImage && cropArea) {
      dispatch(
        handleImageColorCustomizerUpdate(
          masterId,
          backgroundImg,
          originalBgImage,
          cropArea,
          ImageType.BACKGROUND_IMAGE,
          rgbaToHex(convertToColorString(homeTextColor)),
          rgbaToHex(convertToColorString(colorTheme)),
          backgroundImageReference,
          () => {
            closeModal();
          }
        )
      ).then(() => {
        setLoading(false);
      });
    }
  };

  const getImageName = (name: string) => {
    setSelectedImgName(name);
  };

  return (
    <div className={styles.imageCustomizerWrapper}>
      <HalfScreenModal
        width="520px"
        title="Select Background Image & Colors"
        visible={isOpenModal}
        onReset={resetToDefault}
        onCancel={closeCustomizerModal}
        onSubmit={submitColorPicker}
        isLoading={loading}
        confirmModalShow
        footerStyles={styles.footerColorCustomizer}
        destroyOnClose
      >
        {notificationFlag && (
          <div className={styles.notificationAlertWrapper}>
            <FixedAlertMessage
              type={'error'}
              message={ERROR_MESSAGE_PLAN_EDITING}
            />
          </div>
        )}
        <Row className={styles.imgHeaderWrapper}>
          <h4 className={styles.backgroundHeader}>Home Screen Background</h4>
          <p>This will be used as the home screen background image </p>
        </Row>
        <Row className={styles.backgroundImgWrapper}>
          <Col span={8}>
            <ImageUploader
              title="Home Screen Background"
              cropShape="rect"
              uploadedImage={backgroundImg}
              aspect={1}
              loading={false}
              onConfirm={handleUpload}
              showOperations
              uploadNewImgText="+ Upload New Background"
              onRemove={handleImageRemove}
              getImageName={getImageName}
              maxSizeMB={4}
              unCroppedImage={originalBgImage || undefined}
              defaultCropArea={cropArea || undefined}
            />
          </Col>
          <Col className={styles.imgHeaderWrapper}>
            {backgroundImg ? (
              <Row className={styles.imgNameWrapper}>
                <a className={styles.imgName} href={backgroundImg} download>
                  {selectedImgName || 'image.png'}
                </a>

                <div className={styles.removeImg} onClick={handleImageRemove}>
                  (remove)
                </div>
              </Row>
            ) : (
              <p>
                PNG or JPG format only, with a max file size 4MB <br />
                Recommended Size:1440 x 1040
              </p>
            )}
          </Col>
        </Row>
        <Row className={styles.subHeaderWrapper}>
          <h4>Home Screen Text Color</h4>
          <p>
            Choose a color that makes your text legible on your home screen
            background image
          </p>
        </Row>
        <ColorPicker
          name="homeTextColor"
          styleColor={colorPickerStyles().homeTextColor}
          textColor={
            validateHex(rgbaToHex(convertToColorString(homeTextColor)))
              ? rgbaToHex(convertToColorString(homeTextColor))
              : '#'
          }
          placeholder={rgbaToHex(convertToColorString(homeTextColor))}
          value={rgbaToHex(convertToColorString(homeTextColor))}
          handleClick={() => handleClick(HOME_TEXT_COLOR)}
          handleClose={() => handleClose(HOME_TEXT_COLOR)}
          handleChange={(color) => handleColorChange(color, HOME_TEXT_COLOR)}
          displayColorPicker={isVisibleTextColorPicker}
          handleInputChange={handleColorInput}
          ref={homeScreenRef}
        />
        <Row className={styles.subHeaderWrapper}>
          <h4>
            Color Theming
            <span> (buttons, icons, tab highlights)</span>
          </h4>
          <p>
            Choose a color that best represents your branding. This color will
            display for buttons, icons, and tab highlights throughout the
            benefits guide.
          </p>
        </Row>
        <ColorPicker
          name="colorTheme"
          styleColor={colorPickerStyles().colorTheme}
          textColor={
            validateHex(rgbaToHex(convertToColorString(colorTheme)))
              ? rgbaToHex(convertToColorString(colorTheme))
              : '#'
          }
          placeholder={rgbaToHex(convertToColorString(colorTheme))}
          value={rgbaToHex(convertToColorString(colorTheme))}
          handleClick={() => handleClick(COLOR_THEME)}
          handleClose={() => handleClose(COLOR_THEME)}
          handleChange={(color) => handleColorChange(color, COLOR_THEME)}
          displayColorPicker={isVisibleColorThemePicker}
          handleInputChange={handleColorInput}
          ref={colorThemeRef}
        />
      </HalfScreenModal>
    </div>
  );
};

export default ImageColorCustomizer;
